import thunkMiddleWare from 'redux-thunk';

import {createLogger} from 'redux-logger';

import { createStore, applyMiddleware } from 'redux';

import rootReducer from './Reducerlists.js'

const loggerMiddleware = createLogger({collapsed: true});

let middlewares =  [thunkMiddleWare];

// middlewares.push(loggerMiddleware);

var initialState  = {
    dietitianList: null,
    dietitianListAuto: null,
    clientProfileList: null,
    clientProfile: null,
    dietitianProfile: null,
    timingList:null,
    surveyMealList:[
    ],
    packageList: null,
    packageDetail: null,
    packageStatus: null,
    dietitianStatus: null,
    clientDetails: null,
    dietitianDetails: null,
    settings: null,
    foodList: null,
    packageEditData:{},
    currentMeal: {
        openCls: '',
        items: []
    },
    taxonomy: null,
    currentWorkspace: {
        totalDays: 0,
        mealMap: {}
    },
    printModeCls:'',
    showAddSupplement: null,
    planEditToggleClass: 'hide',
    dietPlanSupplements:{
        name:'',
        link:'',
        dosage:''
    },
    dietaryRecall:{},
    currentUser: null,
    surveyProgress: null,
    surveyResponses: null,
    dietPlan: {},
    bodyVitalParams: {},
    bodyVitalParam: null,
    surveyWorkoutDetails:null,
    workoutEditData:null
}

let store = null;

if (initialState) {
    store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middlewares)
    );
} else {
    store = createStore(
        rootReducer,
        applyMiddleware(...middlewares)
    );
}
window.gNutrifyStore = store;
export default store;
