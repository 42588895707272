import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getMealTimeDetails} from "../../js/actions.js";
import moment from 'moment';

class DietaryRecall extends Component {

    componentDidMount() { 

        const {packageDetail} = this.props;
        if (!packageDetail || !packageDetail.data) {
            return;
        }
        this.props.getMealTimeDetails(packageDetail.data.id);
    }

    render(){
        let {mealTimeDetails} = this.props;
        let rows = [];
        if (mealTimeDetails != undefined && mealTimeDetails.data && mealTimeDetails.data.length > 0) {
            mealTimeDetails.data.forEach((item, idx) => {
                rows.push(
                    <tr key={'key_' + idx}>
                        <td>{item.meal_type}</td>
                        <td>{moment(item.meal_time, "HH:mm:ss").format("hh:mm A")}</td>
                        <td className="desc-col" style={{whiteSpace: 'pre-wrap'}}>
                            {item.meal_description}
                        </td>
                    </tr>
                );
            });
        }
        if (rows.length == 0) {
            rows.push(<tr key="zero"><td colSpan="3">
                <div className="alert info">No Meal time list for this package.</div></td></tr>);
        }
        return(

                <div>
                    <h6>Meal Time list:</h6>
                    <div className="w3-responsive">    
                        <table className="mealtime-survey w3-table w3-table-all">
                            <thead>
                                <tr className="w3-light-grey">
                                    <th>Meal Type</th>
                                    <th>Time</th>
                                    <th>Meal Description <br/><span className="w3-small"> (Weekly meals with approx quantities)</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>

                </div>

        )
    }
    
}
function mapStateToProps(state) {
    return {
        mealTimeDetails:state.mealTimeDetails,
        packageDetail: state.packageDetail,
    };
}
export default connect(mapStateToProps, {getMealTimeDetails} )(DietaryRecall);
