import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {FormTextField} from "../Common/HelperComponents.js";
import SettingsLogoDisclaimer from './SettingsLogoDisclaimer';
import SettingsLoadData from './SettingsLoadData';
import SettingsPassword from './SettingsPassword';
import ExportData from "./ExportData";

class Settings extends Component {

    constructor(args) {
        super(args);

        this.state = {
            formData: {},
            errors: {},
            successMessage: '',
        };
    }   

    render() {
        var adminFlag = false;
        if (this.props.currentUser.type != "DIETICIAN"){
            adminFlag = true;
        }
        return (            
            <div className="w3-row-padding settings-margin">
                { adminFlag ?
                    (<div className="w3-col m8 s12">
                        <SettingsLogoDisclaimer/>
                    </div>)
                    : null
                }
                <div className="w3-col m4 s12">
                    <SettingsPassword />
                </div>
                <div className="w3-col m4 s12">
                    <ExportData 
                        admin={adminFlag}
                    />
                </div>     
            </div> 
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
    };
}
export default connect(mapStateToProps, {} )(Settings);
