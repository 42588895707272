import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import ClientProfileManager from './ClientProfileManager.js';
import { getPaginatedList, fireRequest } from "../../js/actions.js";
import {
    NavigationSwitcher,
    FilterList,
    LoadingIndicator,
    PaginationSwitcher,
    FormTextField
} from "../Common/HelperComponents.js";
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'querystring';
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';
import { Foundation } from 'foundation-sites';

class ProfileRow extends Component {   
    
    constructor(props){
        super(props);

        this.state = {
            modal : false,
        }
    }

    handleEdit(userId) {
        if (this.props.handleEdit) {
            this.props.handleEdit(userId);
        }
    }

    handleModal(userId){
        if(this.props.handleModal){
            this.props.handleModal(userId);
        }
    }


    modalOpen(user){
        this.setState({modal : true});
    }

    modalClose(){
        this.setState({modal : false});
    }

    render() {
        var profileData = this.props.item;
        var age = Math.floor(moment().diff(profileData.dob, 'years', true));
        return (
            <tr key={"row_" + profileData.id} >
                <td>{profileData.first_name + " " + profileData.last_name}</td>
                {/* <td>
                <a className="no-decoration" onClick={this.handleModal.bind(this,profileData.id)} className="w3-teal w3-button">Upload</a>
                <NutritionModal userId={profileData.id} show={this.state.modal} handleClose={(e)=> this.modalClose(e)} />
                </td> */}
                <td>{profileData.contact_email}</td>
                <td>{profileData.phone}</td>
                <td>{profileData.gender}</td>
                <td>{(age) ? age : '--'}  Years</td>
                <td>{profileData.status}</td>
                <td>{moment(profileData.joined_date).format('DD-MMM-YYYY')}</td>
                <td>
                    <Link to={"/client-details/" + profileData.id} className="no-decoration">View </Link> |
                    <a className="no-decoration" onClick={this.handleEdit.bind(this, profileData.id)}> Edit</a>
                </td>
            </tr>
        );
    }
}

class SortableTh extends Component {
    constructor(args) {
        super(args);
        this.state = {
            direction: null,
            icon: ""
        };
        this.handleSort = this.handleSort.bind(this);
    }

    handleSort(evt) {
        if (this.state.direction == null || this.state.direction == 'desc') {
            this.setState({ direction: 'asc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-up-b"></i></div> });
        } else {
            this.setState({ direction: 'desc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-down-b"></i></div> });
        }
        this.props.onSort(this.props.title, this.state.direction);
    }

    render() {
        var sortBtn = <div className="sort-indicator"><i className="icon ion-arrow-up-b"></i><i className="icon ion-arrow-down-b"></i></div>;
        if (this.props.title != this.props.current) {
            this.state.icon = sortBtn;
        }
        return (
            <th className="sortable-th" onClick={e => this.handleSort()} >
                <div className="sort-row">
                    {this.props.title}
                    {this.state.icon}
                </div>
            </th>
        );
    }
}

class ProfileList extends FilterList {
    constructor(args) {
        super(args);
        this.state = {
            userIdForEdit: null,
            searchQuery: '',
            filterAll: true,
            sortColumn: 'all'
        };
    }

    componentDidMount() {
        if (this.props.location.query.col) {
            this.state.sortColumn = this.props.location.query.col;
        }
        this.props.getPaginatedList('clientProfileList', this.props.location.query);
        if (!this.props.profileStatusList) {
            this.props.fireRequest('profileStatusList');
        }
    }

    setRevealClass(name) {
        this.setState({
            userIdForEdit: null
        }, function () {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealFormOpen',
                value: name
            })
        }.bind(this));
    }

    handleEdit(userId) {
        if (userId) {
            this.setState({
                userIdForEdit: userId
            }, function () {
                gNutrifyStore.dispatch({
                    type: 'SET_DATA',
                    key: 'revealFormOpen',
                    value: 'open'
                })
            }.bind(this))
        }
    }

    handleModal(userId) {
        if (userId) {
            this.setState({
                userIdForEdit: userId
            }, function () {
                gNutrifyStore.dispatch({
                    type: 'SET_DATA', 
                    key: 'revealModal',
                    value: 'open'
                })
            }.bind(this))
        }
    }

    handleSearch(evt) {
        this.changeParam({ q: evt.target.value })
        this.setState({
            searchQuery: evt.target.value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        var query = this.props.location.query
        var prevQuery = prevProps.location.query;
        const q1 = queryString.stringify(query);
        const q2 = queryString.stringify(prevQuery)
        if (q1 != q2) {
            this.props.getPaginatedList('clientProfileList', query);
        }
        Foundation.addToJquery($);
        $("#filter-client").foundation();
    }

    changeParam(param) {
        const { location } = this.props;
        const query = Object.assign({
            status: 'all',
            order: 'asc',
            page: 1,
            gender: '',
            age_group: '',
        }, location.query, param);
        hashHistory.push(`/client-profile?${queryString.stringify(query)}`);
        // this.props.getPaginatedList('clientProfileList',query);
    }

    orderClientProfile(col) {
        const { location: {query: { order } } }= this.props;
        this.changeParam({
            col,
            order: order == 'asc' ? 'desc' : 'asc'
        });
    }

    handleChange(evt) {
        const value = evt.target.value;
        const targetElement = $(evt.target).attr('id');
        this.changeParam({
            [targetElement]: value
        });
    }

    render() {
        var rows = [];
        const { clientProfileList, profileStatusList } = this.props;
        const { searchQuery } = this.state;
        let query = '';
        var data = [];
        var order = this.props.location.query.order ? this.props.location.query.order : 'desc';
        if (clientProfileList && clientProfileList !== null) {
            data = clientProfileList.data.data;
        }
        let firstNameList = [];
        let lastNameList = [];
        if (searchQuery) {
            query = searchQuery.toLowerCase();
            data.filter(item => {
                if (item.first_name.toLowerCase().indexOf(query) !== -1) {
                    firstNameList.push(item);
                } else if (item.last_name.toLowerCase().indexOf(query) !== -1) {
                    lastNameList.push(item);
                }
            });
            firstNameList.concat(lastNameList).map((item, idx) => {
                rows.push(
                    <ProfileRow
                        key={"idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                        handleModal={this.handleModal.bind(this)}
                    />
                );
            });
        } else {
            data.map((item, idx) => {
                rows.push(
                    <ProfileRow
                        key={"idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                        handleModal={this.handleModal.bind(this)}
                    />
                );
            });
        }

        var pagination = '';
        if (rows.length > 0) {
            let filter = this.props.location.query.status ? this.props.location.query.status : 'all';
            query = this.props.location.query.q ? this.props.location.query.q : '';
            let join = this.props.location.query.join ? this.props.location.query.join : '';
            pagination = <div className="pagination-left">
                <ul className="pagination">
                    <li className="w3-padding-small w3-button">
                        <Link to={{
                            pathname: "/client-profile",
                            query: {
                                status: filter,
                                page: 1,
                                q: query,
                                order,
                                col: this.state.sortColumn,
                                join
                            }
                        }}>«</Link>
                    </li>
                    <PaginationSwitcher
                        basePath="/client-profile"
                        query={query}
                        joinDate={join}
                        filter={filter}
                        order={order}
                        col={this.state.sortColumn}
                        selected={this.props.location.query.page || 1}
                        options={clientProfileList.data.last_page}
                    />
                    <li className="w3-padding-small w3-button">
                        <Link to={{
                            pathname: "/client-profile",
                            query: {
                                status: filter,
                                page: clientProfileList.data.last_page,
                                q: query,
                                order: order,
                                col: this.state.sortColumn,
                                join
                            }
                        }}>»</Link>
                    </li>
                </ul>
            </div>
        }

        if (rows.length == 0 && this.isEmptyFilter()) {
            rows = (
                <tr>
                    <td colSpan="8" className="no-data-text w3-center w3-padding-64" >
                        <div className="w3-light-grey">Go ahead and add your first client.</div>
                    </td>
                </tr>
            );
        } else if (rows.length == 0) {
            rows = (
                <tr>
                    <td colSpan="8" className="no-data-text w3-center w3-padding-64" >
                        <div className="w3-light-grey">
                            There are no results matching the current filter
                        </div>
                    </td>
                </tr>
            );
        }

        if (!profileStatusList) {
            return <LoadingIndicator />;
        }
        return (
            <div className="w3-container  w3-padding">
                <div className="m12 w3-col">
                    <h4 className="section-header">Client Profiles</h4>
                </div>
                <div className="m10 w3-col">
                    <div className="w3-row w3-row-padding">
                        <div className="m3 w3-col">
                            <label>Search</label>
                            <input
                                type="search"
                                onChange={this.handleSearch.bind(this)}
                                value={this.state.searchQuery}
                                placeholder="Search"
                                style={{ height: "40px" }}
                                className="w3-input w3-border w3-round"
                            />
                        </div>
                        <div className="m2 w3-col">
                            <label>Status</label>

                            <select
                                onChange={this.handleChange.bind(this)}
                                value={this.props.location.query.status ? this.props.location.query.status : '-'}
                                id="status"
                                name="status"
                                style={{ height: "40px" }}
                                className="w3-select w3-border w3-round"
                            >
                                {profileStatusList.data.map((item) => <option value={item}>{item}</option>)}
                            </select>

                        </div>
                        <div className="m2 w3-col">
                            <label>Gender</label>
                            <select
                                onChange={this.handleChange.bind(this)}
                                value={this.props.location.query.gender ? this.props.location.query.gender : '-'}
                                id="gender"
                                name="gender"
                                style={{ height: "40px" }}
                                className="w3-select w3-border w3-round"
                            >
                                <option value="">-</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="m2 w3-col">
                            <label>Age Group</label>
                            <select
                                onChange={this.handleChange.bind(this)}
                                value={(this.props.location.query.age_group) ? this.props.location.query.age_group : '-'}
                                id="age_group"
                                name="age_group"
                                style={{ height: "40px" }}
                                className="w3-select w3-border w3-round">
                                <option value="">-</option>
                                <option value="0-05">0-05</option>
                                <option value="06-10">06-10</option>
                                <option value="11-15">11-15</option>
                                <option value="16-20">16-20</option>
                                <option value="21-25">21-25</option>
                                <option value="26-30">26-30</option>
                                <option value="31-35">31-35</option>
                                <option value="36-40">36-40</option>
                                <option value="41-45">41-45</option>
                                <option value="46-50">46-50</option>
                                <option value="51-55">51-55</option>
                                <option value="56-60">56-60</option>
                                <option value="61-65">61-65</option>
                                <option value="66-70">66-70</option>
                                <option value="71-75">71-75</option>
                                <option value="76-80">76-80</option>
                                <option value="81-100">81+</option>
                            </select>
                        </div>
                        <div className="m3 w3-col">
                            <FormTextField
                                id="join_date"
                                label="Date of Joining"
                                name="join_date"
                                placeholder="DD/MM/YYYY"
                                value={(this.props.location.query.join && this.props.location.query.join !== '') ? this.props.location.query.join : ''}
                                onChange={this.handleChange.bind(this)}
                                type="date"
                                inputClass={"w3-input w3-border w3-round"}
                            />
                        </div>
                    </div>
                </div>
                <div className="m2 w3-col">
                    <div className="w3-row w3-row-padding">
                        <div style={{ textAlign: "right", paddingTop: "20px" }}>
                            <button
                                onClick={this.setRevealClass.bind(this, 'open')}
                                className="w3-button w3-padding-small w3-round w3-blue"
                                style={{ height: "40px" }}
                            >Add Profile
                            </button>
                        </div>
                    </div>
                </div>
                <div className="s12 w3-col w3-margin-top">
                    <div className="w3-responsive">
                        <table className="w3-table-all listing">
                            <tbody>
                                <tr className="w3-left-align">
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Name')} title="Name"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Email')} title="Email"
                                        current={this.state.sortColumn} />
                                    <th>Phone</th>
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Gender')} title="Gender"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Age')} title="Age"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Status')} title="Status"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderClientProfile.bind(this, 'Joined On')} title="Joined On"
                                        current={this.state.sortColumn} />
                                    <th>Action</th>
                                </tr>
                                {rows}
                            </tbody>
                        </table>
                        {pagination}
                    </div>
                </div>
                <ClientProfileManager query={this.props.location.query} userId={this.state.userIdForEdit} />

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        clientProfileList: state.clientProfileList,
        profileStatusList: state.profileStatusList
    };
}
export default connect(mapStateToProps, {
    getPaginatedList,
    fireRequest
})(ProfileList);

