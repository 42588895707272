import React, { Component } from 'react';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd'; 
import { findDOMNode } from 'react-dom';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';


class MealTableRow extends Component {
    constructor(args) {
        super(args);
        this.handleSelectChange = null;
        this.state = {
            ddCls:false,
            tr_hover: false, 
        };
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

     

    toggleDropDown(){
        const {ddCls} = this.state;
        const changed = ddCls == 'w3-show'?'w3-hide' : 'w3-show';
        this.setState({ddCls:changed});
    }

    makeOptional(foodItemId) {
        this.setState({ddCls:'w3-hide'});
        this.changeMealProp('ref_id',foodItemId);
    }

    
    //  getOptions(input, callback){
    //     var self = this;
    //     var state = gNutrifyStore.getState();
    //     $.ajax({
    //         url: '/api/v1/food/item/search?q=' + input
    //             + '&package_id=' + this.props.packageId
    //             + '&hypothetical_time=' + state.currentMeal.hypothetical_time
    //     }).done(function (resp) {
    //         console.log("response of items",resp.data);
    //         var list = [];
    //         resp.data.forEach((item) => {
    //             list.push({ 
    //                 value: item,
    //                 label: item.name
    //             });
    //         })
    //         callback(null, {
    //             options: list
    //         });
    //         console.log("searched list",list);
    //         this.handleSelectChange = callback;
    //     });
    // };

    getOptions(input,callback){
 
        var state = gNutrifyStore.getState();
        $.ajax({
            url: '/api/v1/food/item/search?q=' + input
                + '&package_id=' + this.props.packageId
                + '&hypothetical_time=' + state.currentMeal.hypothetical_time
        }).done(function (resp) {
            var list = [];
            resp.data.forEach((item) => {
                list.push({ 
                    value: item,
                    label: item.name
                });
            })
            // callback(null, {
            //     options: list
            // });
            callback(list);
        });

    }

    handleChange(item) {
        var state = gNutrifyStore.getState();
        var meal = Object.assign({}, state.currentMeal);
        if (item) {
            item.quantity  = 1;
            item.ref_id = null;
            item.food_item_id = item.value.id;
            if (this.props.position === undefined) {
                meal.items.push(item);
            } else {
                meal.items.splice(this.props.position, 1, item);
                if(this.props.item && (item.value.id != this.props.item.value.id)){
                    for (var idx = 0; idx < meal.items.length; idx++){
                        if(meal.items[idx].ref_id == this.props.item.value.id){
                            meal.items[idx].ref_id = null;
                        }
                    }
                }     
            }
        } else {
           for (var idx = 0; idx < meal.items.length; idx++) {
                if (this.props.item && meal.items[idx].value.id == this.props.item.value.id) {
                    meal.items.splice(idx, 1);
                }
                if(this.props.item && meal.items[idx].ref_id == this.props.item.value.id){
                    meal.items[idx].ref_id = null;
                }
            }
        }
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        })
    }
    changeMealProp(name,value) {
        var state = gNutrifyStore.getState(); 
        var meal = Object.assign({}, state.currentMeal);
        for (var idx = 0; idx < meal.items.length; idx++) {
            if (idx  == this.props.position) {
                meal.items[idx][name] = value;
            }
        }
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
    }

    handleQtyChange(e) {
        this.changeMealProp('quantity',''+e.target.value);
    }

    componentDidUpdate() {
        if (this.handleSelectChange) {
            this.handleSelectChange(null, {
                options: []
            })

        }
    }

    format(val) {
        return Math.floor(val * 100) / 100;
    }

    onMouseLeaveHandler(){
        this.setState({tr_hover: false});
    }

    onMouseEnterHandler(){
        this.setState({tr_hover: true});
    }

    addOptionalMealHandler(item) {
        const { addOptionMeal } = this.props;
        if (addOptionMeal) {
            this.props.addOptionMeal(item)
        }
    }

    render() {
        const {
            connectDragSource,
            connectDropTarget,
            moveCard,
            addOptionMeal,
            allItems,
            item
        } = this.props;
        let optionVisibleClass = 'w3-hide';
        var uomText = '--';
        var calText = '0';
        var choText = '0';
        var proteinText = '0';
        var fatText = '0';
        var fibreText = '0';
        var qty  = "0";
        var clsConflict = '';
        var qtyStr  = "0";
        var trActiveClass = this.state.tr_hover ? ' tb-drag-context' : '';
        var showHide = this.state.tr_hover ? 'td-drag-context' : 'td-drag-context-hide';
        let {ddCls} = this.state;
        let optionItems = null;
        if (item) {
            qtyStr = item.quantity;
            qty = item.quantity ? parseFloat(item.quantity) : 0;
            uomText = (item.value.output * qty) + " " + item.value.uom ;
            calText = this.format(item.value.qty_calories * qty);
            choText = this.format(item.value.qty_carbohydrate * qty);
            proteinText = this.format(item.value.qty_protein * qty);
            fatText = this.format(item.value.qty_fat * qty);
            fibreText = this.format(item.value.qty_fibre * qty);
            if (item.is_conflict == 1) {
                clsConflict = 'txt-conflict';
            }
            optionVisibleClass = 'w3-show';
                      const options = allItems
                .filter(mItem => mItem.food_item_id != item.food_item_id 
                    && !mItem.ref_id
                )
                .map(mItem => {
                    return <button type="button" 
                    onClick={e => this.makeOptional(mItem.food_item_id)} 
                    className="w3-bar-item w3-button w3-tiny nut-option-btn-padding">{mItem.label}</button> 

            
            });
            const isParent = allItems.some(mItem => mItem.ref_id == item.food_item_id);
            if (options.length > 0  && !isParent ) {
                let btnLabel = 'Optional';
                const optional = allItems.find(mItem => mItem.food_item_id == item.ref_id );
                if(optional) {
                    btnLabel = ' / '+ optional.label;
                }
                optionItems = (<div className={`w3-dropdown ${optionVisibleClass}`}>
                <button  type="button" onClick={e => this.toggleDropDown()} className="w3-button w3-hover-white w3-tiny nut-option-btn-padding">{btnLabel}</button>
                <div className={ `w3-dropdown-content w3-bar-block w3-card-4 ${ddCls}` }>
                    {options}
                    {item.ref_id > 0 && <button type="button" onClick={e => this.makeOptional(null)} className="w3-bar-item w3-button  w3-tiny nut-option-btn-padding">Remove</button>}
                </div>
            </div>)
            }
        }  

        return connectDragSource(connectDropTarget(
            <tr  
                onMouseEnter={this.onMouseEnterHandler.bind(this)}
                onMouseLeave={this.onMouseLeaveHandler.bind(this)}
                className={clsConflict + trActiveClass}
            >
                <td style={{width: "40%",minWidth:"140px"}}>
                    <span className="w3-row">
                        <i className={showHide + " icon ion-drag"}></i>
                        <AsyncSelect
                            name="form-field-name"
                            autosize={false}
                            cache={false}
                            autoload={false}
                            isClearable={true}
                            value={item}
                            onChange={this.handleChange.bind(this)}
                            loadOptions={this.getOptions}
                        />
                        {optionItems}
                    </span>
                </td>
                <td>
                    <input
                        style={{maxWidth: "100px"}}
                        type="number"
                        step="any"
                        min="0"
                        defaultValue={qty}
                        value={qtyStr}
                        onChange={this.handleQtyChange.bind(this)}
                        className="w3-input w3-border"
                    />
                </td>
                <td>{uomText}</td>
                <td>{calText}</td>
                <td>{choText}</td>
                <td>{proteinText}</td>
                <td>{fatText}</td>
                <td>{fibreText}</td>
            </tr>
        ));
    }
}

const rowSource = {
    beginDrag(props) {
        return {
            id: props.meal_item_id,
            index: props.index,
        }
    },
}

const rowTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        monitor.getItem().index = hoverIndex
    },
}

var DS = DragSource("type", rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(MealTableRow);

export default DropTarget("type", rowTarget, (connect,monitor)  => ({
    connectDropTarget: connect.dropTarget(),
}))(DS);