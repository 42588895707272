import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FormTextField} from "../Common/HelperComponents.js";
import {getSupplementDetails, fireRequest} from "../../js/actions.js";
import AddSupplementRow from './AddSupplementRow.js';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

class SupplementInfo extends Component {

    constructor(arg) {
        super(arg);
 
        this.state = {  
            showSaveButton: false
        }
    }

    componentDidMount() {
    	this.props.fireRequest('supplementList');
        this.props.getSupplementDetails(this.props.planId);  
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.planId != prevProps.planId){
           this.props.getSupplementDetails(this.props.planId); 
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.closeSupplements != nextProps.closeSupplements){
            if(nextProps.closeSupplements){
                this.setState({showSaveButton:false});
            }    
        }
    }

    handleAddSupplements(e) {

        console.log('Adding suppolment',this.state);
        e.preventDefault();
        e.stopPropagation();
        if (this.state.showSaveButton){
            this.handleSupplementSave();
        } else {
            this.setState({ 
                showSaveButton: true
            });

            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'closeSupplements',
                value: false
            });
        }      
    }

    handleCancel(){
        this.setState({showSaveButton:false});
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanSupplements',
            value: {}
        });
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'closeSupplements',
            value: 'true'
        });
    }

    handleSupplementSave(){
        
        if(!this.props.dietPlanSupplements || !this.props.dietPlanSupplements.name){
        	alert("Please select a supplement name to add supplements.");
        	return;
        }
        
        $.ajax({
            type: "POST",
            url: '/api/v1/diet/supplement/create',
            data: { planId: this.props.planId,
                    packageId: this.props.packageId,
                    name:this.props.dietPlanSupplements.name,
                    link:this.props.dietPlanSupplements.link,
                    dosage:this.props.dietPlanSupplements.dosage,
                },
            success:  (response) => {
            	if (response.success == false) {
                    this.setState({
                        errors: response.data
                    }); 
                    return;
                }
                gNutrifyStore.dispatch({
                    type: 'SET_DATA',
                    key: 'closeSupplements',
                    value: true
                });
                this.setState({ 
                    showSaveButton:false,
                    successMessage: "Nutrition Supplements added successfully!"
                }); 
    			this.props.getSupplementDetails(this.props.planId);                                                                                                                                                                                                                                                                                                                                          
            } 
        });

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanSupplements',
            value: {}
        });
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'closeSupplements',
            value: 'true'
        });
        
    }

    handleDeleteRow(index, evt){
        evt.preventDefault();
        var deleteRow =[];
        if(!this.props.supplementDetails){  
        	return
        }

        deleteRow = this.props.supplementDetails.data[index];
        $.ajax({
            type: "POST",
            url: '/api/v1/diet/supplement/delete',
            data: { id: deleteRow.id},
            success: function (response) {
                if (response.success == true) {
                    this.setState({
                    	successMessage: "Nutrition Supplement row deleted successfully!"
                    });                                                                                                                                                                                                                                                                                                                                           
                } else if (response.success == false) {
                    this.setState({
                        errors: response.data
                    });
                }
    			this.props.getSupplementDetails(this.props.planId);
            }.bind(this)
        });  
    }

    render() {
     	var supplementDetailsList = [];
        var supplementRows =[];
        var supplementTitle =[];
        if (this.props.supplementDetails){
            supplementDetailsList = this.props.supplementDetails.data;       
        }
        if(supplementDetailsList.length > 0){
            supplementTitle.push(
                    <tr className="w3-light-grey">
                        <th>Name</th>
                        <th>Dosage</th>
                        <th>Link</th>                      
                    </tr>);   
            supplementDetailsList.forEach((supplement, index) =>{
                supplementRows.push(
                        <tr>
                            <td>{supplement.name}</td>
                            <td>{supplement.dosage}</td>
                            <td className="link-col">{supplement.link}
                                <button className="w3-button supp-delete-btn w3-red w3-tiny w3-padding-small"
                                    type="button" 
                                    onClick={this.handleDeleteRow.bind(this, index)}>
                                    Delete
                                </button>
                            </td> 
                        </tr>
                        
                    );
            });
        }
      
        return (
        	<div id='supplements' >
                <h5>Supplements</h5>
                <form className="supplement-info" >
                    <div id='supplement-list' className="w3-responsive w3-margin-bottom">
                        <table className="supplement-table w3-table-all">
                            <thead>
                                {supplementTitle}
                            </thead>
                            <tbody>
                                {supplementRows}
                            </tbody>
                        </table>   
                    </div>
                    <div id='add-supplements' className="w3-col l12 m12 s12">
                        { this.state.showSaveButton &&  <AddSupplementRow planId={this.props.planId} packageId={this.props.packageId} />  }
                        <div className="w3-row w3-margin-bottom ">
                            {
                                this.state.showSaveButton ? <button onClick={this.handleCancel.bind(this)} 
                                    type="button" 
                                    className="w3-button w3-padding-small w3-teal supplement-cls">Cancel
                                </button> : null 
                            }
                            <button onClick={e => this.handleAddSupplements(e)}
                                className={`w3-button w3-padding-small nutrify-btn ${this.state.showSaveButton ? 'supplement-save' : 'supplement-add'}`}>
                                {this.state.showSaveButton? 'Save ' : 'Add Supplement'}
                            </button>
                        </div> 
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) { 
    return {
		supplementList: state.supplementList,
        supplementDetails: state.supplementDetails,
        dietPlanSupplements: state.dietPlanSupplements,
        rowCancel: state.rowCancel,
        closeSupplements: state.closeSupplements
    };
}

export default connect(mapStateToProps,{
    getSupplementDetails,
    fireRequest})
(SupplementInfo);

