import { createStore, applyMiddleware } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import { requestMap,actions } from './actions.js';
let middlewares =  [thunkMiddleWare];
let initialState = {};

export default function rootReducer(state = {},changeAction) {
    if (changeAction.type == actions.SET_DATA) {
        var obj = {};
        obj[changeAction.key] = changeAction.value;

        state = Object.assign({}, state, obj);
    } else if (changeAction.type == actions.CLEAR_PRODUCT_LIST) {
        state = Object.assign({}, state, {productList : null});
    } else if (changeAction.type == actions.GET_APP_REQUEST_ERROR) {
        var obj = {};
        obj[changeAction.key] = {error: true};
        state = Object.assign({}, state, obj);

    } else if (changeAction.type == actions.GET_APP_REQUEST_SUCCESS) {
        var obj = {};
        obj[changeAction.key] = changeAction.response;
        var labels = [];
        var recipes = [];
        var extra = {};
        if (changeAction.key == "currentWorkspace") {
             obj[changeAction.key] = changeAction.response.data;
            labels = changeAction.response.data.labels.map((item)=>{ return {
                label:item.name,value:item.name }
            });
             recipes = changeAction.response.data.recipes.map((item)=>{
                 return { label:item.name, value:item.name }
             })
             
             extra = {dietPlanLabels:labels, dietPlanRecipes:recipes};

        }
        if (changeAction.key == "currentUser") {
            obj[changeAction.key] = changeAction.response.data.user;
        }
        state = Object.assign({}, state, obj,extra);


    } else if (changeAction.type == actions.GET_APP_REQUEST){
        // var obj = {};
        // obj[changeAction.key] = null;
        // state = Object.assign({}, state, obj);
    }

    return state;
}

