import React, { Component } from 'react';
import Select from 'react-select'; 
import { connect } from 'react-redux';
import { FormTextarea } from "../Common/HelperComponents.js";
import {
    fireRequest,
    getMealTimeDetails
} from "../../js/actions.js";
import moment from 'moment';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';


class AddMealTimeRow extends Component {
    constructor(props) {
        super(props);
        let { mealList, idx } = this.props; 
        this.state = {
            mealType: mealList[idx].type,
            mealTime: mealList[idx].time,
            description:mealList[idx].description,
            mealId: mealList[idx].mealId,
            isSaved:mealList[idx].mealId ? true : false,
            initialProp: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        // this.saveRow = this.saveRow.bind(this); 
    }

    componentWillReceiveProps(nextProps) {
        const {mealList,idx} = nextProps;
        this.setState ({
            mealType: mealList[idx].type,
            mealTime: mealList[idx].time,
            description:mealList[idx].description,
            mealId: mealList[idx].mealId,
            isSaved:mealList[idx].mealId ? true : false,
        });
    }

    handleSelect(name, selected) {
        if (!selected || !selected.value){
            return;
        }
        const obj= {};
        obj[name] = selected.value;  
        this.setState(obj,() => {
            this.updatePropsRow();
        });
    }

    


    handleChange(evt) {
        this.setState({
            description : evt.target.value
        },()=>{
            this.updatePropsRow();
        });
    }
    

    updatePropsRow() {
        const {mealList,idx } =  this.props;
        const existing  = mealList[idx];
 
        const {
            mealType,
            mealTime,
            mealId,
            description
        } = this.state; 
        mealList.splice(idx,1,Object.assign(existing,{
            type:mealType,
            time:mealTime,
            description,
            mealId
        }));
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList 
        });
    }



    saveRow() {

        mealList.splice(1);

        const {
            mealList,
            clientId,
            packageId,
            idx
        } = this.props;

        const {
            mealType,
            mealTime,
            mealId,
            description
        } = this.state;
 
        if  (mealTime == "" || mealType == "" || description == "") {
            return;
        }
 
        const dupeCheck = mealList.filter((item,pos) => pos != idx )
            .some(item => item.time == mealTime && item.type == mealType);
         
        if (dupeCheck) {
            alert("Another entry with same meal type and time exists!!");
            return;
        }

        let mealData = {
            mealList: [{
                type:mealType,
                time:mealTime,
                description,
                mealId
            }],
            clientId: clientId,
            packageId: packageId
        };

        $.ajax({
            method: 'POST',
            url: '/api/v1/survey/dietaryrecall/create', 
            data: JSON.stringify(mealData),
            contentType: "application/json",
            dataType: "json",
        }).done((resp)=> {
            if(resp.success == false){
                this.setState({
                    errors: resp.data
                });
                return;
            }
            this.setState({
                successMessage: "Meal List updated Successfully!"
            });
            this.props.getMealTimeDetails(packageId);
            this.setState({ isSaved : true });
        });
    }
 
    deleteRow(){
        const { mealTimeDetails, mealList, idx, packageId } = this.props;  
        const deleteRow = mealList[idx];
        if(deleteRow.mealId){
            $.ajax({
                type: 'POST',
                url: '/api/v1/survey/dietaryrecall/delete',
                data: { id: deleteRow.mealId },
                success: function (response) {
                    if (response.success == true) {
                        this.setState({ successMessage: "Meal Time row deleted Successfully!" })
                    } else if (response.success == false) {
                        this.setState({ errors: response.data })
                    }
                }.bind(this)
            });
        }

        mealList.splice(idx,1);
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList.concat()
        });
    }

    render() {
        let { mealType, mealTime,description,isSaved } = this.state;
        let { mealList,idx , timingList , } = this.props; 
        const item = mealList[idx];
        var times = [];
        var interval = ["00", "15", "30", "45"];
        var row =[];

        for (var i = 0; i < 24; i++) {
            var ampm = 'AM';
            var hour = i > 12 ? i - 12 : i;
            if (i > 11) {
                ampm = 'PM';
            }
            for (var j = 0; j < 4; j++) {
                var hours = hour < 10 ? `0${hour}` : hour;
                var time = hours + ":" + interval[j] + ' ' + ampm;
                times.push({ label: time, value: time });
            }
        }

        var timesOption = []
        var concatTimes = times.splice(16, times.length).concat(times);
        concatTimes.forEach(option => {
            for(var k = 0; k < interval.length; k++) {
                if ((option.label && option.value) == (`0:${interval[k]} AM`)){
                    option.label = `12:${interval[k]} AM`;
                    option.value = `12:${interval[k]} AM`;
                }
            }
            timesOption.push(option);
        });
        const mealTypeOption =  timingList.map(option => {return {label:option,value:option};});
        if (isSaved) {
           return <tr>
                <td style={{width:'20%'}}>{item.mandatory ? item.type+"*" : item.type }</td>
                <td style={{width:'10%'}}>{item.time}</td>
                <td style={{width:'60%'}}>{item.description}</td>
                <td style={{width:'10%'}} className="n5y-btn-list">
                    <button
                        className="w3-button w3-tiny w3-padding-small w3-blue"
                        type="button" 
                        onClick={e => this.setState({isSaved:false})}
                    >
                        Edit
                    </button>
                    { item.mandatory  ?
                        null : <button
                            className="w3-button w3-tiny w3-padding-small w3-red" 
                            type="button" 
                            onClick={this.deleteRow.bind(this)}
                        >
                            delete
                        </button>
                    }
                </td>
            </tr>
        }
        return (
            <tr>
                <td style={{width:'20%'}}>
                    <label> 
                        { item.mandatory ?
                            <label>
                                {mealType+"*"}
                            </label> : <Select
                                name="mealType"
                                id="mealType"
                                type="search"
                                placeholder="Meal Type"
                                value={mealType}
                                onChange={(selected) => this.handleSelect('mealType', selected)}
                                options={mealTypeOption}
                            />
                        }
                    </label>
                </td>
                <td style={{width:'10%'}}>
                    <Select
                        name="mealTime"
                        id="mealTime"
                        type="search"
                        placeholder={mealTime ? mealTime : 'Time'}
                        value={mealTime}
                        onChange={(selected) => this.handleSelect('mealTime', selected)}
                        options={timesOption}
                        clearable={false}
                        matchPos="start"
                        inputClass={"w3-input w3-border w3-round"}
                    /> 
                </td>
                <td style={{width:'60%'}}>
                    <FormTextarea
                        label=""
                        id="mealDesc"
                        name="mealDesc"
                        placeholder="Please type 'NA' if not applicable"
                        onChange={this.handleChange}
                        value={description } 
                        style={{ whiteSpace: 'pre-wrap'}}
                        inputClass={"w3-input w3-border w3-round textarea-wrap"}/>
                </td> 
                <td style={{width:'10%'}}>
                {/*
                    <button className="w3-button w3-tiny w3-padding-small w3-blue" 
                        type="button" 
                        onClick={this.saveRow}>Save</button>
                
                    <button className="w3-button w3-tiny w3-padding-small w3-red" 
                        type="button" 
                        onClick={this.cancelRow}>Cancel</button>
                        */}
                </td> 
            </tr>
        )
    }
}
 
function mapStateToProps(state) {
    return {
        //surveyMealList: state.surveyMealList, 
    }
}


export default connect(mapStateToProps, {
    fireRequest,
    getMealTimeDetails
})(AddMealTimeRow);