import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { connect } from 'react-redux';
import { getDietitianList, getBodyVitalParam, getBodyVitalsDetails, fireRequest } from "../../js/actions.js";
import { FormTextField, FormSelectField } from "../Common/HelperComponents.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

class Accordion extends Component {

    toggleChange(id) {
        this.props.onClick(id);
    }

    render() {
        var category = this.props.category ? this.props.category : '';
        var paramName = this.props.paramName ? this.props.paramName : [];
        var paramId = this.props.paramId ? this.props.paramId : [];
        var categoryItem = [];
        for (var key in paramId) {
            if (paramId[key] == category) {
                categoryItem.push(<div key={key} className="w3-row w3-row-padding w3-padding">
                    <div className="m6 s12 w3-col">
                        <label>{paramName[key]}</label>
                    </div>
                    <div className="m6 s12 w3-col">
                        <FormTextField type="text" onChange={this.props.onChange} id={key} value={this.props.formData[key] ? this.props.formData[key] : ''} name={key}
                            inputClass={"w3-input w3-border"} />
                    </div>
                </div>);
            }
        }

        return (
            <li className="w3-block li-no-border">
                <div className="no-decoration param-category" onClick={this.toggleChange.bind(this, category)} style={{ textAlign: 'center', color: 'ghostwhite', padding: '4px', backgroundColor: '#205081' }}>
                    <h4 className={`accordion-head ${this.props.toggleCls == category ? 'active' : ''}`}>{category}</h4>
                </div>
                <div id={category} className={`w3-border ${this.props.toggleCls == category ? 'w3-show' : 'w3-hide'}`}>
                    {categoryItem}
                </div>
            </li>
        );
    }
}

class VitalBodyManager extends Component {
    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            category: {},
            parameterName: {},
            parameterId: {},
            formData: {},
            nutriForm: {},
            paramId: null,
            btnDisable: false,
            toggleCls: ""
        };
    }

    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealFormOpen',
            value: name
        })
    }

    setModal(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealModal',
            value: name
        })
    }

    componentWillReceiveProps(newProps) {
        $(".report-file-list").show();
        if (newProps.paramId != null && this.state.paramId != newProps.paramId) {
            this.setState({
                paramId: newProps.paramId
            }, function () {
                this.props.getBodyVitalParam(this.state.paramId);
            }.bind(this))
        } else {
            if (this.props.bodyVitalParam) {
                this.setState({
                    formData: this.props.bodyVitalParam.data[0],
                    nutriForm: this.props.bodyVitalParam.data[0],
                })
            }
        }

        if (newProps.bodyVitalParam && newProps.paramId != null) {
            this.setState({
                formData: newProps.bodyVitalParam.data[0],
                nutriForm: newProps.bodyVitalParam.data[0],
                errors: {},
            })
        } else {
            this.setState({
                formData: { client_id: this.props.clientId },
                nutriForm: { client_id: this.props.clientId },
                errors: {},
            })
        }
    }

    componentDidMount() {
        var self = this;
        $.ajax({
            type: 'GET',
            method: 'GET',
            url: '/api/v1/bodyvitals',
        }).done(function (bodyParamsArr) {
            // var bodyParamsArr = JSON.parse(resp);
            var categoryArr = [];
            var paramIdArr = [];
            var paramNameArr = [];
            bodyParamsArr.forEach(function (itm, id) {
                if (itm.category) {
                    var i = categoryArr.indexOf(itm.category);
                    if (!(i > -1)) {
                        categoryArr.push(itm.category);
                    }
                    paramIdArr[itm.id] = itm.category;
                    paramNameArr[itm.id] = itm.name;
                }
            });
            if (self.props.dietitianList == null) {
                self.props.getDietitianList();
            }

            if (!self.state.formData.client_id) {
                self.state.formData['client_id'] = self.props.clientId;
                self.state.nutriForm['client_id'] = self.props.clientId;

            }

            self.setState({
                category: categoryArr,
                parameterName: paramNameArr,
                parameterId: paramIdArr,
                formData: self.state.formData
            });
        });

        if (!this.props.bodyVitalStatusList) {
            this.props.fireRequest('bodyVitalStatusList');
        }
    }

    handleClose() {
        $('.close-button').click();
    }

    handleSubmit(evt,type) {
        evt.preventDefault();
        var self = this;
        let form = this.state.formData;
        if (type == 'nutri') {
            form  = this.state.nutriForm;
        }
        const sendFormData = new FormData();
        self.setState({
            btnDisable: true
        });

        for (var key in form) {
            if (key == 'file') {
                sendFormData.append('file', form['file']);
                delete form['file'];
            }
            if (key == 'nutriFile') {
                sendFormData.append('nutriFile', form['nutriFile']);
                delete form['nutriFile'];

            }
        }
        sendFormData.append('json', JSON.stringify(form));
        let url = '/api/v1/vital/bodyparams/create';

        if (form && form.id) {
            url = '/api/v1/vital/bodyparams/edit';
        }  

        $.ajax({
            type: 'POST',
            url,
            data: sendFormData,
            processData: false,
            contentType: false
        }).done(function (resp) {
            if (resp.success == true) {
                self.props.getBodyVitalsDetails(self.props.clientId);
                if (self.state.paramId) {
                    self.props.getBodyVitalParam(self.state.paramId);
                }
                setTimeout(function () {
                    self.handleClose();
                    $("#file").val('');
                    self.setState({
                        btnDisable: false
                    });
                }, 2000);
            } else if (resp.success == false) {
                self.setState({
                    errors: resp.data,
                    btnDisable: false
                })
            }

            $("#nutriFile").val('');
            $("#file").val('');

        });
    }

    handleChange(evt) {
        var targetElement = $(evt.target).attr('id');
        if (targetElement == 'file') {
            this.state.formData[targetElement] = evt.target.files[0];
        } else {
            this.state.formData[targetElement] = $(evt.target).val();
        }

        this.setState({
            formData: this.state.formData,
        });
    }

    handleChangeModal(evt) {

        let { nutriForm } = this.state;
        let targetElement = $(evt.target).attr('id');
        let regex = new RegExp("(.*?)\.(pdf)$");
        if (targetElement == 'nutriFile') {
            if (nutriForm[targetElement] = evt.target.files[0]) {
                if (!regex.test(nutriForm[targetElement].name)) {
                    alert("Please choose an pdf file");
                    nutriForm[targetElement] = $(evt.target).val();
                    return;
                }
            }
        } else {
            nutriForm[targetElement] = $(evt.target).val();
        }
        this.state.nutriForm['dietitian_id'] = this.props.currentUser.id;
        this.state.nutriForm['notes'] = 'no notes';
        this.setState({
            nutriForm: this.state.nutriForm
        });
    }

    handleModalSubmit(evt) {
        evt.preventDefault();
         
        if (!this.state.nutriForm.nutriFile) {
            alert("Please choose a pdf file to save");
            return;
        }
        this.handleSubmit(evt,'nutri')
    }

    handleFileRemove(evt) {
        $(".path-nd-json").val('');
        this.state.formData['file_upload_path'] = '';
        $(".report-file-list").hide();
    }

    handleToggle(evt) {
        this.setState({ toggleCls: evt })
    }

    render() {
        var data = [];
        var dietitian = [];
        var category = this.state.category ? this.state.category : [];
        var reportFile = '';
        var id = 1;
        var btnDisable = this.state.btnDisable;

        for (var i = 0; i < category.length; i++) {
            var activeCls = '';
            if (id == 1) {
                id++;
                activeCls = 'w3-show';
            }
            data.push(<Accordion active={activeCls}
                onChange={this.handleChange.bind(this)}
                paramName={this.state.parameterName}
                paramId={this.state.parameterId}
                formData={this.state.formData}
                category={category[i]}
                toggleCls={this.state.toggleCls}
                onClick={this.handleToggle.bind(this)} key={i} />);
        }

        dietitian.push({ val: '', name: 'Select Nutritionist' });
        if (this.props.dietitianList) {
            this.props.dietitianList.data.data.forEach(function (item, idx) {
                if (item.status.toUpperCase() != 'ACTIVE') {
                    return;
                }
                dietitian.push(
                    {
                        val: item.id,
                        name: item.first_name + ' ' + item.last_name
                    }
                )
            })
        }

        if (this.state.formData.id) {
            var jsonData = this.state.formData.json_data.length > 0 ? JSON.parse(this.state.formData.json_data) : [];
            if (jsonData && jsonData.file_name) {
                reportFile = <div className="w3-row report-file">
                    <label className="m4 w3-col lbl-report-file report-file-list" type="button" style={{ paddingBottom: '10px' }}>
                        {jsonData.file_name}
                    </label>
                    <button type="button" className="report-file-list w3-button w3-padding-small" 
                        onClick={this.handleFileRemove.bind(this)} title="Remove File">
                        <i className="icon ion-close"></i>
                    </button>
                    <div className="w3-col">
                        <input type="hidden" value={this.state.formData.file_upload_path || ''} className="path-nd-json" name="file_upload_path" id="file_upload_path" />
                        <input type="hidden" value={this.state.formData.json_data || ''} className="path-nd-json" name="json_data" id="json_data" />
                        <input type="file" ref="file" id="file" name="file" onChange={this.handleChange.bind(this)} />
                    </div>
                </div>;
            } else {
                reportFile = <input type="file" ref="file" id="file" name="file" onChange={this.handleChange.bind(this)} />;
            }
        }
        else {
            reportFile = <input type="file" ref="file" id="file" name="file" onChange={this.handleChange.bind(this)} />
        }

        var btnText = 'Save';
        var modalTitle = 'Add Blood/Body Parameters';
        if (this.state.formData.id) {
            btnText = 'Update';
            modalTitle = 'Edit Blood/Body Parameters';
        }

        // var bodyVitalStatus = [];
        //  if (this.props.bodyVitalStatusList) {
        //     var bodyVitalStatusList = this.props.bodyVitalStatusList.data;
        //     for (var idx = 0; idx <= bodyVitalStatusList.length; idx++) {
        //         bodyVitalStatus.push(<option key={'param_status_'+idx}>{bodyVitalStatusList[idx]}</option>)
        //     }
        // }
        return (
            <Fragment>
                <NutrifyReveal openCls={this.props.revealFormOpen} handleClose={this.setRevealClass} 
                    customWidth={'635px'}>
                    <div className="w3-row w3-row-padding">
                        <div className="w3-margin w3-center reveal-heading">
                            {modalTitle}
                        </div>
                        <form encType="multipart/form-data" onSubmit={e => this.handleSubmit(e,'vital')}>
                            <input type="hidden" id="client_id" name="client_id" value={this.props.clientId} />
                            <input type="hidden" id="id" name="id" value={this.state.formData.id ? this.state.formData.id : ''} />
                            <div className="w3-col s12">
                                <div className="w3-row w3-row-padding">
                                    <div className="m3 s12 w3-col">
                                        <label>Note *</label>
                                    </div>
                                    <div className="m9 s12 w3-col">
                                        <FormTextField
                                            type="text"
                                            id="notes"
                                            onChange={this.handleChange.bind(this)}
                                            value={this.state.formData.notes ? this.state.formData.notes : ''}
                                            errors={this.state.errors.notes}
                                            name="notes"
                                            inputClass={"w3-input w3-border w3-margin-bottom"} />
                                    </div>
                                </div>
                                <div className="w3-row w3-row-padding">
                                    <div className="m3 s12 w3-col">
                                        <label>Nutritionist *</label>
                                    </div>
                                    <div className="m9 s12 w3-col">
                                        <FormSelectField
                                            id="dietitian_id"
                                            onChange={this.handleChange.bind(this)}
                                            options={dietitian}
                                            value={(this.state.formData.dietitian_id) ? this.state.formData.dietitian_id : ''}
                                            errors={this.state.errors.dietitian_id}
                                            name="dietitian_id"
                                            selectClass={"w3-select w3-border w3-margin-bottom"} />
                                    </div>
                                </div>
                                <div className="w3-row w3-row-padding">
                                    <div className="m3 s12 w3-col">
                                        <label>Reading Date *</label>
                                    </div>
                                    <div className="m9 s12 w3-col">
                                        <FormTextField
                                            id="reading_date"
                                            name="reading_date"
                                            onChange={this.handleChange.bind(this)}
                                            value={(this.state.formData.reading_date) ? this.state.formData.reading_date : ''}
                                            errors={this.state.errors.reading_date}
                                            type="date"
                                            inputClass={"w3-input w3-border w3-margin-bottom"} />
                                    </div>
                                </div>
                                <div className="m12 s12 w3-col">
                                    <ul id='vital-body-accordion' className="body-params-ul w3-ul">
                                        {data}
                                    </ul>
                                </div>
                                <div className="w3-row">
                                    <div className="m12 s12 w3-col w3-margin-top">
                                        {reportFile}
                                    </div>
                                </div>
                            </div>
                            <div className="w3-col s12 w3-right-align w3-margin-bottom">
                                <button type="button" onClick={this.setRevealClass.bind(this, '')} 
                                    className="w3-button w3-teal w3-hover-teal w3-padding-small">Cancel</button>
                                <button type="submit" className="w3-button btn-space nutrify-btn w3-padding-small" 
                                    disabled={btnDisable}>{btnText}</button>
                            </div>
                        </form>
                    </div>
                    <button className="close-button w3-button w3-display-topright" 
                        onClick={this.setRevealClass.bind(this, '')} aria-label="Close modal" type="button">
                        <span aria-hidden="true" className="w3-xlarge">&times;</span>
                    </button>
                </NutrifyReveal>

                <NutrifyReveal openCls={this.props.revealModal} handleClose={this.setModal}>
                    <form encType="multipart/form-data" onSubmit={e => this.handleModalSubmit(e)}>
                        <input type="hidden" id="client_id" name="client_id" value={this.props.clientId} />
                        <input type="hidden" id="id" name="id" value={this.state.formData.id ? this.state.formData.id : ''} />

                        <div className="modal-container">
                            <div className="w3-margin w3-center reveal-heading">
                                <h4>Upload Nutrition PDF</h4>
                            </div>
                            <button className="close-button w3-btn no-shadow w3-display-topright" 
                                onClick={this.setModal.bind(this, '')} aria-label="Close modal" type="button">
                                <span aria-hidden="true" className="w3-xlarge">&times;</span>
                            </button>
                            <h5 style={{ paddingTop: '15px' }}>Disclaimer : Only files with extension .pdf are supported</h5>
                            <div className="w3-padding-16">
                                <div className="m9 s12 l4 w3-col">
                                    <FormTextField
                                        id="reading_date"
                                        name="reading_date"
                                        onChange={this.handleChangeModal.bind(this)}
                                        value={(this.state.nutriForm.reading_date) ? this.state.nutriForm.reading_date : ''}
                                        errors={this.state.errors.reading_date}
                                        type="date"
                                        inputClass={"w3-input w3-border w3-margin-bottom"} />
                                </div>

                                <div className="w3-col">
                                    <input type="hidden" value={this.state.nutriForm.type|| ''} 
                                        className="path-nd-json" name="nutri" id="type" />
                                    <input type="hidden" value={this.state.nutriForm.json_data || ''} 
                                        className="path-nd-json" name="json_data" id="json_data" />
                                    <input type="file" rel="nutriFile" id='nutriFile' name="nutriFile" 
                                        onChange={this.handleChangeModal.bind(this)} />
                                </div>
                            </div>
                            <button type="submit" className="w3-button w3-blue w3-right" style={{ marginBottom: "10px" }}>Save</button>
                        </div>
                    </form>
                </NutrifyReveal>
            </Fragment>

        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
        revealFormOpen: state.revealFormOpen,
        dietitianList: state.dietitianList,
        bodyVitalParam: state.bodyVitalParam,
        bodyVitalStatusList: state.bodyVitalStatusList,
        revealModal: state.revealModal,
    };
}

export default connect(mapStateToProps, { 
    getDietitianList, 
    getBodyVitalParam, 
    getBodyVitalsDetails, 
    fireRequest 
})(VitalBodyManager);
