import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {getSessionUser, getSurveyQuestionLoadData, getFoodItemLoadData, getLoadCategoryData, getLoadSystemConfigData, getLoadCreditsData} from "../../js/actions.js";
import {LoadingIndicatorImage} from "../Common/HelperComponents.js";

class SettingsLoadData extends Component{

    constructor(args) {
        super(args);

        this.state = {
            foodLoader: false,
            questionLoader: false,
            categoryLoader: false,
            systemConfigLoader:false,
            systemConfigCredits:false,
            syncCreditMessage:null
        };
    } 

    foodItemLoadDataClick() { 
        this.setState({foodLoader: true});
        this.props.getFoodItemLoadData()
        .then((resp) => {
            console.log(resp);
            this.setState({
                foodLoader: false,
            });
        }).catch(ex => {
            console.error(ex);
        });
    }

    surveyQuestionLoadDataClick() {
        const self = this;
        self.setState({questionLoader: true});
        self.props.getSurveyQuestionLoadData().then((response) => {
            self.setState({questionLoader: false});
        });
    }

    categoryLoadDataClick() {
        const self = this;
        self.setState({categoryLoader: true});
        self.props.getLoadCategoryData().then((response) => {
            self.setState({categoryLoader: false});
        });
    }

    syncCredits(){
        const self = this;
        self.setState({systemConfigCredits: true});
        self.props.getLoadCreditsData().then((response) => {
            self.setState({
                systemConfigCredits: false,
                syncCreditMessage: "Credits synced successfully"
            });
            this.props.getSessionUser();
        });
    }

    systemConfigLoadDataClick(){
        const self = this;
        self.setState({systemConfigLoader: true});
        self.props.getLoadSystemConfigData().then((response) => {
            self.setState({systemConfigLoader: false});
        });
    }

    render(){
        var self = this;
        var questionMessage = null;
        if (this.props.questionList && !(this.state.questionLoader)) {
            questionMessage = "successfully synced " + this.props.questionList.data.length + " questions for survey ";
        }
        var foodMessage = null;
        //console.log("load data response list", this.props.foodList);
        if (this.props.foodList && this.props.foodList.data && !(this.state.foodLoader)) {
            foodMessage =  "successfully synced " + this.props.foodList.data.foodDb + " food items, " +
            this.props.foodList.data.supplements + " supplements & " + this.props.foodList.data.recipes + " recipes" ;
        }
        var categoryMessage = null;
        if (this.props.categoryList && !(this.state.categoryLoader)) {
            categoryMessage = "successfully synced " + this.props.categoryList.data.Categories.length
                            + " categories, " + this.props.categoryList.data.Generic.length
                            + " generic & " + this.props.categoryList.data.Timings.length + " timings data";
        }

        var systemConfigMessage = null;
        if(this.props.systemConfigList && !(this.state.systemConfigLoader)){
            systemConfigMessage = "successfully synced "  + this.props.systemConfigList.data.bodyParams.length
                                + " body params"
        }
        
        var btnText = this.state.foodLoader ? <LoadingIndicatorImage /> : "Sync Food Items, Supplements & Recipes";
        var btnTextSurvey = this.state.questionLoader ? <LoadingIndicatorImage /> : "Sync Survey Questions";
        var btnTextCategory = this.state.categoryLoader ? <LoadingIndicatorImage /> : "Sync Category Info";
        var btnTextSystemConfig = this.state.systemConfigLoader ? <LoadingIndicatorImage /> : "Sync Body Params";
        var btnTextSyncCredits = this.state.systemConfigCredits ? <LoadingIndicatorImage /> : "Sync Credits";

        return(
            <div>
                <div  className="w3-padding w3-light-grey w3-margin-bottom" >  
                    <h6>Sync Data</h6>
                    <div className="w3-margin-bottom">
                        <button className="w3-button w3-round w3-block w3-small nutrify-btn"
                        onClick={self.foodItemLoadDataClick.bind(this)}
                        >{btnText}</button>
                        <span className="spinner-loader">
                            {foodMessage}
                        </span>
                    </div>
                    <div className="w3-margin-bottom">
                        <button className="w3-button w3-round w3-block w3-small nutrify-btn" onClick={self.surveyQuestionLoadDataClick.bind(this)}>
                            {btnTextSurvey}
                        </button>
                        {questionMessage}
                    </div>
                    <div className="w3-margin-bottom">
                        <button className="w3-button w3-round w3-block w3-small nutrify-btn" onClick={self.categoryLoadDataClick.bind(this)}>
                            {btnTextCategory}
                        </button>
                        {categoryMessage}
                    </div>

                    <div className="w3-margin-bottom">
                        <button className="w3-button w3-round w3-block w3-small nutrify-btn" onClick={self.systemConfigLoadDataClick.bind(this)}>
                            {btnTextSystemConfig}
                        </button>
                            {systemConfigMessage}
                    </div> 
                    { this.props.admin ?
                        ( 
                            <div className="sync-data-padding">
                                <button className="w3-button w3-round w3-block w3-small nutrify-btn" onClick={self.syncCredits.bind(this)}>
                                    {btnTextSyncCredits}
                                </button>
                                    {this.state.syncCreditMessage}
                            </div> 
                        ) : null
                    }
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        foodList: state.foodItemLoadData,
        questionList: state.surveyQuestionLoadData,
        categoryList: state.loadCategoryData,
        systemConfigList: state.loadSystemConfigData
    };
}
export default connect(mapStateToProps, {
    getSurveyQuestionLoadData,
    getFoodItemLoadData, 
    getLoadCategoryData,
    getLoadSystemConfigData,
    getSessionUser,
    getLoadCreditsData} )(SettingsLoadData);