import React, { Component } from 'react';
import Select from 'react-select'; 
import { connect } from 'react-redux';
import {FormTextField} from "../Common/HelperComponents.js";
import {fireRequest} from "../../js/actions.js";
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';


class AddSupplementRow extends Component{
    constructor(arg){
        super(arg);

        this.handleSupplementSelect = this.handleSupplementSelect.bind(this);
        this.handleSupplementChange = this.handleSupplementChange.bind(this);
    }

    handleSupplementChange(evt) {

        var targetElement = $(evt.target).attr('id');
        var supplementData = Object.assign({}, this.props.dietPlanSupplements);
        supplementData[targetElement] =  $(evt.target).val();

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanSupplements',
            value: supplementData
        }); 
    }

    handleSupplementSelect(value){

        var loadSupplementDetails =[];

        if(this.props.supplementList){
            loadSupplementDetails = this.props.supplementList.data;
        }

        function checkLabel(row) {
            if (row.SupplementName == value.label){
                return row;
            }
        }

        var updateSupplementData = Object.assign({}, this.props.dietPlanSupplements);

        var details = loadSupplementDetails.find(checkLabel);
        updateSupplementData['name'] = details.SupplementName;
        updateSupplementData['link'] = details.Link;
        updateSupplementData['dosage'] = details.Dosage;


        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanSupplements',
            value: updateSupplementData
        });
    }
 

    render(){
  
        var loadSupplementListData = [];
        var loadSupplementOptions = [];
        if (this.props.supplementList){
            loadSupplementListData = this.props.supplementList.data;
            loadSupplementListData.forEach((item, idx) => { 
                loadSupplementOptions.push({
                    value: item.SupplementName, 
                    label: item.SupplementName
                });
            });   
        }
     
        return(
            <div id='add-supplement' className="w3-row w3-row-padding" style={{background:"#ededed",padding:"4px"}}>
                    <div className="w3-col m4 s6">
                        <label>{"Name"}
                            <Select
                                name="select-supplements"
                                id="name"
                                type="search" 
                                onChange={this.handleSupplementSelect}
                                options = {loadSupplementOptions}
                            />
                        </label>
                    </div>
                    <div className="w3-col m3 s6">
                        <FormTextField
                            id="dosage"
                            label="Dosage"
                            onChange={this.handleSupplementChange}
                            value={this.props.dietPlanSupplements.dosage ? this.props.dietPlanSupplements.dosage :'' } 
                            inputClass={"w3-input w3-border w3-padding-small"} />
                    </div>
                    <div className="w3-col m5 s12">
                        <FormTextField
                            id="link"
                            label="Link"
                            onChange={this.handleSupplementChange}
                            value={this.props.dietPlanSupplements.link ? this.props.dietPlanSupplements.link : ''} 
                            inputClass={"w3-input w3-border w3-padding-small"} />
                    </div> 
            </div>
        )
    }
}

function mapStateToProps(state) { 
    return {
        supplementList: state.supplementList,
        dietPlanSupplements: state.dietPlanSupplements,
        rowCancel: state.rowCancel
    };
}

export default connect(mapStateToProps, {fireRequest})(AddSupplementRow);
