import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    getTaxonomy,
    getDietPlanlist,
    getDietPlanPackageProfileList,
    getPaginatedList,
    fireRequest,
} from "../../js/actions.js";
import {FormSelectField,PaginationSwitcher} from "../Common/HelperComponents.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import moment from 'moment';
import MealTimeModal from './MealTimeModal';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';


class SortableTh extends Component {

    constructor(args) {
        super(args);
        this.state = {
            direction: null,
            icon: ""
        };
        this.handleSort = this.handleSort.bind(this);
    }
    handleSort(evt) {
        if (this.state.direction == null || this.state.direction == 'desc') {
            this.setState({ direction: 'asc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-up-b"></i></div> });
        } else {
            this.setState({ direction: 'desc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-down-b"></i></div> });
        }
        this.props.onSort(this.props.title, this.state.direction);
    }
    render() {
        var sortBtn = <div className="sort-indicator"><i className="icon ion-arrow-up-b"></i><i className="icon ion-arrow-down-b"></i></div>;
        if (this.props.title != this.props.current) {
            this.state.icon = sortBtn;
        }
        return <th className="sortable-th" onClick={e => this.handleSort()} >
            <div className="sort-row">
                {this.props.title}
                {this.state.icon}
            </div></th>
    }
}

class _DietPlanPackageTable extends Component {

    mealTimeModalOpen(name, package_id, item_id) {

        var creditInfo = 0;      
        if(this.props.currentUser){
            creditInfo = this.props.currentUser.credits - this.props.currentUser.plans;
        }
        if (creditInfo <=0){
            alert("You are out of Plans. Please refill your Credits to create new Nutrition Plans.");
            return null;
        }
        var values = {
            packId: package_id,
            itemId: item_id,
            target: name
        };
        this.props.timingListModalProps(values);
    }

    render() {

        var url= "/workspace/" + this.props.item.package_id + "/" + this.props.item.id;
        return (
            <tr>
                <td>{this.props.si_no}</td>
                <td>{this.props.item.client_name + ' ' + this.props.item.last_name}</td>
                <td>{this.props.item.description}</td>
                <td>{moment(this.props.item.created_at).format('DD-MMM-YYYY hh:mm:ss')}</td>
                <td>{this.props.item.tags}</td>
                <td>{this.props.item.category}</td>
                <td>{this.props.item.food_preferences}</td>
                <td>{this.props.item.max_calories}</td>
                <td>
                    <Link to={"/workspace/" + this.props.item.package_id + "/" + this.props.item.id + "?planId=" + this.props.plnId} className="no-decoration"> View </Link> |  
                    <a className="no-decoration" onClick={this.mealTimeModalOpen.bind(this,'open',this.props.item.package_id, this.props.item.id)}> Copy </a>
                </td>
            </tr>
        )
        /* removing code as diet plan will be deleted from workspace not diet plan list page */
        //<a onClick={this.handleDelete.bind(this, this.props.item.id)}> Delete </a>
    }
}
const DietPlanPackageTable =  connect((state) =>{
    return {
        currentUser:state.currentUser
    }
})(_DietPlanPackageTable);


class DietPlanSearchCopy extends Component {

    constructor(args) {
        super(args);

        this.state = {
            planId: null,
            formData: {},
            subcat: null,
            planIdforEdit: null,
            revealFormOpen: '',
            filter: false,
            sortColumn:'all',
            mealTimePackId:'',
            mealTimeItemId:'',
            mealTimeCategory:'',
            mealTimingListModal: ''
        };

    }

    handleModalProps(values) {
        this.setState({
            mealTimePackId: values.packId,
            mealTimeItemId: values.itemId,
            mealTimeCategory: values.itemCategory,
            mealTimingListModal: values.target
        });
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'mealTimingListFormOpen',
            value: values.target
        })
    }

    setFormData(id,value) {

        let {packageId, plnId} = this.props;
        let {formData} = this.state;
        let filterQuery = '';

        if (id == 'category') {
            if (formData.category != value) {
                formData.sub_category = '';
            }
        }

        this.state.formData[id] = value;
        this.props.getDietPlanPackageProfileList(this.state.formData);
        this.setState({
            formData: this.state.formData
        });

        if (Object.keys(formData).length > 0) {
            filterQuery = "&" + $.param(formData);
            hashHistory.push("/workspace/"+packageId+"/"+plnId+"?status=all&page=1&order=desc&col=Created At"+ filterQuery);
        }
    }

    handleChange(evt) {
        evt.preventDefault();
        var targetElement = $(evt.target).attr('id');
        this.setFormData(targetElement, $(evt.target).val());
    }

    handleSubmit(evt) {
        //getDietPlanPackageProfileList(this.props.dispatch, this.state.formData);
        this.setState({
            formData: this.state.formData
        })
    }

    handleSelect(evt) {

        if (evt) {
            this.setState({
                subcat: evt.target.value
            })
        }
        var targetElement = $(evt.target).attr('id');
        this.setFormData(targetElement, $(evt.target).val());
        this.props.fireRequest('subCategoryList', [], {
            category: 'Categories',
            parent: evt.target.value
        });
        this.setState({
            formData: this.state.formData
        })
    }

    setRevealClass(name) {
        this.setState({
            packageIdforEdit: null
        }, function () {
            this.setState({
                revealFormOpen: name
            });
        }.bind(this));
    }

    componentDidMount() {

        this.props.getDietPlanPackageProfileList(null);

        this.props.fireRequest('categoryList');

        let categoryName = this.props.query.category;
        if (categoryName) {
            this.props.fireRequest('subCategoryList', [], {
                category: 'Categories',
                parent: categoryName,
            });
        }

        if (this.props.query.col) {
            this.state.sortColumn = this.props.query.col;
        }
        this.props.getPaginatedList('dietPlanPackageProfileList',this.props.query);
        var query = this.props.query;
        let formData = {}; 
        let formKey=['search','label','food_pref','age_group','category','sub_category','min_cal','max_cal'];
            
        Object.keys(query).forEach(function(key) {
            for(var i=0; i < formKey.length; i++){             
                if (key == formKey[i]) {
                    formData[key] = query[key];
                }
            }
        });
    
        this.setState({
            formData
        });
    }

    componentDidUpdate(prevProps, prevState) {               
        var query = this.props.query;
        var prevQuery = prevProps.query;
        if (query.page != prevQuery.page || query.order != prevQuery.order || 
            query.search != prevQuery.search || query.label != prevQuery.label || 
            query.food_pref != prevQuery.food_pref || query.age_group != prevQuery.age_group|| 
            query.category != prevQuery.category || query.sub_category != prevQuery.sub_category || 
            query.min_cal != prevQuery.min_cal || query.max_cal != prevQuery.max_cal) {

            this.props.getPaginatedList('dietPlanPackageProfileList', query);
        }

        if (this.props.timingListModal != prevProps.timingListModal){
    		this.setState({
                mealTimingListModal: this.props.timingListModal
            });
        }
        $("#filter-client").foundation();
    }

    componentWillReceiveProps(nextProps){      
        let formData = {};
        let {query}= this.props;
        let formKey=['search','label','food_pref','age_group','category','sub_category','min_cal','max_cal'];

        if (query != nextProps.query) {
            Object.keys(nextProps.query).forEach(function (key) {
                for (var i = 0; i < formKey.length; i++) {
                    if (key == formKey[i]) {
                        formData[key] = nextProps.query[key];
                    }
                }
            });

            this.setState({
                formData
            });
        }
    }

    orderCopyPlan(col) {
        this.state.sortColumn = col;
        var order = this.props.query.order == 'asc' ? 'desc' : 'asc';
        var page = this.props.query.page ? this.props.query.page : 1;
        let {formData} = this.state;
        let {packageId, plnId} =this.props;
        let filterQuery = '';
        if (Object.keys(formData).length > 0) {
            filterQuery = "&" + $.param(formData);
        }
        hashHistory.push("/workspace/"+packageId+"/"+plnId+"?status=all&page="+page+"&order=" + order + "&col=" + col + filterQuery);
    }

    render() {
        const self = this;
        var rows = [];
        var dietPlanList = this.props.dietPlanList;
        var pagination = '';
        // var dietPlanListFilterList= this.props.dietPlanListFilterList;
        var plnId = this.props.plnId ? this.props.plnId : '';
        var {packageId, query, timingListModal } = this.props;
        var {formData, sortColumn, mealTimePackId, mealTimeItemId, mealTimeCategory} = this.state;
        var keyId = (query.page-1)*20;
        var order = query.order ? query.order : 'desc';
        if (this.props.dietPlanList && this.props.dietPlanList.data.data && this.props.dietPlanList.data.data.length > 0) {
            this.props.dietPlanList.data.data.forEach(function (item) {
                    rows.push(<DietPlanPackageTable key={ "r1_" + keyId++}
                            // getDietPlanPackageProfileList={this.props.getDietPlanPackageProfileList}
                            // targetPackageId = {packageId}
                            item = {item} 
                            plnId = {plnId}
                            si_no={keyId}
                            timingListModalProps={self.handleModalProps.bind(self)} 
                            />);
                }.bind(this));
        }

        if(rows.length > 0){
            let filterQuery = '';
            if (Object.keys(formData).length > 0) {
                filterQuery = "&" + $.param(formData);
            }
            pagination = <div className="pagination-left"> 
                            <ul className="pagination">
                                <li className="w3-padding-small w3-button"><Link to={"/workspace/"+packageId+"/"+plnId+"?status=all&page=1&order="+order+"&col="+ sortColumn + filterQuery}>«</Link></li>
                                <PaginationSwitcher basePath={"/workspace/"+packageId+"/"+plnId}
                                    filterQuery={filterQuery}
                                    order={order}
                                    col={sortColumn}
                                    selected={query.page || 1}
                                    // selected={dietPlanList.data.current_page || 1}
                                    options={dietPlanList.data.last_page} />
                                <li className="w3-padding-small w3-button"><Link to={"/workspace/"+packageId+"/"+plnId+"?status=all&page=" + dietPlanList.data.last_page +"&order="+ order +"&col="+ sortColumn + filterQuery} >»</Link></li>
                            </ul>
                        </div>
        }

        if (rows.length == 0) {
            rows = <tr>
                <td colSpan="9" className="no-data-text" >
                    <div className="alert info">
                        There are no results matching the current filter
                </div>
                </td>
            </tr>
        }

        var options = [];
        var subOption = [];
        var categoryList = this.props.categoryList;
        var subCategoryList = this.props.subCategoryList;

        if (categoryList) {
            for (var idx = 0; idx < categoryList.data.length; idx++) {
                options.push(<option key={idx}>{categoryList.data[idx]}</option>);
            }
        }


        if (subCategoryList) {
            for (var i = 0; i < subCategoryList.data.length; i++) {
                subOption.push(<option key={i}>{subCategoryList.data[i]}</option>);
            }
        }
        return (
            <div className="m12 w3-padding-24"> 
                    <form id="searchPlan" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="w3-row w3-row-padding w3-margin-bottom">
                            <div className="m3 s12 w3-col ">
                                <input type="text" value={(this.state.formData.search) ? this.state.formData.search : ''} onChange={this.handleChange.bind(this)} placeholder="Name"  id= "search" className="w3-input w3-border" style={{height: "40px"}} />
                            </div>
                            <div className="m3 s12 w3-col">
                                <input type="text" value={(this.state.formData.label) ? this.state.formData.label : ''} onChange={this.handleChange.bind(this)} placeholder="Tags"  id= "label" className="w3-input w3-border" style={{height: "40px"}} />
                            </div>
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleChange.bind(this)}
                                           value={(this.state.formData.food_pref) ? this.state.formData.food_pref : ''}
                                           id="food_pref"
                                           name="food_pref"
                                           style={{height: "40px"}}
                                           className="w3-select w3-border">
                                    <option value="">-Food Preferences-</option>
                                    <option value="Vegetarian">Vegetarian</option>
                                    <option value="Non Vegetarian">NonVegetarian</option>
                                    <option value="Non Veg (Occasionally)">Non Veg (Occasionally)</option>
                                    <option value="Eggetarian">Eggetarian</option>
                                    <option value="Vegan">Vegan</option>
                                    <option value="Pescetarian">Pescetarian</option>
                                </select>
                            </div>
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleChange.bind(this)}
                                     value={(this.state.formData.age_group) ? this.state.formData.age_group : ''}
                                     id="age_group"
                                     name="age_group"
                                     style={{height: "40px"}}
                                     className="w3-select w3-border">
                                    <option value="">-Age Group-</option>
                                    <option value="10-20">10-20</option>
                                    <option value="21-30">21-30</option>
                                    <option value="31-40">31-40</option>
                                    <option value="41-50">41-50</option>
                                    <option value="51-60">51-60</option>
                                    <option value="61-70">61-70</option>
                                    <option value="70-80">70-80</option>
                                    <option value="80-100">80+</option>
                                </select>
                            </div>
                        </div>

                        <div className="w3-row w3-row-padding w3-margin-bottom">
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleSelect.bind(this)}
                                 value={(this.state.formData.category) ? this.state.formData.category : ''}
                                 id="category"
                                 name="category" style={{height: "40px"}} 
                                 className="w3-select w3-border">
                                    <option value="">-Category-</option>
                                    {options}
                                </select>
                            </div>
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleChange.bind(this)}
                                 value={(this.state.formData.sub_category) ? this.state.formData.sub_category : ''}
                                 id="sub_category"
                                 name="sub_category" style={{height: "40px"}} 
                                 className="w3-select w3-border">
                                    <option value="">-SubCategory-</option>
                                    {subOption}
                                </select>
                            </div>

                            <div className="m6 s12 w3-col">
                                <div className="m4 s12 w3-col ">
                                    <label>Total Calories Range</label>
                                </div>
                            
                                <div className="m3 s12 w3-col">
                                   <input type="text"
                                             id="min_cal"
                                             name="min_cal"
                                             placeholder="Min"
                                             value={(this.state.formData.min_cal) ? this.state.formData.min_cal : ''}
                                             onChange={this.handleChange.bind(this)} style={{height: "40px"}}
                                             className="w3-input w3-border"/>
                                </div>
                                <div className="m1 s12 w3-col w3-center">
                                    <span>to</span>
                                </div>
                                <div className="m3 s12 w3-col">
                                   <input type="text"
                                             id="max_cal"
                                             name="max_cal"
                                             placeholder="Max"
                                             value={(this.state.formData.max_cal) ? this.state.formData.max_cal : ''}
                                             onChange={this.handleChange.bind(this)} style={{height: "40px"}}
                                             className="w3-input w3-border"/>
                                </div>
                                <div className="m1 s12 w3-col">
                                </div>
                            </div>
                            
                        </div>
                    </form>
                    <div className="w3-responsive">  
                        <table className="w3-table-all listing">
                            <thead>
                                <tr className="w3-light-grey">
                                    <th>Sl.No</th>
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Client')} title="Client"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Nutrition Plan Name')} title="Nutrition Plan Name"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Created At')} title="Created At"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Tags')} title="Tags"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Category')} title="Category"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Food Preferences')} title="Food Preferences"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderCopyPlan.bind(this, 'Maximum Calories')} title="Maximum Calories"
                                        current={this.state.sortColumn} />
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table> 
                        {pagination}
                    </div>

                    {timingListModal == "open" ? <NutrifyReveal openCls={timingListModal} customWidth={"420px"}>
                        <MealTimeModal pack_id={mealTimePackId} item_id={mealTimeItemId} dietplan={this.props.dietPlanList}
                        targetPackageId = {packageId} getDietPlanPackageProfileList={this.props.getDietPlanPackageProfileList}/>
                    </NutrifyReveal>: null}
            </div>
           )
    }
}

function mapStateToProps(state) {
    return {
        dietPlanList: state.dietPlanPackageProfileList,
        categoryList: state.categoryList,
        subCategoryList: state.subCategoryList,
        currentUser: state.currentUser,
        timingListModal: state.mealTimingListFormOpen
    };
}
export default connect(mapStateToProps,{    
    getDietPlanlist,
    getDietPlanPackageProfileList,
    getPaginatedList,
    fireRequest,
})(DietPlanSearchCopy);
