import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { fireRequest, getClientDetails, getBodyVitalsDetails } from "../../js/actions.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import VitalBodyManager from "./VitalBodyManager.js";
import VitalBodyParams from "./VitalBodyParams.js"
import moment from 'moment';
import gNutrifyStore from '../../js/clientStore';



class ParamRows extends Component {

    handleEdit(paramId) {
        if (this.props.handleEdit) {
            this.props.handleEdit(paramId);
        }
    }

    render() {
        var paramData = this.props.item;
        var jsonData = paramData.json_data.length > 0 ? JSON.parse(paramData.json_data) : [];
        var fullPath = paramData.file_upload_path ? paramData.file_upload_path : '';
        var reportFile = fullPath != '' && jsonData.file_name ? <a href={fullPath} target="_blank">{jsonData.file_name}</a> : 'No file';
        return (
            <tr key={"row_" + paramData.id}>
                <td>{paramData.notes}</td>
                <td>{moment(paramData.reading_date).format('DD-MMM-YYYY')}</td>
                <td>{paramData.reports.length}</td>
                <td>{reportFile}</td>
                <td>
                    <a className="no-decoration" onClick={this.handleEdit.bind(this, paramData.id)}>Edit</a>
                </td>
            </tr>
        )
    }
}

class ParamRowNutrition extends Component {

    handleModal(paramId) {
        if (this.props.handleModal) {
            this.props.handleModal(paramId);
        }
    }

    render() {
        var paramData = this.props.item;
        var jsonData = paramData.json_data.length > 0 ? JSON.parse(paramData.json_data) : [];
        var fullPath =  paramData?.file_upload_path ||  '';
        var reportFile = fullPath != '' && jsonData.file_name ? <a href={fullPath} target="_blank">{jsonData.file_name}</a> : 'No file';
        return (
            <tr key={"row_" + paramData.id}>
                <td>{moment(paramData.reading_date).format('DD-MMM-YYYY')}</td>
                <td>{reportFile}</td>
                <td>
                    <a className="no-decoration" onClick={this.handleModal.bind(this, paramData.id)}>Edit</a>
                </td>
            </tr>
        )
    }
}

class ClientBodyParams extends Component {


    constructor(args) {
        super(args);

        this.state = {
            paramIdForEdit: null,
            viewParam: ''
        };
    }

    componentDidMount() {
        this.props.getBodyVitalsDetails(this.props.clientId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.closeBodyVitalView != prevProps.closeBodyVitalView) {
            this.setState({
                viewParam: this.props.closeBodyVitalView
            });
        }
    }

    handleEdit(paramId) {
        if (paramId) {
            this.setState({
                paramIdForEdit: paramId
            }, function () {
                gNutrifyStore.dispatch({
                    type: 'SET_DATA',
                    key: 'revealFormOpen',
                    value: 'open'
                })
            }.bind(this))
        }
    }

    handleModal(paramId) {
        if (paramId) {
            this.setState({
                paramIdForEdit: paramId
            }, function () {
                gNutrifyStore.dispatch({
                    type: 'SET_DATA',
                    key: 'revealModal',
                    value: 'open'
                })
            }.bind(this))
        }
    }

    setRevealClass(name) {
        this.setState({
            paramIdForEdit: null
        }, function () {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealFormOpen',
                value: name
            })
        }.bind(this));
    }

    setModal(name) {
        this.setState({
            paramIdForEdit: null
        }, function () {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealModal',
                value: name
            })
        }.bind(this));
    }

    openBodyVitalView(value) {
        this.setState({ viewParam: value });
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'closeBodyVitalView',
            value: value
        });
    }

    render() {


        var rows = [];
        var rows_modal = [];
        var bodyVitalParams = this.props.bodyVitalParams;
        bodyVitalParams = bodyVitalParams.data ? bodyVitalParams.data : [];
        let filterParam = bodyVitalParams.filter(obj => obj.type == '');
        let filterNutri = bodyVitalParams.filter(obj => obj.type !== '');

        // bodyVitalParams.forEach(function (item, idx) {
        //     rows.push(<ParamRows key={"idx_" + idx} item={item}
        //         handleEdit={this.handleEdit.bind(this)}
        //          />);

        //      rows_modal.push(<ParamRowNutrition key={"idx_" + idx} item={item}
        //         handleModal={this.handleModal.bind(this)}
        //           />);
        // }.bind(this));

        filterParam.forEach(function (item, idx) {
            rows.push(<ParamRows key={"idx_" + idx} item={item}
                handleEdit={this.handleEdit.bind(this)}
            />);
        }.bind(this));

        filterNutri.forEach(function (item, idx) {
            rows_modal.push(<ParamRowNutrition key={"idx_" + idx} item={item}
                handleModal={this.handleModal.bind(this)}
            />);
        }.bind(this));

        if (rows.length == 0) {
            rows.push(<tr key="zero"><td colSpan="5"><div className="alert info">No body parameter for the client.</div></td></tr>);
        }

        if (rows_modal.length == 0) {
            rows_modal.push(<tr key="zero"><td colSpan="5"><div className="alert info">No Nutrition PDF for the client.</div></td></tr>);
        }

        var exportBtn = '';
        var viewBtn = '';
        if (bodyVitalParams.length > 0 && this.props.clientId) {
            exportBtn = <button type="submit" className="w3-button w3-padding-small nutrify-btn w3-right btn-body-vitals">Export</button>
            viewBtn = <button className="w3-button w3-padding-small nutrify-btn w3-right btn-body-vitals" 
                onClick={this.openBodyVitalView.bind(this, 'open')}>View</button>
        }
        return (

            <div className="m12">
                <VitalBodyManager paramId={this.state.paramIdForEdit} clientId={this.props.clientId} />
                <div className="w3-bar">
                    <div className="w3-bar-item w3-right no-padding">
                        <button className="w3-button w3-padding-small nutrify-btn w3-right w3-margin-bottom"
                            onClick={this.setRevealClass.bind(this, 'open')}>
                            Add New Set Of Parameters
                        </button>
                    </div>
                    <div className="w3-bar-item w3-right no-padding">
                        <form action="../api/v1/vital/bodyparams/export-csv" method="get">
                            <input type="hidden" name="client_id" value={this.props.clientId} />
                            {exportBtn}
                        </form>
                    </div>
                    <div className="w3-bar-item w3-right no-padding">
                        {viewBtn}
                    </div>

                </div>
                <div className="w3-responsive">
                    <table className="w3-table-all listing">
                        <thead>
                            <tr>
                                <th>Note</th>
                                <th>Date</th>
                                <th>Parameters</th>
                                <th>Report File</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>

                <div className="w3-bar w3-padding-16">
                    <h4>Nutrition PDF Files</h4>
                    <div className="w3-bar-item w3-right no-padding">
                        <button className="w3-button w3-padding-small nutrify-btn w3-right w3-margin-bottom"
                            style={{ marginRight: "10px" }}
                            onClick={this.setModal.bind(this, 'open')}>
                            Upload Nutrition PDF
                        </button>
                    </div>

                </div>
                <div className="w3-responsive ">
                    <table className="w3-table-all listing">
                        <thead>
                            <th>Date</th>
                            <th>Nutrition File</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {rows_modal}
                        </tbody>

                    </table>

                </div>
                <NutrifyReveal className="params-view" openCls={this.state.viewParam}>
                    <VitalBodyParams clientId={this.props.clientId} bodyVitals={this.props.bodyVitalParams} />
                </NutrifyReveal>
            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        bodyVitalParams: state.bodyVitalParams,
        closeBodyVitalView: state.closeBodyVitalView
    };
}

export default connect(mapStateToProps,
    {
        fireRequest,
        getBodyVitalsDetails,
        getClientDetails
    })(ClientBodyParams);
