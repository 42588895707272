import  React, { Component } from 'react';
import { Link } from 'react-router';
import $ from 'jquery'; 

export default class ResetPassword extends Component {
    constructor(args) {
        super(args);
        this.state  = {
            errorMessage: null,
            successMessage: null
        };

    }

    handleReset(e) {
        e.preventDefault();
        var self = this;
        self.setState({errorMessage: null});
        $.ajax({
            url: '/api/v1/auth/reset-password',
            method: 'POST',
            data: {
                token: $("#token").val(),
                password: $("#password").val(),
                password_confirmation: $("#password_confirmation").val()
            }
        }).done(function (resp) {

            if (resp.success == false) {
                self.setState({
                    errorMessage: resp.message,
                    successMessage: null
                });
            }else {
                self.setState({
                    successMessage: resp.message,
                    errorMessage: null
                });
            }
        });
    }

    render() {
        var content = [];
        if (this.state.errorMessage) {
            content.push(<div className="w3-margin w3-padding error-message ">
              <p>{this.state.errorMessage}</p>
            </div>)

        }

        if (this.state.successMessage) {
            content.push(<div className="w3-margin w3-padding success-message ">
              <p>{this.state.successMessage}</p>
            </div>)
        } else {
            content.push(<div className="w3-border w3-padding">  
                            <input id="token" name="token" type="hidden" value={this.props.params.token}  />
                            <label>New Password
                                <input className="w3-input w3-border w3-margin-bottom" id="password" name="password" type="text" type="password" placeholder="Password" />
                            </label>
                            <label>Confirm Password
                                <input className="w3-input w3-border w3-margin-bottom" id="password_confirmation" name="password_confirmation" type="text" type="password" placeholder="Confirm Password" />
                            </label>
                            <p><button  type="submit" className="w3-button w3-block nutrify-btn">Reset Password</button></p>
                            
                        </div>);

        }
        return (
            <div className="w3-container">
                <div className="w3-row">
                    <div className="m6 l4 w3-quarter login-block"> 

                        <form style={{padding: "50px 0px"}} onSubmit={this.handleReset.bind(this)} method="POST">
                            {content}
                            <p className="w3-center"><a href="/#/login">Login</a></p> 
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}
