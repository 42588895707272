import React, { Component } from 'react';
import $ from 'jquery'; 

export default class NutrifyReveal extends Component {

    static defaultProps() {
        return {
            revealSize: ''
        }
    }
    render() {
        var customWidth = {};

        if (this.props.customWidth) {
            customWidth = {
                width: this.props.customWidth
            }
        }

        if (this.props.openCls == 'open') {
            $('body').addClass('is-reveal-open');
        } else {
            $('body').removeClass('is-reveal-open');
        }

        return (
            <div className= {"w3-modal " + this.props.openCls}>     
                <div className="w3-modal-content" style={customWidth}>     
                    <div className="w3-container">         
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
