import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    getPackageDetails,
    getDietPlanPackageProfileList,
    getdietPlanListbyPackageId,
    setData,
    getDietPlanDayDetails,
    fireRequest
} from "../../js/actions.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import DietPlanSearchCopy from './DietPlanSearchCopy.js';
import PackagePlanList from './PackagePlanList.js';
import DayPlanner from './DayPlanner.js';
import AddMealModal from './AddMealModal.js';
import PlanEditModal from './DietPlanDetails.js';
import { NavigationSwitcher, LoadingIndicator } from "../Common/HelperComponents.js";
import SurveyResponse from "../SurveyQuestions/SurveyResponse.js";
import VitalBodyParams from "../BodyVitals/VitalBodyParams.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';
import AsyncSelect from 'react-select/async';

class CheckBox extends Component {

    toggleChange(evt) {
        if (evt.target.id == 'check_all') {
            this.props.handleCheckAllChange(evt.target.id);
        } else if (evt.target.id == 'check_search_all') {
            this.props.handleSearchCheckAllChange(evt.target.id);
        } else {
            this.props.handleCheckBoxChange(this, evt.target.checked);
        }
    }

    render() {
        let { option, cls, disable } = this.props;
        return (
            <div className={`m12 w3-col w3-left-align w3-margin-left ${cls}`}>
                <label>
                    <input
                        type="checkbox"
                        id={option.id}
                        value={option.value}
                        checked={option.checked}
                        disabled={disable}
                        onChange={this.toggleChange.bind(this)}
                        className="w3-check check-small checkbox-space" />
                    {option.label}
                </label>
            </div>
        )
    }
}


class DietPlanWorkSpace extends Component {

    static defaultProps() {
        return {
            planEditToggleClass: 'w3-hide'
        }
    }

    constructor(args) {
        super(args);
        this.state = {
            packageId: null,
            planID: null,
            showSurvey: true,
            btnDisabled: false,
            message: '',
            hideTime: false,
            hideNutri: false,
            optionSearchArray: [],
            selectedPlan: null,
            searchCheckAll: false,
            searchCheckAllChecked: false,
            handleModal: '',
            targetId: null,
            //outOfCredits: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.props.setData('packageDetail', null);
        this.props.getPackageDetails(this.props.params.packageId, "");
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    componentWillReceiveProps(nextProps) {
        if (this.nextProps != this.props) {
            var disableButton = false;
            if ((nextProps.location.query.planId && nextProps.location.query.planId == 'create') || nextProps.printModeCls == "print-mode") {
                disableButton = true;
            }
            this.setState({
                btnDisabled: disableButton
            })
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && $("#offCanvas").hasClass('w3-show')) {
            $("#offCanvas").removeClass('w3-show');
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handlePlanEditToggle(evt) {
        evt.preventDefault();

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'planEditToggleClass',
            value: 'open'
        })
    }

    handleExport() {
        var state = gNutrifyStore.getState();
        var newCls = '';
        var disableButton = false;
        if (state.printModeCls == '') {
            newCls = 'print-mode';
            disableButton = true;
        } else {
            $(".meal-table-cell").show();
        }
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'printModeCls',
            value: newCls
        })
        this.setState({
            btnDisabled: disableButton
        })
    }

    handleMerge(name) {
        var self = this;
        self.setState({
            handleModal: name
        })
    }

    handlePlanAdd() {
        var self = this;
        var creditInfo = 0;
        if(this.props.currentUser){
            creditInfo = this.props.currentUser.credits - this.props.currentUser.plans;
        }
        if (creditInfo <= 0) {
            this.setState({
                outOfCredits: true
            })
            alert("You are out of Credits. Please refill your Credits to create new Nutrition Plans.");
            return null;
        }

        $.ajax({
            url: '/api/v1/diet/plan/create-plan',
            method: 'POST',
            data: {
                packageId: this.props.params.packageId
            }
        }).done(function (resp) {
            var url = '/workspace/' + resp.data.package_id + "/" + resp.data.id;
            self.props.getPackageDetails(resp.data.id, "");
            hashHistory.push(url);
        });
    }

    handleTabSwitch(source) {

        if (source == "body") {
            this.setState({
                showSurvey: false
            });
        } else {
            this.setState({
                showSurvey: true
            });
        }
    }

    handleHideTime() {
        this.setState({
            hideTime: !this.state.hideTime,
        })
    }

    handleHideNutri() {
        this.setState({
            hideNutri: !this.state.hideNutri,
        })
    }

    exportPDF() {
        var html = $("#diet-plan-table").html();

        if (html == '') {
            alert('You have selected an empty Nutrition Plan to print..');
            return;
        }
        html = html.replace(new RegExp("<!--.*?-->", "g"), "");
        html = html.replace(/<button.*?>.*?<\/button>/igm, '');
        if (this.state.hideTime == true) {
            html = html.replace(/<em.*?>.*?<\/em>/igm, '');
        }

        var topContent = [];
        var topRows = document.getElementsByClassName('day-top-content w3-right');

        for (let idx = 0; idx < topRows.length; idx++) {
            const topRow = topRows[idx].innerHTML;
            topContent.push(topRow);
        }

        var tableHead = [];
        var theads = document.getElementsByClassName('diet-plan-table-thead');

        for (let idy = 0; idy < theads.length; idy++) {
            const thead = theads[idy].innerText;
            if (thead.indexOf("hide") === -1) {
                tableHead.push(thead);
            }
        }
        if (this.state.hideNutri == true) {
            $("#export_header").val('');
        } else {
            $("#export_header").val(topContent);
        }

        $("#export_thead").val(tableHead);
        $("#export_field_id").val(html);
        $("#export_form").submit();
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.params.packageId !== this.props.params.packageId) {
            this.props.getPackageDetails(this.props.params.packageId, "");
        }

        if (this.props.packageDetail && this.props.packageDetail.data == null) {
            this.props.getPackageDetails(this.props.params.packageId, "");
            //  hashHistory.push('/packages/' + this.props.params.packageId);
        }

        if (!isNaN(this.props.params.planId) && !this.props.currentWorkspace) {
            this.props.getDietPlanDayDetails(this.props.params.planId)
        }

        // $('#diet-plan-list').foundation();
        // $(document).foundation(); //TODO - Keep this in check
    }

    callbackFunc(childdata) {
        this.setState({
            message: childdata
        })
    }

    getSearchCheckedValues() {
        let { optionSearchArray } = this.state;
        var checkedSearchOptionsList = [];
        var checkedOptions = optionSearchArray.filter((option, idx) => {
            return option.checked;
        })
        checkedOptions.forEach((option) => {
            checkedSearchOptionsList.push(option.value);
        })
        return checkedSearchOptionsList;
    }

    handleSearchCheckAll() {
        let {
            searchCheckAll,
            searchCheckAllChecked,
            optionSearchArray
        } = this.state;
        if (searchCheckAll === false && searchCheckAllChecked === false) {
            optionSearchArray.forEach((option, idx) => {
                optionSearchArray[idx].checked = true;
            });
        } else if (searchCheckAll === true && searchCheckAllChecked === true) {
            optionSearchArray.forEach((option, idx) => {
                optionSearchArray[idx].checked = false;
            });
        }
    }
 

    toggleSearchCheckAll(evt) {
        let {
            searchCheckAll,
            searchCheckAllChecked,
            showButton
        } = this.state;
        if (evt === 'check_search_all') {
            this.setState({
                searchCheckAll: !searchCheckAll,
                searchCheckAllChecked: !searchCheckAllChecked,
            });
            this.handleSearchCheckAll();
            // if (showButton) {
            //     this.setState({ showButton: false });
            // }
        }
    }

    handleSearchCheckedBoxes(changed, newState) {
        let { optionSearchArray } = this.state;
        var changedSearchOptionArray = optionSearchArray;

        optionSearchArray.forEach((option, idx) => {
            if (option.id === changed.props.option.id) {
                changedSearchOptionArray[idx].checked = newState;
            }
        });

        this.setState({
            optionSearchArray: changedSearchOptionArray
        });

    }

    handlePlanChange(element) {
        this.setState({
            selectedPlan: element
        })
        const self = this; 
        var tempSearchArray = [];
        this.props.fireRequest('mealTimingOptionList', [], {
            dietPlanId: element.value,
        }).then(function (response) {
            response.response.data.forEach((options) => {
                tempSearchArray.push({
                    id: options.hypothetical_time,
                    label: options.hypothetical_time,
                    value: options.hypothetical_time,
                    checked: false
                })
            })
            self.setState({
                optionSearchArray: tempSearchArray,
                targetId: element.value
            })
        })
    }

    getPlanOptions(input, callback) {
        $.ajax({ url: '/api/v1/diet/plan/list?q=' + input }).done(function (resp) {
            var list = [];
            resp.data.forEach(function (item) {
                list.push({
                    value: item.id,
                    label: `${item.description} / ${item.contact_email}`
                });
            })
            callback(list);
        })
    }
    handleSearchPlanCopy(evt) {
        evt.preventDefault();
        var self = this;
        var targetSelectId = this.props.params.planId;
        var packageId = this.props.params.packageId;
        let sourceSelectPlanId = self.state.targetId;
        var selectedSearchTimeList = this.getSearchCheckedValues();

        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/plan/copy-plan',
            data: {
                targetPlanId: targetSelectId,
                sourcePlanId: sourceSelectPlanId,
                sourcePackageId: packageId,
                targetPackageId: packageId,
                selectTimeList: selectedSearchTimeList,
            }
        }).done(function () {
            self.props.getDietPlanPackageProfileList(null);
            let { optionSearchArray } = self.state;
            optionSearchArray.forEach((option, idx) => {
                optionSearchArray[idx].checked = false;
            });
            self.setState({
                searchCheckAll: false,
                searchCheckAllChecked: false,
            });
            window.location.reload();
            self.handleMerge('');
        })
    }

    render() {
        var packageId = this.props.params.packageId;
        var planId = this.props.params.planId;
        var viewPlanId = this.props.location.query.planId ? this.props.location.query.planId : '';

        if (!this.props.packageDetail || this.props.packageDetail == null) {
            return <LoadingIndicator />;
        }

        let details = this.props.packageDetail.data;
        if (!details) {
            return <LoadingIndicator />;
        }

        var showPrintOptions = 'w3-hide';

        if (this.props.printModeCls == 'print-mode') {
            showPrintOptions = 'w3-show';
        }

        let profile = details.profile;
        let plans = details.plans;
        var dPlan = null;
        if (!isNaN(planId)) {
            if (this.props.currentWorkspace && this.props.currentWorkspace.plan) {
                dPlan = this.props.currentWorkspace.plan;
            }
        }

        var age = 'Age:Not Updated';
        if (profile.dob && profile.dob != '0000-00-00') {
            age = Math.floor((moment().diff(profile.dob, 'years', true)) * 10) / 10 + " Years";
        }

        var food = null;
        if (profile.food_preferences) {
            food = <span className="workspace-label alert font-wt">{profile.food_preferences}  </span>
        }
        const self = this;
        let {
            searchCheckAll,
            optionSearchArray,
            searchCheckAllChecked
        } = this.state;
        var content = null;
        var printButton = null;
        var copyButton = null
        var cls = 'workspace-btn';
        var searchCheckBoxes = [];
        var warningText = "Selected Nutrition Plan is empty. Please choose another Nutrition Plan to copy.";



        var topButton = (
            <button
                onClick={this.handlePlanAdd.bind(this)}
                className="workspace-label w3-right text-dotdot w3-button workspace-btn w3-padding-medium"
                style={{ "color": "white" }}
                type="button"
            >
                <i className="fa fa-plus" aria-hidden="true" ></i> |
                Create a Plan
            </button>
        );
        if (this.props.printModeCls == 'print-mode' || viewPlanId == "create") {
            cls = 'w3-green w3-hover-green';
        }
        if (planId == 'create') {
            content = (
                <div className="w3-padding w3-margin w3-border plan-overflow">
                    <DietPlanSearchCopy
                        plnId={planId}
                        packageId={packageId}
                        query={this.props.location.query}
                    />
                </div>
            );
        } else if (!planId || planId == 'list') {
            content = (
                <div className="w3-padding w3-margin  plan-overflow">
                    <PackagePlanList
                        packageId={packageId} />
                </div>
            );
        } else {
            topButton = (
                <button
                    onClick={this.handlePlanEditToggle.bind(this)}
                    className={`workspace-label w3-right w3-button text-dotdot w3-padding-medium${this.state.btnDisabled ? ' no-click' : ''} ${cls}`}
                    disabled={this.state.btnDisabled}
                    type="button"
                >
                    <i className="fa fa-pencil" aria-hidden="true"></i> |
                    {dPlan ? dPlan.description : 'Loading..'}
                </button>
            );

            printButton = (
                <button
                    onClick={this.handleExport.bind(this)}
                    className={"workspace-label w3-left w3-button w3-padding-medium " + cls}
                    type="button"
                >
                    <i className="fa fa-print" aria-hidden="true"></i>
                </button>
            );

            copyButton = (
                <button
                    onClick={this.handleMerge.bind(this, 'open')}
                    className={"workspace-label w3-left w3-button w3-padding-medium " + cls}
                    type="button"
                    disabled={this.state.btnDisabled}

                >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                </button>

            )

            if (optionSearchArray.length > 0) {
                var cls = ''
                optionSearchArray.forEach((option, idx) => {
                    cls = (optionSearchArray.length - 1 == idx ? 'bottom-padding' : '')
                    searchCheckBoxes.push(<CheckBox key={idx} option={option} cls={cls} handleCheckBoxChange={self.handleSearchCheckedBoxes.bind(self)} />)
                });
            }

            content = (
                <DayPlanner
                    viewPlanId={viewPlanId}
                    planId={planId}
                    packageId={packageId}
                    parentCall={this.callbackFunc}
                />
            );
        }

        var menuList = {};
        menuList["workspace/" + packageId + "/list?a=b"] = "Select..";
        menuList["workspace/" + packageId + "/create?status=all&page=1&order=desc&col=Created At"] = "Create Plan Copy";
        menuList["workspace/" + packageId + "/list"] = 'Nutrition Plans List';
        details.plans.forEach(function (item, idx) {
            menuList["workspace/" + packageId + "/" + item.id] = item.description;
        });

        var selected = "workspace/" + packageId + "/" + this.props.params.planId + "?status=all&page=1&order=desc&col=Created At";

        var showPopup = null;
        var clsButtonSurvey = '', clsButtonBody = '';

        var searchValues = {
            id: 'check_search_all',
            label: searchCheckAll == false ? 'Check All' : 'Uncheck All',
            value: searchCheckAll,
            checked: searchCheckAllChecked
        }

        if (this.state.showSurvey) {
            clsButtonSurvey = 'w3-teal';
            showPopup = (
                <div className={"w3-display-container w3-col m12 "}>
                    <SurveyResponse
                        showCloseButton={true}
                        token={details.survey_token}
                    />
                </div>
            );
        } else {
            clsButtonBody = 'w3-teal';
            showPopup = (
                <div className={"w3-display-container w3-col m12"}>
                    <VitalBodyParams clientId={profile.id} />
                </div>
            );
        }

        var contents = [];
        var dietContent = this.props.packageDetail.data.plans;

        if (dietContent) {
            for (var key in dietContent) {
                var valer = dietContent[key];
                contents.push(<option value={valer.id} key={key}>{valer.description}</option>)
            }

        }

        return (
            <div className="off-canvas-wrapper diet-plan-workspace">
                <div className="w3-sidebar w3-block w3-light-grey w3-animate-left w3-card w3-hide off-canvas-panel " id="offCanvas" ref={this.setWrapperRef}>
                    <div className="nutrify-workspace container-sizing-small">
                        <div className="w3-row">
                            <div className="w3-bar w3-light-gray">
                                <button onClick={this.handleTabSwitch.bind(this, 'survey')}
                                    className={"w3-bar-item w3-button " + clsButtonSurvey}>Assessment Response</button>
                                <button onClick={this.handleTabSwitch.bind(this, 'body')}
                                    className={"w3-bar-item w3-button " + clsButtonBody}>Body Parameters</button>

                                <button className="w3-right w3-button w3-black" onClick={(e) => { $("#offCanvas").toggleClass('w3-show') }}>&times;</button>
                            </div>
                            {showPopup}
                        </div>
                    </div>
                </div>
                <div className="off-canvas-content">
                    <div id="app-top-header" className="w3-row align-middle expanded plan-header-row" >
                        <div className="m5  s12 w3-col" >
                            <div className="w3-col s2 m2">
                                <Link className="w3-button w3-blue-gray w3-left w3-round " to="/">
                                    <i className="fa fa-home"></i>
                                </Link>
                            </div>
                            <div className="w3-col s10 m10">
                                <button id="survey-response"
                                    className="workspace-label text-dotdot w3-button w3-padding-medium workspace-btn"
                                    onClick={e => {
                                        e.preventDefault();
                                        $("#offCanvas").toggleClass('w3-show');
                                        this.handleTabSwitch.bind(this, "survey-response");
                                    }} >
                                    <i className="fa fa-clipboard" aria-hidden="true"></i> |
                                    {profile.first_name + " " + profile.last_name}
                                </button>

                                {food}
                                <span className="workspace-label age font-wt">{age}</span>
                                {details.category ? <span className="workspace-label category font-wt">{details.category}</span> : ''}
                            </div>


                        </div>
                        <div className="m7 s12 w3-col" >
                            <div className="m6 s12 w3-col">
                                {topButton}
                            </div>
                            <div className="m2 s2 w3-col">
                                {printButton}
                                {copyButton}
                            </div>
                            <div className="m4 s10 w3-col">
                                <NavigationSwitcher basePath="" map={menuList} selected={selected} />
                            </div>
                        </div>
                        <div className={showPrintOptions}>
                            <form method="POST" id="export_form" method="post" target="_blank" action="/api/v1/client/package/export-pdf">
                                <input type="hidden" id="export_field_id" name="html" value="" />
                                <input type="hidden" id="export_header" name="header" value="" />
                                <input type="hidden" id="export_thead" name="tableHead" value="" />
                                <input type="hidden" name="package_id" value={packageId} />
                                <input type="hidden" name="plan_id" value={planId} />
                                <div className="w3-row w3-row-padding">
                                    <div className="m3 s12 w3-col">
                                        <label className="w3-right w3-padding-small"> Select Print options </label>
                                    </div>
                                    <div className="m3 s12 w3-col">
                                        <select id="layout" name="layout" className="w3-select w3-border" >
                                            <option value="landscape">Landscape</option>
                                            <option value="portrait">Portrait</option>
                                        </select>
                                    </div>
                                    <div className="m3 s12 w3-col">
                                        <select className="w3-select w3-border" name="paper_size" id="paper_size" >
                                            {/*<option>-Select Paper Size -</option>*/}
                                            <option value="a1">A1</option>
                                            <option value="a2">A2</option>
                                            <option value="a3">A3</option>
                                            <option value="a4">A4</option>
                                        </select>
                                    </div>
                                    <div className="m3 s12 w3-col">
                                        <button type="button" onClick={this.exportPDF.bind(this)} className="w3-button nutrify-btn w3-round">
                                            Export to PDF
                                            </button>
                                    </div>
                                </div>
                            </form>
                            <div className="m3 s12 w3-col">
                                <label className="w3-right w3-padding-small"> Select Additional Print options </label>
                            </div>
                            <div className="m3 s12 w3-col w3-padding" >
                                <input type="checkbox" id="myCheck" onClick={this.handleHideNutri.bind(this)} />
                                <label className="w3-large" style={{ cursor: "pointer" }} for="myCheck"> :Hide Nutrition Stats</label>
                            </div>

                            {/* <button className="w3-button w3-indigo" onClick={this.handleHideNutri.bind(this)}>Hide Nutrition Stats</button> */}

                            <div className="m3 s12 w3-col w3-padding">
                                <input type="checkbox" id="mytime" onClick={this.handleHideTime.bind(this)} />
                                <label className="w3-large" style={{ cursor: "pointer" }} for="mytime"> :Hide Meal Time</label>
                            </div>

                            {/* <button className="w3-button w3-indigo" onClick={this.handleHideTime.bind(this)}>{this.state.hideTime ? 'Show time in PDF' : 'Hide Time in PDF'}</button> */}

                        </div>
                    </div>


                    <div className="l12 m12 s12 w3-col">
                        {content}
                    </div>
                    <NutrifyReveal openCls={this.props.currentMeal.openCls}>
                        <AddMealModal packageId={this.props.params.packageId}
                            planId={this.props.params.planId}
                            category={details.category} />
                    </NutrifyReveal>
                    <PlanEditModal planId={this.props.params.planId} />


                    <NutrifyReveal openCls={this.state.handleModal}>
                        <div className="modal-container w3-padding-16">
                            <h4 className="w3-margin w3-center reveal-heading">Select a Nutrition Plan to merge with the existing Plan</h4>
                            <div className="w3-col m10 s10 w3-padding-16" style={{ paddingLeft: "50px" }}>
                                <label>Select Nutrition Plan : </label>
                             

                                <AsyncSelect
                                    key='nut-plan'
                                    name="nut-plan"
                                    className="select w3-round"
                                    value={this.state.selectedPlan}
                                    onChange={this.handlePlanChange.bind(this)}
                                    loadOptions={this.getPlanOptions}
                                />

                                <div className="w3-col s12 m12 w3-padding-16">
                                    <div className="">
                                        {searchCheckBoxes.length !== 0 ? searchCheckBoxes : <p className="alert">{warningText}</p>}
                                    </div>
                                    <div>
                                        {searchCheckBoxes.length !== 0 ?
                                            <CheckBox
                                                option={searchValues}
                                                handleSearchCheckAllChange={self.toggleSearchCheckAll.bind(self)}
                                            /> : null
                                        }
                                    </div>
                                </div>

                                <div className="s12 w3-right">
                                    {searchCheckBoxes.length !== 0 ?
                                        <button
                                            type="button"
                                            onClick={this.handleSearchPlanCopy.bind(this)}
                                            className="w3-button nutrify-btn" 
                                        >
                                            Merge Plan
                                        </button> : null
                                    }
                                </div>
                            </div>
                            <button className="w3-btn no-shadow w3-display-topright"
                                onClick={this.handleMerge.bind(this, '')} aria-label="Close modal" type="button">
                                <span className="w3-xlarge" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </NutrifyReveal>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dietitians: state.dietitianList,
        packageDetail: state.packageDetail,
        currentMeal: state.currentMeal,
        planEditToggleClass: state.planEditToggleClass,
        currentWorkspace: state.currentWorkspace,
        printModeCls: state.printModeCls,
        mealTimingOptionList: state.mealTimingOptionList,
        currentUser: state.currentUser
    };
}
export default connect(mapStateToProps, {
    getPackageDetails,
    getDietPlanPackageProfileList,
    getdietPlanListbyPackageId,
    setData,
    fireRequest,
    getDietPlanDayDetails
})
    (DietPlanWorkSpace);
