import React, { Component } from 'react';


export default class Dashboard extends Component {

	componentDidMount() {
    	
    }

    render() {

        return (
            <div className="w3-row content-panel">
                <div className="m12 w3-col">
            	   <h3>Dashboard</h3>
                </div>
            </div>
        )
    }

}