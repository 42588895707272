import React, { Component } from 'react';
import { Link,hashHistory } from 'react-router';
import { getPaginatedList } from "../../js/actions.js";
import $ from 'jquery'; 

export class FilterList extends Component{
    isEmptyFilter() {

        if (this.props.location.query.status != undefined) {
            return false;
        }

        if (this.state.searchQuery != '') {
            return false;
        }
        return true;
    }
}


export class LoadingIndicator extends Component {
    render(){
       return <div className="center-text w3-center" style={{padding:"100px 0px"}}>Loading...</div>
    }
}

export class LoadingIndicatorImage extends Component {
    render(){
        return <div className="center-text w3-center">Loading...</div>
    }
}

export class ErrorHelperText extends Component {

    static get defaultProps() {
        return {
            errors: null
        }
    }

    render() {
        var errorContent = null;
        if (!this.props.errors) {
            return <span></span>;
        }
        if (typeof (this.props.errors) == 'string') {
            errorContent = (<span className="form-error is-visible">{this.props.errors}</span>);
        } else {
            errorContent = (<p className="form-error is-visible">{this.props.errors.join(',')}</p>);
        }
        return errorContent;
    }

}

export class FormTextField extends Component {

    static get defaultProps() {
        return {
            type : "text",
            valueChange: null,
            inputClass: '',
            noLabel: false,
            onBlur: function () {

            },
            defaultValue: '',
            textClass: ''
        }
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }
        var errorClass = '';
        if (this.props.errors != undefined) {
            errorClass = 'is-invalid-input';
        }

        var inputClass = "";
        if (this.props.inputClass != undefined) {
            inputClass = this.props.inputClass;
        }
        
        var topClass = "";
        if (this.props.topClass != undefined) {
            topClass = this.props.topClass;
        }

        return (
            <div className={topClass}>
                <label>{this.props.label}
                    <input type={this.props.type} id={this.props.id} name={this.props.name}
                        value={this.state.value}
                        className={this.props.textClass}
                        onBlur={this.props.onBlur}
                        onChange = {this.props.onChange} placeholder={this.props.placeholder}
                        className={this.props.inputClass + " " + errorClass}
                    />

                    <ErrorHelperText errors={this.props.errors} />
                </label>
            </div>
        );
    }
}

export class NavigationSwitcher extends Component {
    handleChange(evt) {
        const page = this.props.page ? this.props.page : 1;
        const gender = this.props.gender ? this.props.gender : '';
        const age_group = this.props.age_group ? this.props.age_group : '';
        const join = this.props.join ? this.props.join : '';
        const cat= this.props.cat ? this.props.cat : '';
        const client_type= this.props.client_type ? this.props.client_type : '';
        const sub_category=this.props.sub_category ? this.props.sub_category : '';
        const nutritionist=this.props.nutritionist ? this.props.nutritionist : '';
        const duration= this.props.duration ? this.props.duration : '';
        const start= this.props.start ? this.props.start : '';
        const end=this.props.end ? this.props.end : '';
        if (this.props.options) {
            const searchQuery = this.props.query ? this.props.query : '';
            hashHistory.push(this.props.basePath + "?status=" + evt.target.value + "&page=" + page + "&q=" + searchQuery + "&gender=" + gender + "&age_group=" + age_group + "&join=" + join + "&cat=" + cat + "&sub_category=" + sub_category + "&client_type=" + client_type + "&nutritionist=" + nutritionist + "&duration=" + duration + "&start=" + start + "&end=" + end);
        }
        else if (this.props.map) {
            hashHistory.push(this.props.basePath + "/" + evt.target.value);
        }
    }
    componentDidUpdate() {
        if (this.props.selected) {
            $(this.refs.own_select).val(this.props.selected);
        }
    }
    render() {
        var options = [];
        if (this.props.options) {
            options.push(<option key="all" value="">All</option>);
            if (this.props.options) {
                var list = this.props.options.forEach(function (item) {
                    options.push(<option key={item} value={item}>{item}</option>);
                });
            }
        }

        if (this.props.map) {
            for (var key in this.props.map) {
                var val = this.props.map[key];
                options.push(<option key={key} value={key}>{val}</option>);
            }
        }
        return <select className="w3-select w3-border" ref="own_select" onChange={this.handleChange.bind(this)}>
            {options}
        </select>
    }
}
export class FormTextarea extends Component {

    static get defaultProps() {
        return {
            valueChange: null
        }
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }
        var errorClass = '';
        var errorContent = null;
        if (this.props.errors != undefined) {
            errorClass = 'is-invalid-input';
        }

        return (
             <div>
                <label>{this.props.label}
                    <textarea id={this.props.id}
                        name={this.props.name}
                        onChange = {this.props.onChange}
                        onBlur = {this.props.onBlur}
                        placeholder = {this.props.placeholder}
                        className={this.props.inputClass + " " + errorClass}
                        value={this.state.value} style={this.props.style}></textarea>
                    <ErrorHelperText errors={this.props.errors} />
                </label>
              </div>

        );
    }
}

export class FormSelectField extends Component {

    static get defaultProps() {
        return {
            valueChange: null,
            options: [],
            disabled: false
        }
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null,
            optionList : []
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value,
            optionList: newProps.options
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }

        if (this.state.optionList.length == 0) {
            this.state.optionList = this.props.options;
        }

        var selOpts = null;
        var errorClass = '';
        if (this.props.errors != undefined) {
            errorClass = 'is-invalid-input';
        }

        selOpts = this.state.optionList.map(function (item) {
            return (
                <option key={item.val} value={item.val}>{item.name}</option>
            );
        });
        return (
            <div>
                <label>{this.props.label} 
                    <select id={this.props.id} name={this.props.name}
                        value={this.state.value}
                        disabled={this.props.disabled}
                        onChange = {this.props.onChange}
                        className={this.props.selectClass + " " + errorClass}>
                        {selOpts}
                        {this.props.children}
                    </select>
                    <ErrorHelperText errors={this.props.errors} />
                </label>
            </div>
        );
    }
}

export class PaginationSwitcher extends Component {

    render() {
        var options = [];
        var filter = this.props.filter ? this.props.filter : 'all';
        var order = this.props.order ? this.props.order : 'asc';
        var searchQuery = this.props.query ? this.props.query : '';
        var filterQuery = this.props.filterQuery ? this.props.filterQuery : '';
        var startDate = this.props.startDate ? this.props.startDate : '';
        var endDate = this.props.endDate ? this.props.endDate : '';
        var joinDate = this.props.joinDate ? this.props.joinDate : '';

        if (this.props.options) {
            var active = '';
            for (var i = 1; i <= this.props.options; i++) {
                if(i < this.props.selected - 5){
                    continue;
                }
                if(i == +this.props.selected + +5){
                    break;
                }
                active = i == this.props.selected ? 'nutrify-btn' : '';
                options.push(
                    <li className={"w3-padding-small w3-button "+ active} key={i.toString()}>
                        <Link
                            to={{
                                pathname: this.props.basePath,
                                query: {
                                    status: filter,
                                    page: i,
                                    q: searchQuery,
                                    order,
                                    col: this.props.col,
                                    filterQuery,
                                    start: startDate,
                                    end: endDate,
                                    join: joinDate
                                }
                            }}>{i}</Link>
                    </li>
                );
            }
        }
        if(options.length == 0){
            return null;
        }
        return options;
    }
}

// disabled={this.props.disabled}
