import React, { Component } from 'react';

export default class ExportData extends Component{

    constructor(args) {
        super(args);
    }

    render(){
        return(
            <div>
                { this.props.admin &&
                    ( <div  className="w3-padding w3-light-grey w3-margin-bottom">              
                        <h5 className="w3-margin-bottom section-header">Export Data</h5>
                        <div className="w3-margin-bottom">
                            <a href="../api/v1/client/package/export-csv"
                            className="w3-button w3-round w3-block w3-small nutrify-btn">Package List</a>
                        </div>
                        <div className="w3-margin-bottom">
                                <a href="../api/v1/client/profile/export-csv"
                                className="w3-button w3-round w3-block w3-small nutrify-btn">Client List</a>
                        </div>
                        <div className="w3-margin-bottom"> 
                            <a href="../api/v1/dietitian/profile/export-csv"
                                className="w3-button w3-round w3-block w3-small nutrify-btn">Nutritionist Profiles
                            </a>
                        </div>
                    </div>
                )
                }               
            </div>
        )
    }
}
