import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {fireRequest,getClientDetails,getBodyVitalsDetails} from "../../js/actions.js";
import VitalBodyManager from "../BodyVitals/VitalBodyManager.js";
import ClientBodyParams from "../BodyVitals/ClientBodyParams.js"
import moment from 'moment';
import NutrifyReveal from '../Common/NutrifyReveal';
import PackageCreateEditModal from '../Packages/PackageCreateEditModal';
import gNutrifyStore from '../../js/clientStore';

class ClientPackageList extends Component {
    handleEdit(packageData) {
        if (this.props.handleEdit) {
            this.props.handleEdit(packageData);
        }
    }

    render() {
        var packageList = [];
        let { clientId, packages} = this.props;
        if (!packages || packages.length == 0) {
            return (
                <div>No packages for the client!</div>
            );
        } else if (packages.length > 0) {
            packages.forEach((item, idx) => {
                packageList.push(
                    <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.dietitian_name}</td>
                        <td>{moment(item.start_date).format('DD-MMM-YYYY')}</td>
                        <td>{moment(item.end_date).format('DD-MMM-YYYY')}</td>
                        <td>{item.status}</td>
                        <td>
                            <Link className="no-decoration" to={"/workspace/" + item.id + "/list"}>View Package </Link>|
                            <Link className="no-decoration" to={`/survey/${item.survey_token}/`}> Assessment Form </Link>|
                            <a className="no-decoration" onClick={this.handleEdit.bind(this, item)}> Edit</a>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="w3-responsive">
                <table className="w3-table-all listing">
                    <tbody>
                        <tr>
                            <th>Sl. No</th>
                            <th>Nutritionist</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Package Status</th>
                            <th>Action</th>
                        </tr>
                        {packageList}
                    </tbody>
                </table>
            </div>
        )
    }
}



class ClientDetails extends Component {
    constructor(args) {
        super(args);
        this.state = {
            evealFormOpen: ''
        }
    }

    componentDidMount() {
        this.handleUpdate();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.params.id != this.props.params.id) {
            this.props.getClientDetails(newProps.params.id);
        }
    }

    handleUpdate() {
        this.props.getClientDetails(this.props.params.id)
    }

    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: {}
        });
        this.setState({
            revealFormOpen: name
        });
    }

    handleEdit(packageData) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: packageData
        });
        this.setState({
            revealFormOpen: 'open'
        });
    }
   
    render() {
        let data = null;
        const { clientDetails } = this.props;
        if (!clientDetails || clientDetails == null) {
            return (
                <div>Loading...</div>
            );
        } else {
            data = clientDetails.data ;
        }
        return (
            <div className="w3-container  w3-padding">
                <div className="w3-row">
                    <div className="s12">
                        <h4 style={{fontWeight: "bold"}}>Client Details</h4>
                    </div>
                    <hr />
                </div>
                <div className="s12">
                    <h4>Personal Details</h4>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Name
                        </div>
                        <div className="s6 m8">
                            <b>{data.first_name} {data.last_name}</b>
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Gender
                        </div>
                        <div className="s6 m8">
                            {data.gender}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            DOB
                        </div>
                        <div className="s6 m8">
                            {((data.dob != null) && (data.dob != '0000-00-00')) ?(moment(data.dob).format('DD-MMM-YYYY')):''}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Email
                        </div>
                        <div className="s6 m8">
                            {data.contact_email}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Phone
                        </div>
                        <div className="s6 m8">
                            {data.phone}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Nationality
                        </div>
                        <div className="s6 m8">
                            {data.nationality}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Occupation
                        </div>
                        <div className="s6 m8">
                            {data.occupation}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="s6 m4 w3-col">
                            Blood Group
                        </div>
                        <div className="s6 m8">
                            {data.blood_group}
                        </div>
                    </div>
                    <hr />
                    <h4>Body Vitals</h4>
                    <ClientBodyParams clientId={this.props.params.id}/>
                    <hr />
                    <h4>Package Details</h4>
                    <ClientPackageList
                        packages={data.packages}
                        clientId={this.props.params.id}
                        handleEdit={this.handleEdit.bind(this)}
                    />
                </div>
                <NutrifyReveal openCls={this.state.revealFormOpen} customWidth={"600px"}>
                    <PackageCreateEditModal
                        query={this.props.location.query}
                        params={this.props.params}
                        updateClientDetails={this.handleUpdate.bind(this)}
                    />
                    <button className="close-button w3-button  w3-display-topright" onClick={this.setRevealClass.bind(this, '')}  aria-label="Close modal" type="button">
                        <span aria-hidden="true" className="w3-xlarge">&times;</span>
                    </button>
                </NutrifyReveal>   
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientDetails: state.clientDetails
    };
}
export default connect(mapStateToProps,{getClientDetails})(ClientDetails);
