import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select'; 
import { Link, hashHistory } from 'react-router';
import NutrifyReveal from "../Common/NutrifyReveal.js";
import {FormTextField, FormTextarea, LoadingIndicator} from "../Common/HelperComponents.js";
import {getPackageDetails, getDietPlanPackageProfileList, getSupplementDetails, fireRequest} from "../../js/actions.js";
import SupplementInfo from '../Supplements/SupplementInfo.js';
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

import CreatableSelect, { makeCreatableSelect } from 'react-select/creatable';

class PlanEditModal extends Component {
    constructor(arg) {
        super(arg);
 
        this.state = { 
            planInfo: {},
            disableSavebutton: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleRecipeChange = this.handleRecipeChange.bind(this);
    }

    handleDeletePlan() {
        var self = this;
        if (!window.confirm("Are you Sure you want to delete this Nutrition Plan?")) {
            return;
        }

        var packageId = self.props.planInfo.package_id;
        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/plan/delete',
            data: {planId: this.props.planInfo.id}
        }).done(function (resp) {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'planEditToggleClass',
                value: 'hide'
            });
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'dietPlanListbyPackageId',
                value: null
            });
            self.props.getPackageDetails(packageId, "");
            self.props.getDietPlanPackageProfileList();
            var url= '/workspace/' + packageId + "/list";
            hashHistory.push(url);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.nextProps != this.props) {
            this.state.planInfo = nextProps.planInfo;
        }
        //return true;
    }

    componentDidMount() {

        this.setState({
            planInfo: this.props.planInfo
        });
     
        if (!this.props.tagList) {
           this.props.fireRequest('tagList');
        }
        if (!this.props.recipeList) {
           this.props.fireRequest('recipeList');
        }
    }

    handleChange(evt) {
        var targetElement = $(evt.target).attr('id');
        this.state.planInfo[targetElement] = evt.target.value;
        this.forceUpdate();
    }

    handleLabelChange(value){
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanLabels',
            value: value
        });
    }

    handleRecipeChange(value){
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'dietPlanRecipes',
            value: value
        });
    }

    handlePlanInfoSave() {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'planEditToggleClass',
            value: 'hide'
        });
    }


    handleSubmit(evt) {
        evt.preventDefault();
        var self = this;
        this.setState({
            disableSavebutton: true,
        });

        if (this.state.planInfo && this.state.planInfo.id) {
            $.ajax({
                type: "POST",
                url: '/api/v1/diet/plan/edit',
                data: this.state.planInfo,
                success: function (response) {
                    self.handlePlanInfoSave();
                    if (response.success == true) {
                        this.setState({
                            disableSavebutton:false,
                            successMessage: "Nutrition Plan Details edited successfully!"
                        });
                    } else if (response.success == false) {
                        this.setState({
                            disableSavebutton:false,
                            errors: response.data
                        });
                    }
                    var state = gNutrifyStore.getState();
                    var workspace = Object.assign({}, state.currentWorkspace);
                    workspace.plan = response.data;
                    gNutrifyStore.dispatch({
                        type: 'SET_DATA',
                        key: 'currentWorkspace',
                        value: workspace
                    });
                    var editedPackageId = workspace.plan.package_id;
                    self.props.getPackageDetails(editedPackageId, "");
                    self.props.getDietPlanPackageProfileList();
                }.bind(this)
            });
        }
        var label_list =[];
        if(this.props.dietPlanLabels!= null){
            label_list = this.props.dietPlanLabels.map(item=>item.label);
        }
        $.ajax({
            type: "POST",
            url: '/api/v1/diet/planlabel/create',
            data: { planId: self.props.planInfo.id,
                    packageId: self.props.planInfo.package_id,
                    labels:label_list
                },
            success: function (response) {
                if (response.success == true) {
                    this.setState({
                        disableSavebutton:false,
                        successMessage: "Nutrition Plan Labels added successfully!"
                    });
                } else if (response.success == false) {
                    this.setState({
                        disableSavebutton:false,
                        errors: response.data
                    });
                }
            }.bind(this)
        }); 

         // var state = gNutrifyStore.getState();
         // if(state.dietPlanSupplements){
        var recipesDetails = [];
        var recipes_list = [];

        if (this.props.recipeList) {
            recipesDetails = this.props.recipeList.data;
        }

        if (this.props.dietPlanRecipes) {
    
            recipes_list = this.props.dietPlanRecipes.reduce((agg,item) => {
                var details = recipesDetails.find(row => row['Recipe Name'] == item.label);
                if (!details){
                    return agg;
                }
                var recipe = {
                    name: item.label,
                    link: details.Link,
                    meal: details['Meal'],
                    dish_type: details['Dish Type']
                }
                agg.push(recipe);
                return agg;
            },[]);
        }
        $.ajax({
            type: "POST",
            url: '/api/v1/diet/planrecipe/create',
            data: {
                planId: self.props.planInfo.id,
                packageId: self.props.planInfo.package_id,
                recipes: recipes_list
            },
            success: function (response) {
                if (response.success == true) {
                    this.setState({
                        disableSavebutton: false,
                        successMessage: "Nutrition Plan Recipes added successfully!"
                    });
                } else if (response.success == false) {
                    this.setState({
                        disableSavebutton: false,
                        errors: response.data
                    });
                }
            }.bind(this)

        });
        if(this.props.dietPlanSupplements.name !=""){
        	$.ajax({
                type: "POST",
                url: '/api/v1/diet/supplement/create',
                data: { planId: self.props.planInfo.id,
                        packageId: self.props.planInfo.package_id,
                        name: self.props.dietPlanSupplements.name,
                        link: self.props.dietPlanSupplements.link,
                        dosage: self.props.dietPlanSupplements.dosage,
                    },
                success:  (response) => {
                    if (response.success == false) {
                        this.setState({
                            errors: response.data
                        }); 
                        return;
                    }
                    this.setState({
                        disableSavebutton:false,
                        successMessage: "Nutrition Plan Details edited successfully!"
                    });
                    gNutrifyStore.dispatch({
                        type: 'SET_DATA',
                        key: 'dietPlanSupplements',
                        value: {}
                    });
                    gNutrifyStore.dispatch({
                        type: 'SET_DATA',
                        key: 'closeSupplements',
                        value: true
                    });
                    self.props.getSupplementDetails(self.props.planInfo.id);
                } 
            });
        }
        return false;
        
    }


    render() {

        var tagListData = [];
        var labelListData = [];
        if (this.props.tagList){
            tagListData = this.props.tagList.data;
            tagListData = tagListData.sort();
            for(var idx=0; idx < tagListData.length; idx++){
                labelListData.push( {value: tagListData[idx], label: tagListData[idx]});
            } 
        }
        var recipeListData = [];
        var recipeListOptions = []

        if(this.props.recipeList){
            recipeListData = this.props.recipeList.data;
            recipeListData = recipeListData.sort();
            recipeListData.forEach(recipe => {
                recipeListOptions.push({
                    label: recipe['Recipe Name'],
                    value: recipe['Recipe Name'],
                })
            });
        }

        var planInfo = this.state.planInfo;
        var editToggle = this.props.planEditToggleClass;
        if (!planInfo){
            if(editToggle == 'open') {
                return <LoadingIndicator />; 
            }else{
                return null;
            }
        }

        return (
            <NutrifyReveal openCls={this.props.planEditToggleClass} customWidth={"75%"}>    
                <div className="w3-row">
                    <div className="w3-row w3-margin">
                        <div> 
                            <button className="w3-button w3-left w3-red w3-small w3-padding-small"   
                                onClick={this.handleDeletePlan.bind(this)}>
                                Delete Plan
                            </button>
                        </div>
                        <div className="w3-center reveal-heading"> 
                            <h4>Nutrition Plan Details</h4>
                        </div>
                    </div>
                    <div className="m12 w3-col w3-padding">
                        <form
                            name="dietPlanForm"
                            method="POST"
                            onSubmit={this.handleSubmit.bind(this)}
                        >
                            <label>Nutrition Plan Description
                                <FormTextField
                                    id="description"
                                    placeholder="Description"
                                    onChange={this.handleChange}
                                    value={(planInfo.description) ? planInfo.description : ''} 
                                    inputClass={"w3-input w3-border w3-margin-bottom"}
                                />
                            </label>
                            <div className="w3-row w3-margin-bottom">
                                <div className="w3-col m4 w3-padding-small">
                                    <label>Plan Start Date
                                        <input
                                            id = "start_date"
                                            name = "start_date"
                                            onChange = {this.handleChange}
                                            value = {(planInfo.start_date) ? planInfo.start_date : ''}
                                            type = "date" 
                                            className="w3-input w3-border"/>
                                    </label>  
                                </div>
                                <div className="w3-col m4 w3-padding-small">
                                    <label>Plan End Date
                                        <input
                                            id="end_date"
                                            name="end_date"
                                            onChange={this.handleChange}
                                            value={(planInfo.end_date) ? planInfo.end_date : ''}
                                            type = "date" 
                                            className="w3-input w3-border"/>
                                    </label>    
                                </div>
                                <div className="w3-col m4 w3-padding-small">
                                    <label>Select Start Day
                                        <select id="day_format"  
                                                onChange={this.handleChange}
                                                selected={(planInfo.day_format) ? planInfo.day_format : ''}
                                                className="w3-select w3-border">
                                            <option>-Select Start Day-</option>
                                            <option value="day_numbers">Show Day Numbers</option>
                                            <option value="week_day">Show Days of Week</option>
                                            <option value="custom">Custom</option> 
                                        </select>
                                    </label> 
                                </div>
                            </div>
                            <label>{"Add Labels"}
                                <div id='add-label'>
                                    <CreatableSelect
                                        options = {labelListData}
                                        value = {this.props.dietPlanLabels} 
                                        isMulti
                                        creatab
                                        type="search"
                                        onChange = {this.handleLabelChange}
                                    />
                                </div>
                            </label>
                            <SupplementInfo
                                planId={planInfo.id}
                                packageId={planInfo.package_id}
                            /> 
                            <label>{"Add Recipes"}
                                <div id='add-recipes' className="w3-margin-bottom">
                                    <Select
                                        options = {recipeListOptions}
                                        value = {this.props.dietPlanRecipes} 
                                        isMulti
                                        type="search"
                                        onChange = {this.handleRecipeChange}
                                    />
                                </div>
                            </label>
                            <label>{"Notes for Nutrition Plan PDF"}
                                <textarea
                                    id="note"
                                    value={planInfo.note}
                                    onChange={this.handleChange}
                                    className="w3-input w3-border"
                                />
                            </label> 
                            <div id='save-diet-plan' style={{marginTop: "10px"}}> 
                                <button
                                    type="submit"
                                    disabled={this.state.disableSavebutton}
                                    className="w3-button w3-right nutrify-btn w3-padding-small"
                                >
                                    Save Plan
                                </button>
                            </div> 
                        </form> 
                    </div>  
                    <button
                        className="w3-btn no-shadow w3-display-topright" 
                        onClick={this.handlePlanInfoSave.bind(this)}  
                        aria-label="Close modal" 
                        type="button"
                    >
                        <span className="w3-xlarge" aria-hidden="true">&times;</span>
                    </button>
                </div>
            </NutrifyReveal>
        );
    }
}

function mapStateToProps(state) {
    return {
        planEditToggleClass: state.planEditToggleClass,
        planInfo: state.currentWorkspace.plan,
        dietPlanLabels: state.dietPlanLabels,
        dietPlanSupplements: state.dietPlanSupplements,
        supplementDetails: state.supplementDetails,
        tagList: state.tagList,
        recipeList: state.recipeList,
        dietPlanRecipes: state.dietPlanRecipes
    };
}
export default connect(mapStateToProps, {getPackageDetails,
    getDietPlanPackageProfileList,
    getSupplementDetails,
    fireRequest})(PlanEditModal);