import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getSurveyWorkoutDetails} from "../../js/actions.js";
import moment from 'moment';

class WorkoutDetails extends Component {
    componentDidMount() { 
        const { packageDetail } = this.props;
        if (!packageDetail || !packageDetail.data) {
            return;
        }
        this.props.getSurveyWorkoutDetails(packageDetail.data.id);
    }

    render(){
        const {
            packageDetail,
            workout,
            notes
        } = this.props;
        var workoutTable = [];
        var trainingTable = [];
        var daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let tableData = null;
        
        if (!workout || !workout.data ) {
            return null
        }
        workout.data.forEach((item, idx) => { 
            if (item.category == 'workout') {
                var workoutDays = [];
                for (var i = 0; i < daysList.length; i++) {
                    if (item[`${daysList[i].toLowerCase()}`] == '1') {
                        workoutDays.push(daysList[i]);
                    }
                }
                workoutTable.push(
                    <tr key={idx}>
                        <td>{item.routine_type}</td>
                        <td>{moment(item.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                        <td>{moment(item.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                        <td className="desc-col">
                        {workoutDays.toString()}    
                        </td>
                    </tr>
                )
            } else  {
                var trainingDays = [];
                for (var i = 0; i < daysList.length; i++) {
                    if (item[`${daysList[i].toLowerCase()}`] == '1') {
                        trainingDays.push(daysList[i]);
                    }
                }
                trainingTable.push(
                    <tr key={idx}>
                        <td>{item.routine_type}</td>
                        <td>{moment(item.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                        <td>{moment(item.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                        <td className="desc-col">
                        {trainingDays.toString()}    
                        </td>
                    </tr>
                )
            }
        });
        
        var noData = (<tr>
            <td colSpan="10">
                No data available
            </td>
        </tr>);

        if (workoutTable.length == 0) {
            workoutTable = noData 
        }

        if (packageDetail.data.category == 'Sports') {
            if(trainingTable.length == 0){
                trainingTable = noData;
            }        
            tableData = (
                <div className="w3-col m12 s12">
                    <h6>Training</h6>
                    <div className="w3-responsive">
                        <table className="sports-survey w3-table-all">
                            <thead>
                                <tr className="w3-light-grey">
                                    <th>Type</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trainingTable}
                            </tbody>
                        </table>
                    </div>    
                </div>
            );
        } 

        return (
            <div className="left-padding"> 
                <div className="w3-row">
                    {tableData}
                    <div className="w3-col m12 s12">
                        <h6>General Fitness</h6>
                        <div className="w3-responsive">    
                            <table className="sports-survey w3-table-all">
                                <thead>
                                    <tr className="w3-light-grey">
                                        <th>Type</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workoutTable}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="w3-row w3-margin-top">
                    <div className="w3-col m12 s12">
                        <div className="w3-col s12 m7">
                            Notes:
                        </div>
                        <div className="w3-col s12 m5" style={{ whiteSpace: "pre-wrap" }}>
                            {notes}
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        packageDetail: state.packageDetail,
        workout: state.surveyWorkoutDetails
    };
}
export default connect(mapStateToProps, {getSurveyWorkoutDetails} )(WorkoutDetails);
