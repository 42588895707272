import  React, { Component } from 'react';
import { Link, IndexLink, hashHistory } from 'react-router';
import { connect } from 'react-redux';
class SidebarMenu extends Component {

    render() {
        var menu = null;
        var url = this.props.pathname;
        url = url.indexOf('client-details') > -1 ? '/client-profile?order=desc&col=Joined On' : url;
        url = url.indexOf('dietitian-details') > -1 ? '/nutritionists' : url;

        if (this.props.user.type == 'DIETICIAN') {
            menu = (
                <ul className="w3-ul sideBar">
                    <Link to="/client-profile?order=desc&col=Joined On">
                        <li className={url==='/client-profile' ? 'active' : ''}>Client Profiles</li>
                    </Link>
                    <Link to="/packages?order=default&col=Status">
                        <li className={url==='/packages' ? 'active' : ''}>Packages</li>
                    </Link>
                    <Link to="/nutrition-plans?order=desc&col=Created At">
                        <li className={url==='/nutrition-plans' ? 'active':''}>Nutrition Plans</li>
                    </Link>
                    <Link to="/manage-data">
                        <li className={url==='/manage-data' ? 'active' : ''}>Manage Data</li>
                    </Link>
                    <Link to="/settings">
                        <li className={url==='/settings' ? 'active' : ''}>Settings</li>
                    </Link>
                </ul>
            );
        } else {
            menu = (
                <ul className="w3-ul sideBar">
                    <Link to="/client-profile?order=desc&col=Joined On">
                        <li className={url==='/client-profile' ? 'active' : ''}>Client Profiles</li>
                    </Link>
                    <Link to="/packages?order=default&col=Status">
                        <li className={url==='/packages' ? 'active' : ''}>Packages</li>
                    </Link>
                    <Link to="/nutritionists">
                        <li className={url==='/nutritionists' ? 'active' : ''}>Nutritionists</li>
                    </Link>
                    <Link to="/nutrition-plans?order=desc&col=Created At">
                        <li className={url==='/nutrition-plans' ? 'active':''}>Nutrition Plans</li>
                    </Link>
                    <Link to="/manage-data">
                        <li className={url==='/manage-data' ? 'active' : ''}>Manage Data</li>
                    </Link>
                    <Link to="/settings">
                        <li className={url==='/settings' ? 'active' : ''}>Settings</li>
                    </Link>
                </ul>
            );
        }
        return (
            <div id="sidebar-menu">
                {menu}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.currentUser
    }
}
export default connect(mapStateToProps)(SidebarMenu);
