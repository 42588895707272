import  React, { Component } from 'react';
import { Link } from 'react-router';
import $ from 'jquery'; 

export default class ForgotPassword extends Component {
    constructor(args) {
        super(args);
        this.state  = {
            errorMessage: null,//    'There is a problem',
            successMessage: null// 'Awesome you have done it'
        };

    }

    handleForgot(e) {
        e.preventDefault();
        var self = this;
        self.setState({errorMessage: null});
        $.ajax({
            url: '/api/v1/auth/forgot-password',
            method: 'POST',
            data: {
                email: $("#email").val()
            }
        }).done(function (resp) {
            if (resp.success == false) {
                self.setState({
                    errorMessage: resp.message,
                    successMessage: null
                });
            }else {
                self.setState({
                    successMessage: resp.message,
                    errorMessage: null
                });
            }
        })
    }

    render() {
        var content = [];
        if (this.state.errorMessage) {
            content.push(<div className="callout w3-small warning">
              <p>{this.state.errorMessage}</p>
            </div>)

        }

        if (this.state.successMessage) {
            content.push(<div className="w3-margin w3-padding success-message ">
              <p>{this.state.successMessage}</p>
            </div>)
        } else {
            content.push(
                <label>Email
                    <input className="w3-input w3-border w3-margin-bottom" id="email" name="email" type="text" placeholder="somebody@example.com" />
                </label>);

            content.push(<p>
                <button  type="submit" className="w3-button w3-round w3-block nutrify-btn">Send Me Password Reset Email
                </button></p>)

        }

        return (
            <div className="w3-container">
                <div className="w3-row">
                    <div className="m6 l4 w3-quarter login-block"> 
                        <form style={{padding: "50px 0px"}} onSubmit={this.handleForgot.bind(this)}>
                            <div className="w3-border w3-padding"> 
                            {content}
                                <p className="w3-row"><Link className="no-decoration w3-right" to="/login">Login</Link></p>   
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
