import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import SurveyQuestions from './SurveyQuestions.js';
import SurveyResponse from './SurveyResponse.js';

export default class SurveyPanel extends Component {
    constructor(props){
        super(props);
    }
   
    handleTabSwitch(tab) {
        hashHistory.push('/survey/' + this.props.params.token   +"/" + tab);
    }
    render() {
        var content = null;
        var clsView = 'hide',clsEdit = 'hide';
        var clsButtonEdit = '',clsButtonView = ''; 

        if (this.props.params.tab == 'view') {
            clsButtonView = 'w3-teal';
            content = (
                <div className={"w3-display-container w3-col m12 " + clsEdit}>
                    <SurveyResponse token={this.props.params.token} />
                </div>
            );
        } else {
            clsButtonEdit = 'w3-teal';
            content = (
                <div className="w3-display-container w3-col m12">
                    <SurveyQuestions token={this.props.params.token} />
                </div>
            );
        }
        return (
            <div className="w3-content" style={{maxWidth:"1150px"}}> 
                <div className="w3-bar w3-light-grey" style={{marginTop: "20px"}}> 
                    <button
                        onClick={this.handleTabSwitch.bind(this, 'edit')}
                        className={"w3-bar-item w3-button " + clsButtonEdit}
                    >
                        Edit
                    </button>
                    <button
                        onClick={this.handleTabSwitch.bind(this, 'view')}
                        className={"w3-bar-item w3-button " + clsButtonView}
                    >
                        View
                    </button>
                </div>
                {content}
            </div> 
        );
    }
}

