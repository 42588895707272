import React, { Component } from 'react';

import Header from "../components/Common/Header.js";
import Footer from "../components/Common/Footer.js";
import SidebarMenu from "../components/Common/SidebarMenu.js";

export  class NoHeaderApplication extends Component {

    render() {

        return (
            <div>
                {this.props.children}
            </div>
        );
    }

}

export  class EmptyApplication extends Component {

    componentDidMount() {
    }
    render() {
        var appContent = (
            <div>
                <Header />
                {this.props.children}
            </div>
        );

        return (
            <div>
                {appContent}
            </div>
        );
    }

}

export default class Application extends Component {
    render() {
        var appContent = (
            <div>
                <Header />
                <div className="w3-row expanded">
                    <div className="s12 m3 l2 w3-col hide-for-small-only no-padding">
                        <div className="sidebar-wrapper">
                            <div data-sticky data-anchor="right-container" data-check-every='0'>
                                <SidebarMenu pathname={this.props.location.pathname}/>
                            </div>
                        </div>
                    </div>

                    <div id="right-container" className="w3-col m9 l10 s12 w3-padding">
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </div>
        );

        return (
            <div>
                {appContent}
            </div>
        );
    }

}

