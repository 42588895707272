import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDietPlanDayList, getDietPlanDayDetails, fireRequest} from "./../../js/actions.js";
import MealTableRow from './MealTableRow';
import { DNDWrapper } from "../utility/utility.js";
import NutrifyReveal from '../Common/NutrifyReveal';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

var ReactDOM = require('react-dom');

class TableBodyDropContextEx extends Component{
    render(){
        return (
            <tbody>
                {this.props.rows}
            </tbody>
        );
    }
}
const TableBodyDropContext =   DNDWrapper(TableBodyDropContextEx);

class AddMealModal extends Component {
    constructor(args) {
        super(args);
        this.state = {
            successMessage: '',
            errors: '',
            needFocus: true,
            showDisabledButton: false,
            optionalItem: null,
        }
        this.moveCard = this.moveCard.bind(this);
    }

    moveCard(dragIdx,hoverIdx){
        if (dragIdx === undefined){
            return;
        }
        const state = gNutrifyStore.getState();
        const meal = Object.assign({}, state.currentMeal); 
        const item = meal.items[dragIdx];
        meal.items.splice(dragIdx,1);
        meal.items.splice(hoverIdx,0,item);

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
    }


    componentDidMount() {
        if (!this.props.timingList) {
            this.props.fireRequest('timingList', [],
                {
                    category: 'Timings',
                    parent: this.props.category
                }
            );
        }
        if (this.props.currentMeal){
            var self = this;
            // setTimeout(function() {
            //     ReactDOM.findDOMNode(self.refs.saveMeal).focus();
            // }, 300);
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.currentMeal != newProps.currentMeal) {
            this.setState({
                errors: '',
                successMessage: ''
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.category != prevProps.category) {
            this.props.fireRequest('timingList', [],
                {
                    category: 'Timings',
                    parent: this.props.category
                }
            );
        }
        if (this.state.needFocus && this.props.currentMeal.openCls != '') {
            this.state.needFocus = false;
            ReactDOM.findDOMNode(this.refs.saveMeal).focus();
        }
    }

    handleSubmit(evt) {
        var self = this;
        evt.preventDefault();
        var state = gNutrifyStore.getState();
        var meal = $.extend(true, {}, state.currentMeal,{temporary:true});
        var work = $.extend(true, {}, state.currentWorkspace);
        if (!work.mealMap) {
            work.mealMap = {}
        }
        if (work.mealMap[meal.mealKey]) {
            delete work.mealMap[meal.mealKey];
        }
        var newKey = 'day_' + meal.day + '_time_' + meal.strTime;
        var goAhead = true;
        const targetMeal = work.mealMap[newKey];
        if (targetMeal  && targetMeal.meal_id !=   meal.meal_id ) {
            goAhead = window.confirm("A meal is already created with same day and time. If you press OK, the previous meal will be overwritten by this. Would you like to proceed?");
        }
        if (goAhead == false) {
            return;
        }

        this.setState({
            showDisabledButton: true
        });
        
        work.mealMap[newKey] = meal;
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentWorkspace',
            value: work
        });
        meal.time = meal.hh + ":" + meal.mm;
        meal.strTime = meal.hh + ":" + meal.mm;

        var day = meal.day;
        var dataToSendToServer = {
            package_id: this.props.packageId,
            diet_plan_id: this.props.planId,
            day_id: "",
            day_description: "Option " + (day+1),
            meal_description: "day_" + day + "_" + meal.hypothetical_time,
            day: day,
            time: meal.time,
            meal_id: meal.meal_id,
            strTime: meal.strTime,
            hypothetical_time: meal.hypothetical_time,
            items: []
        };

       
        if (meal.items) {
            meal.items.forEach(function (item, idx) {
                dataToSendToServer.items.push({
                    id: item.value.id,
                    qty: item.quantity,
                    ref_id: item.ref_id
                });
            });
        

            $.ajax({
                type: "POST",
                url: '/api/v1/diet/planday/create-day',
                data: dataToSendToServer,
                success: function (response) {
                    if (response.success == true) {
                        meal = $.extend(true, {}, state.currentMeal,{meal_id:response.data.meal_id});
                        work.mealMap[newKey] = meal;
                        gNutrifyStore.dispatch({
                            type: 'SET_DATA',
                            key: 'currentWorkspace',
                            value: Object.assign({},work)
                        });

                        self.setState({
                            successMessage: "Diet Plan Day added successfully!",
                            errors: '',
                            showDisabledButton: false
                        });
                        if (response.data.total_meal_count != Object.keys(work.mealMap).length  ){
                            self.props.getDietPlanDayDetails(self.props.planId);
                        }
                        self.closeDialog();

                    } else if (response.success == false) {
                        self.setState({
                            errors: response.data,
                            successMessage: '',
                            showDisabledButton: false
                        })
                        self.props.getDietPlanDayDetails(this.props.planId);
                    }
                }.bind(this),
                error: function (error) {
                    // self.closeDialog();
                }.bind(this)
            });
        } else {
            this.setState({
                errors: 'No Items in Meal. Please Add a Meal to Continue.'
            });
        }
    }

    closeDialog() {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: {
                openCls: '',
                items: [],
                day: '1',
                day_id: "",
                meal_id: ""
            }
        });  
        this.state.needFocus = true;
    }

    handleChangeSpec(e) {
        var state = gNutrifyStore.getState();
        var meal = Object.assign({}, state.currentMeal);
        meal.hypothetical_time = e.target.value;
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
    }

    handleChangeHour(e) {
        var self =this;
        var state = gNutrifyStore.getState();
        var meal = Object.assign({}, state.currentMeal);
        meal.hh = e.target.value;
        meal.time = meal.hh + ":" + meal.mm;
        meal.strTime = meal.hh + ":" + meal.mm;
       
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
        setTimeout(function(){ 
            ReactDOM.findDOMNode(self.refs.saveMeal).focus();
        }, 100);
        
    }

    handleChangeMin(e) {
        var self =this;
        var state = gNutrifyStore.getState();
        var meal = Object.assign({}, state.currentMeal);
        meal.mm = e.target.value;
        meal.time = meal.hh + ":" + meal.mm;
        meal.strTime = meal.hh + ":" + meal.mm;
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
        setTimeout(function(){ 
            ReactDOM.findDOMNode(self.refs.saveMeal).focus();
        }, 100);
    }

    render() {
        const {
            optionalItem,
            showDisabledButton,
            successMessage,
            errors
        } = this.state;
        const {
            timingList,
            currentMeal,
            packageId
        } = this.props;

        let optionTime = [];
        if (timingList) {
            for (var idx = 0; idx < timingList.data.length; idx++) {
                optionTime.push(
                    <option key={idx}>{timingList.data[idx]}</option>
                );
            }
        }

        let totalCalories = 0;
        let totalCarb = 0;
        let totalProt = 0;
        let totalFat = 0;
        let itmndx = 0;
        let rows = [];
        if (!currentMeal || currentMeal.items == null) {
            rows = [
                <MealTableRow
                    packageId={packageId}
                    meal_item_id={0}
                    moveCard={this.moveCard}
                    key="item_new"
                    position={0}
                    index={0}
                />
            ];
        } else {
            currentMeal.items.forEach((item,itmdx) => {
                const itemCal = item.value.qty_calories * item.quantity;
                const itemCarb = item.value.qty_carbohydrate * item.quantity;
                const itemProt = item.value.qty_protein * item.quantity;
                const itemFat = item.value.qty_fat * item.quantity;
                if(!item.ref_id){
                    totalCalories += itemCal;
                    totalCarb += itemCarb;
                    totalProt += itemProt;
                    totalFat += itemFat;
                }
                itmndx++;
                rows.push(
                    <MealTableRow
                        allItems={currentMeal.items}
                        packageId={packageId}
                        moveCard={this.moveCard}
                        meal_item_id={item.meal_item_id}
                        position={itmdx}
                        key={`item_${item.food_item_id}_${itmdx}`}
                        item={item}
                        index={itmdx}
                    />
                );
            });
            rows.push(
                <MealTableRow
                    packageId={packageId}
                    moveCard={this.moveCard}
                    meal_item_id={0}
                    key={"item_new" + itmndx}
                    position={itmndx}
                    index={0}
                />
            );
        }

        var hours = [];
        for (var idx = 4; idx <= 23; idx++) {
            var hh = "" + idx;
            if (hh.length < 2) {
                hh = "0" + hh;
            }
            hours.push(
                <option value={hh} key={idx}>{hh}</option>
            );
        }
        for (var idx = 0; idx <= 3; idx++) {
            hh = "0"  + idx;
            hours.push(
                <option value={hh} key={idx}>{hh}</option>
            );
        }

        const defaultTime = currentMeal.strTime;
        const showButton = showDisabledButton;

        let showMessage = 'w3-hide';
        if (successMessage != '') {
            showMessage = 'w3-show';
        }
        let showError = 'w3-hide';
        if (errors != '') {
            showError = 'w3-show';
        }

        return (
            <div className="w3-row">
                <div className="m12 w3-col reveal-heading w3-center w3-padding">Add Meal</div>
                <div className="m12 w3-col reveal-body">
                    <div className={"w3-margin w3-padding success-message " + showMessage}>
                        {this.state.successMessage}
                    </div>
                    <div className={"w3-margin w3-padding error-message " + showError}>
                        {this.state.errors}
                    </div>
                    <form id='add-meal-form' onSubmit={this.handleSubmit.bind(this)}>
                        <div className="w3-row w3-row-padding">
                            <div className="w3-col m4">
                                <label>hours in 24hr format</label>
                                <select key={"hr_" + defaultTime} defaultValue={this.props.currentMeal.hh} 
                                    onChange={this.handleChangeHour.bind(this)}
                                    className="w3-select w3-border">
                                    {hours}
                                </select>
                            </div>
                            <div className="w3-col m4">
                                <label>minute</label>
                                <select
                                    key={"sec_" + defaultTime}
                                    defaultValue={this.props.currentMeal.mm} 
                                    onChange={this.handleChangeMin.bind(this)} 
                                    className="w3-select w3-border"
                                >
                                    <option value='00' >00</option>
                                    <option value='15' >15</option>
                                    <option value='30' >30</option>
                                    <option value='45' >45</option>
                                </select>
                            </div>
                            <div className="w3-col m4">
                                <label>Meal Timings</label>
                                <select
                                    key={"time_" + defaultTime}
                                    defaultValue={this.props.currentMeal.hypothetical_time} 
                                    onChange={this.handleChangeSpec.bind(this)}
                                    className="w3-select w3-border"
                                > 
                                    <option value="Custom">-Select-</option>
                                    {optionTime}
                                </select>
                            </div>
                        </div>
                        <div className="w3-padding">
                            <table className="w3-table-all table w3-small">
                                <thead>
                                    <tr>
                                        <th>Food Item</th>
                                        <th>Quantity</th>
                                        <th>Consume</th>
                                        <th>Calories</th>
                                        <th>CHO</th>
                                        <th>Prot</th>
                                        <th>Fat</th>
                                        <th>Fibre</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <TableBodyDropContext rows={rows}/>
                            </table>
                        </div>
                        <div className="w3-row w3-padding">
                            <div className="w3-left n5y-btn-list">
                                <button id="add-meal-cancel" className="w3-button w3-small-padding w3-teal w3-hover-teal"
                                    type="button"
                                    onClick={this.closeDialog.bind(this)}
                                    value="Cancel">Cancel</button>
                                <button id="add-meal-save"
                                 className="w3-button w3-small-padding nutrify-btn"
                                  value="Submit" ref="saveMeal" disabled={showButton}>Save</button>
                            </div>
                            <div className="w3-right w3-right-align">
                                Total Calories : {(totalCalories) ? totalCalories.toFixed(2) : 0} kcal
                                <br></br>Total Carbohydrates : {(totalCarb) ? totalCarb.toFixed(2) : 0} g
                                <br></br>Total Protein : {(totalProt) ? totalProt.toFixed(2) : 0} g
                                <br></br>Total Fat : {(totalFat) ? totalFat.toFixed(2) : 0} g
                            </div>     
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentMeal: state.currentMeal,
        timingList: state.timingList
    }
}

export default connect(mapStateToProps, { 
    getDietPlanDayDetails, 
    fireRequest})(AddMealModal)

