import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import getClientProfileList from '../../js/actions.js'
import { FormTextField, FormTextarea, FormSelectField } from "../Common/HelperComponents.js";
import $ from 'jquery';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';


export default class ClientAutocompletList extends Component {

    constructor(args) {
        super(args);
        this.state = {
            client: null
        }
        this.getOptions = this.getOptions.bind(this);

    }



    getOptions(input, callback) {
        $.ajax({
            url: '/api/v1/client/package/client-list?q=' + input + '&auto=true&selected=' + this.state.clientId
        }).done(function (resp) {
            var list = [];
            resp.data.forEach(function (item) {
                list.push({
                    value: item.id,
                    label: item.first_name + ' ' + item.last_name
                });
            })
            callback(list);
        })

    }

    handleChange(item, prev) { 
        this.setState({
            client: item
        }, function () {
            if (this.props.handleClientChange) {
                this.props.handleClientChange(item ? item.value : null)
            }
        }.bind(this))
    }

    render() {
        return (
            <div className="w3-row">
                <div className="m12 w3-col">
                    <AsyncSelect
                        key={"client_auto"}
                        name="form-field-name"
                        className="select w3-round"
                        value={this.state.client}
                        onChange={this.handleChange.bind(this)}
                        loadOptions={this.getOptions}
                    />
                </div>
            </div>
        )
    }
}

