import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {FormTextField} from "../Common/HelperComponents.js";
import $ from 'jquery'; 

export default class SettingsPassword extends Component {

    constructor(args) {
        super(args);

        this.state = {
            formData: {},
            errors: {},
            successMessage: '',
        };
    }   

    handleSubmit(evt) {
        evt.preventDefault();
        if (this.state.formData) {
            $.ajax({
                type: "POST",
                url: '/api/v1/user/edit',
                data: this.state.formData,
                success: function (response) {

                    if (response.success == true) {
                        this.setState({
                            successMessage: "Password reset successfully!",
                            formData: {}
                        });
                        alert("Password reset successfully!!!");
                        document.location.reload();

                    } else if (response.success == false) {
                        this.setState({
                            errors: response.data
                        });
                    }
                }.bind(this)
            });
        }
    }

    handleChange(evt) {

        var targetElement = $(evt.target).attr('id');
        if (this.state.errors[targetElement]) {
            this.state.errors[targetElement] = null;
            this.setState({
                errors: this.state.errors
            })
        }

        this.state.formData[targetElement] = $(evt.target).val();
        this.setState({
            formData: this.state.formData
        });
    }

    render() {
        return (            
            <div className="w3-light-grey w3-padding w3-margin-bottom">
                <h5 className="section-header">Change Password</h5>
                <form name="password_reset_form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
                        <FormTextField
                            label="Current Password"
                            id="current_password"
                            name="current_password"
                            errors={this.state.errors.current_password}
                            onChange={this.handleChange.bind(this)}
                            type="password" 
                            inputClass={"w3-input w3-border w3-margin-bottom"}/>
                        
                        <FormTextField
                            label="New Password"
                            id="new_password"
                            name="new_password"
                            errors={this.state.errors.new_password}
                            onChange={this.handleChange.bind(this)}
                            type="password" 
                            inputClass={"w3-input w3-border w3-margin-bottom"}/>

                        <FormTextField
                            label="Confirm Password"
                            id="confirm_password"
                            name="confirm_password"
                            errors={this.state.errors.confirm_password}
                            onChange={this.handleChange.bind(this)}
                            type="password" 
                            inputClass={"w3-input w3-border w3-margin-bottom"}/>
        
                    <button type="submit" className="w3-button nutrify-btn w3-round">Change Password </button>
                </form> 
            </div>
        )
    }
}
