import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getClientProfileList,
    getPackageList,
    getPaginatedList,
    fireRequest,
} from "../../js/actions.js";
import {
    FormTextField,
    FormTextarea,
    FormSelectField
} from "../Common/HelperComponents.js";
import ClientAutocompletList from './ClientAutocompletList.js';
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

class PackageCreateEditModal extends Component {
    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            subcat: null,
            successMessage: '',
            formData: {},
            showDisabledButton: false
        };
    }

    componentDidMount() {
        if (this.props.clientProfiles == null) {
            this.props.getClientProfileList();
        }
        if (this.props.dietitianListAuto == null) {
            this.props.fireRequest('dietitianListAuto');
        }
        if (!this.props.packageStatusList) {
            this.props.fireRequest('packageStatusList');
        }
        if (!this.props.categoryList) {
            this.props.fireRequest('categoryList');
        }
        if (!this.props.durationList) {
            this.props.fireRequest('durationList');
        }
        if (!this.props.packageEditData) {
            this.setState({
                errors: {},
                successMessage: ''
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.packageEditData.category !=  prevProps.packageEditData.category) {
            this.props.fireRequest('subCategoryList', [], {
                category: 'Categories',
                parent: this.props.packageEditData.category
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.packageEditData != this.props.packageEditData) {
            this.state.successMessage = '';
            this.state.errors = {};
        }
    }

    handleSubmit(evt) {
        evt.preventDefault();
        var self = this;
        self.setState({
            showDisabledButton: true
        });
        var formData = self.props.packageEditData;
        if (formData && formData.id) {
            $.ajax({
                type: "POST",
                url: '/api/v1/client/package/edit',
                data: formData,
                success: ((response) => {
                    if (response.success == true) {
                        self.setState({
                            successMessage: "Package edited successfully!"
                        });
                        if (self.props.updateClientDetails) {
                            self.props.updateClientDetails();
                        }
                        self.props.getPaginatedList('packageStatus', self.props.query);
                        setTimeout(function () {
                            self.setState({
                                showDisabledButton: false
                            });
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        self.setState({
                            errors: response.data,
                            showDisabledButton: false
                        })
                    }

                }).bind(this)
            });
        } else {
            $.ajax({
                type: "POST",
                url: '/api/v1/client/package/create',
                data: formData,
                //data: $(evt.target).serialize(),
                success: ((response)=> {
                    if (response.success == true) {
                        self.setState({
                            successMessage: "Package added successfully!"
                        });
                        self.props.getPaginatedList('packageStatus', self.props.query);
                        setTimeout(function () {
                            self.setState({
                                showDisabledButton: false
                            });
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        self.setState({
                            errors: response.data,
                            showDisabledButton: false
                        });
                    }
                }).bind(this)
            });
        }
        return false;
    }

    handleClose() {
        $('.close-button').click();
    }

    handleChange(evt) {
        var targetElement = $(evt.target).attr('id');
        if (this.state.errors[targetElement]) {
            this.state.errors[targetElement] = null;
            this.setState({
                errors: this.state.errors
            })
        }
        var packageData = Object.assign({}, this.props.packageEditData);
        packageData[targetElement] =  $(evt.target).val();

        if (targetElement == 'category'){
            packageData['sub_category'] =  "";
        }

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: packageData
        });
    }

    handleSelect(opt) {
        this.handleChange(opt);
    }

    handleClientChange(clientId) {
        var packageData = Object.assign({}, this.props.packageEditData);
        packageData.client_id =  clientId;
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: packageData
        });
    }

    render() {
        var options = [];
        var subOption = [];
        var durationOptions = [];
        var categoryList = this.props.categoryList;
        var subCategoryList = this.props.subCategoryList;
        var packageStatusList = this.props.packageStatusList;
        var durationList = this.props.durationList;

        if (categoryList) {
            for (var idx = 0; idx < categoryList.data.length; idx++) {
                options.push(<option key={idx}>{categoryList.data[idx]}</option>)
            }
        }

        if (subCategoryList) {
            for (var i = 0; i < subCategoryList.data.length; i++) {
                subOption.push(<option key={i}>{subCategoryList.data[i]}</option>)
            }
        }

        if(durationList){
            for (var idx = 0; idx < durationList.data.length; idx++) {
                durationOptions.push(<option key={idx}>{durationList.data[idx]}</option>)
            }
        }

        // constructorole.log("props", this.props.packageEditData);
        var formData = this.props.packageEditData;
        var formHeading = null;
        var btnText = null;
        if (this.props.packageEditData.id) {
            formHeading = "Edit Package";
            btnText = 'Update';
        } else {
            formHeading = "Add Package";
            btnText = 'Add';
        }

        var showMessage = 'w3-hide';
        if (this.state.successMessage != '') {
            showMessage = 'w3-show';
        }

        var showButton = this.state.showDisabledButton;
        var dietitian = [];
        dietitian.push({val: '', name: '-Select Nutritionist-'});
        if (this.props.dietitianListAuto) {
            this.props.dietitianListAuto.data.forEach(function (item, idx) {
                if (item.status.toUpperCase() != 'ACTIVE') {
                    return;
                }
                dietitian.push(
                        {
                        val: item.id,
                        name: item.first_name + ' ' + item.last_name
                    }
                )
            })
        }
        var clientList = [];
        if (this.props.clientProfiles) {
            this.props.clientProfiles.data.data.forEach(function (item, idx) {
                clientList.push(
                    {'val': item.id, 'name': item.first_name + ' ' + item.last_name}
                )
            });
        }

        var statusOptions = [];
        statusOptions.push({val: '', name: '-Select Status-'});
        if (packageStatusList) {
            for (var idx = 0; idx < packageStatusList.data.length; idx++) {
                statusOptions.push({val: packageStatusList.data[idx], name: packageStatusList.data[idx]});
            }
            const newStatusOptions = statusOptions.map((item) => {
                if(item.name == 'Send Survey'){
                    item.name = 'Send Assessment Form';
                } else if(item.name == 'Survey Response Pending'){
                    item.name = 'Assessment Response Pending';
                } else if( item.name == 'Survey Response Received'){
                    item.name = 'Assessment Response Received';
                }
                return item;
            });
            statusOptions = newStatusOptions;
        }

        var payment_mode = [
            {'val': '', 'name': '-Select Payment Mode-'},
            {'val': 'Cash', 'name': 'Cash'},
            {'val': 'Card', 'name': 'Card'},
            {'val': 'NEFT', 'name': 'NEFT'},
            {'val': 'Cheque', 'name': 'Cheque'},
            {'val': 'Wallet', 'name': 'Wallet'},
            {'val': 'UPI', 'name': 'UPI'}
        ];
 
        var client_type_options = [
            {'val':'','name':'-Select Client Type-'},
            {'val':'Corporate','name':'Corporate'},
            {'val':'Clinic','name':'Clinic'},
            {'val':'Club','name':'Club'},
            {'val':'Defense','name':'Defense'},
            {'val':'Hospital','name':'Hospital'},
            {'val':'Institute','name':'Institute'},
            {'val':'Sports Academy','name':'Sports Academy'},
            {'val':'Team','name':'Team'}
        ]

        var onBoardingMessage = null;
        if (formData && formData.status) {
            if (formData.status == 'Send Survey') {
                onBoardingMessage = "This will send an Assessment Form emailer to the client";
            }
        }

        return (
            <div className="w3-row">
                <div className="w3-margin w3-center reveal-heading">
                    {formHeading}
                </div>
                <div className="">
                    <div className={"w3-margin w3-padding success-message " + showMessage}>
                        {this.state.successMessage}
                    </div>
                    <form method="POST" onSubmit={this.handleSubmit.bind(this)}>
                      <div className="w3-row w3-row-padding w3-margin-bottom">
                        <div className="m6 s12 w3-col package-clientid">
                            <label>Select Client</label>
                            <ClientAutocompletList handleClientChange={this.handleClientChange.bind(this)}
                             />
                            <label className="error-red">{this.state.errors.client_id}</label>
                        </div>
                        <div className="m6 s12 w3-col">
                            <FormSelectField
                                label="Select Nutritionist"
                                id="dietitian_id"
                                name="dietitian_id"
                                value={(formData.dietitian_id) ? formData.dietitian_id : ''}
                                onChange={this.handleChange.bind(this)}
                                options={dietitian}
                                selectClass={'w3-select w3-border w3-round'} />
                                <label className="error-red">{this.state.errors.dietitian_id}</label>
                        </div>  
                      </div>                 
                      <div className="w3-row w3-row-padding w3-margin-bottom">
                        <div className="m6 s12 w3-col">
                            <label>Select Category
                            <select onChange={this.handleSelect.bind(this)}
                                value={(formData.category) ? formData.category : ''}
                                id="category"
                                name="category"
                                className="w3-select w3-border w3-round">
                                <option value="">-Select Category-</option>
                                {options}
                            </select>
                            </label>
                            <label className="error-red">{this.state.errors.category}</label>
                        </div>
                        <div className="m6 s12 w3-col">
                            <label>Select Sub Category
                                <select onChange={this.handleChange.bind(this)}
                                    value={(formData.sub_category) ? formData.sub_category : ''}
                                    id="sub_category"
                                    name="sub_category"
                                    className="w3-select w3-border w3-round">
                                    <option value="">-Select Sub Category-</option>
                                    {subOption}
                                </select>
                            </label>
                            </div>
                        </div>
                        <div className="w3-row w3-row-padding w3-margin-bottom">
                            <div className="m6 s12 w3-col">
                                <FormSelectField
                                    label="Select Client type"
                                    id="client_type"
                                    name="client_type"
                                    value={(formData.client_type) ? formData.client_type : ''}
                                    errors={this.state.errors.client_type}
                                    onChange={this.handleChange.bind(this)}
                                    options={client_type_options}
                                    selectClass={'w3-select w3-border w3-round'}
                                />
                            </div>
                            <div className="m6 s12 w3-col">
                                <FormTextField
                                    placeholder="Organisation Name"
                                    label="Organisation Name"
                                    id="organisation_name"
                                    name="organisation_name"
                                    onChange={this.handleChange.bind(this)}
                                    value={(formData.organisation_name) ? formData.organisation_name : ''}
                                    errors={this.state.errors.organisation_name}
                                    type="text" 
                                    inputClass={"w3-input w3-border w3-round"}
                                />
                            </div>
                        </div>
                        <div className="w3-row w3-row-padding w3-margin-bottom"> 
                            <div className="m6 s9 w3-col">
                                <label className="w3-align-left">Start Date</label>
                                <FormTextField
                                    id="start_date"
                                    name="start_date"
                                    onChange={this.handleChange.bind(this)}
                                    value={(formData.start_date) ? formData.start_date : ''}
                                    errors={this.state.errors.start_date}
                                    type="date" 
                                    inputClass={"w3-input w3-border w3-round"}
                                />
                            </div> 
                            <div className="m6 s12 w3-col">
                                <label className="w3-align-left ">End Date</label>
                                    <FormTextField
                                        id="end_date"
                                        name="end_date"
                                        onChange={this.handleChange.bind(this)}
                                        value={(formData.end_date) ? formData.end_date : ''}
                                        errors={this.state.errors.end_date}
                                        type="date" 
                                        inputClass={"w3-input w3-border w3-round"}
                                    />
                            </div>
                      </div>
                      <div className="w3-row w3-row-padding w3-margin-bottom"> 
                        <div className="m6 s12 w3-col">
                            <FormTextField
                                id="price"
                                name="price"
                                placeholder="Amount"
                                label="Amount"
                                onChange={this.handleChange.bind(this)}
                                value={(formData.price) ? formData.price : ''}
                                errors={this.state.errors.price}
                                type="text" 
                                inputClass={"w3-input w3-border w3-round"}
                            />
                        </div>
                        <div className="m6 s12 w3-col">
                            <FormTextField
                                id="amount_paid"
                                placeholder="Paid"
                                label="Paid"
                                name="amount_paid"
                                onChange={this.handleChange.bind(this)}
                                value={(formData.amount_paid) ? formData.amount_paid : ''}
                                errors={this.state.errors.amount_paid}
                                type="text" 
                                inputClass={"w3-input w3-border w3-round"}
                            />
                        </div>    
                      </div>
                      <div className="w3-row w3-row-padding w3-margin-bottom">   
                        <div className="m6 s12 w3-col select-padding">
                            <FormSelectField
                                label="Payment Mode"
                                id="payment_mode"
                                name="payment_mode"
                                value={(formData.payment_mode) ? formData.payment_mode : ''}
                                errors={this.state.errors.payment_mode}
                                onChange={this.handleChange.bind(this)}
                                options={payment_mode} 
                                selectClass={"w3-select w3-border w3-round"}
                            />
                        </div>
                        <div className="m6 s12 w3-col">
                            <label className="w3-align-left">Payment Date</label>
                            <FormTextField
                                placeholder="payment date"
                                id="payment_date"
                                name="payment_date"
                                onChange={this.handleChange.bind(this)}
                                value={(formData.payment_date) ? formData.payment_date : ''}
                                errors={this.state.errors.payment_date}
                                type="date" 
                                inputClass={"w3-input w3-border w3-round"}
                            />
                        </div>  
                      </div>
                     <div className="w3-row w3-row-padding w3-margin-bottom">
                        <div className="m6 s12 w3-col">
                            <label>Select Duration
                                <select onChange={this.handleChange.bind(this)}
                                    value={(formData.duration) ? formData.duration : ''}
                                    id="duration"
                                    name="duration"
                                    className="w3-select w3-border w3-round">
                                    <option>-Select Duration-</option>
                                    {durationOptions}
                                </select>
                            </label>
                        </div>
                        <div className="m6 s9 w3-col select-padding">
                            <FormSelectField
                                label="Select Status"
                                id="status"
                                name="status"
                                value={(formData.status) ? formData.status : ''}
                                onChange={this.handleChange.bind(this)}
                                options={statusOptions} 
                                selectClass={"w3-select w3-border w3-round"}
                            />
                            <label className="error-red">{this.state.errors.status}</label>
                        </div>
                      </div>
                        <div className="m12" style={{textAlign: 'center',color: 'red'}}>
                            {onBoardingMessage}
                        </div>
                        <div className="w3-row w3-padding">
                            <FormTextarea
                                label="Notes"
                                id="notes"
                                name="notes"
                                onChange={this.handleChange.bind(this)}
                                value={(formData.notes) ? formData.notes : ''}
                                errors={this.state.errors.notes} 
                                style={{minHeight: "80px"}}
                                inputClass={"w3-input w3-border w3-round"}
                            />
                        </div>
                        <div className="w3-row w3-padding w3-right">
                            <button onClick={this.handleClose.bind(this)} type="button" className="w3-button w3-teal w3-hover-teal w3-padding-small">Cancel</button>
                            <button type="submit" className="w3-button w3-padding-small nutrify-btn btn-space" disabled={showButton}>{btnText}</button>
                        </div>
                    </form> 
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        packageEditData: state.packageEditData,
        clientProfiles: state.clientProfileList,
        dietitianListAuto: state.dietitianListAuto,
        packageDetail: state.packageList,
        categoryList: state.categoryList,
        durationList: state.durationList,
        subCategoryList: state.subCategoryList,
        packageStatusList: state.packageStatusList
    };
}
export default connect(mapStateToProps,{
    getClientProfileList,
    getPackageList,
    getPaginatedList,
    fireRequest,
})(PackageCreateEditModal);
