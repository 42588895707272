import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getDietitianProfile, getPaginatedList, fireRequest } from "../../js/actions.js";
import { FormTextField, FormSelectField } from "../Common/HelperComponents.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

class DieticianProfileReveal extends Component {

    constructor(args) {
        super(args);

        this.state = {
            dietitianId: null,
            formData: {},
            errors: {},
            successMessage: '',
            showDisabledButton: false
        };
    }

    componentWillReceiveProps(newProps) {

        if (newProps.dietitianId != null && this.state.dietitianId != newProps.dietitianId) {
            this.setState({
                dietitianId: newProps.dietitianId
            }, function () {
                this.props.getDietitianProfile(this.state.dietitianId);
            }.bind(this))
        } else {
            if (this.props.dietitianProfile) {
                this.setState({
                    formData: this.props.dietitianProfile.data.data[0],
                })

            }
        }



        if (newProps.dietitianProfile && newProps.dietitianId != null) {
            this.setState({
                formData: newProps.dietitianProfile.data.data[0],
                errors: {},
                successMessage: ''
            })
        } else {
            this.setState({
                formData: {},
                errors: {},
                successMessage: ''
            })
        }
    }
    componentDidMount() {
        if (!this.props.dietitianStatusList) {
            this.props.fireRequest('dietitianStatusList');
        }
    }

    handleSubmit(evt) {
        evt.preventDefault();
        this.setState({
            showDisabledButton: true
        });

        if (this.state.formData && this.state.formData.id) {
            $.ajax({
                type: "POST",
                url: '/api/v1/dietitian/profile/edit',
                data: this.state.formData,
                success: function (response) {
                    if (response.success == true) {
                        this.setState({
                            successMessage: "Nutritionist profile edited successfully!",
                            showDisabledButton: false
                        });
                        this.props.getPaginatedList('dietitianFilter', this.props.query);
                        setTimeout(function () {
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        this.setState({
                            errors: response.data,
                            showDisabledButton: false
                        })
                    }
                }.bind(this)
            });
        } else {
            $.ajax({
                type: "POST",
                url: '/api/v1/dietitian/profile/create',
                data: this.state.formData,
                success: function (response) {
                    if (response.success == true) {
                        this.setState({
                            successMessage: "Dietician added successfully!",
                            showDisabledButton: false
                        });
                        this.props.getPaginatedList('dietitianFilter', this.props.query);
                        setTimeout(function () {
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        this.setState({
                            errors: response.data,
                            showDisabledButton: false
                        })
                    }
                }.bind(this)
            });
        }
        return false;
    }

    handleClose() {
        $('.close-button').click();
    }

    handleChange(evt) {

        var targetElement = $(evt.target).attr('id');
        if (this.state.errors[targetElement]) {
            this.state.errors[targetElement] = null;
            this.setState({
                errors: this.state.errors
            })
        }

        this.state.formData[targetElement] = $(evt.target).val();
        this.setState({
            formData: this.state.formData
        })

    }

    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealFormOpen',
            value: name
        })
    }

    setRevealClassDe(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealDeacti',
            value: name
        })
    }

    render() {

        var formHeading = null;
        var btnText = null;

        if (this.props.dietitianId) {
            formHeading = "Edit Nutritionist";
            btnText = 'Update';
        } else {
            formHeading = "Add Nutritionist";
            btnText = 'Add';
        }

        var showMessage = 'w3-hide';
        if (this.state.successMessage != '') {
            showMessage = 'w3-show';
        }

        var showButton = this.state.showDisabledButton;
        var dietitianStatus = [];
        if (this.props.dietitianStatusList) {
            var dietitianStatusList = this.props.dietitianStatusList.data;
            for (var idx = 0; idx <= dietitianStatusList.length; idx++) {
                dietitianStatus.push(<option key={'status_'.idx}>{dietitianStatusList[idx]?.toUpperCase()}</option>)
            }
        }

        var bloodGroupList = [
            { val: '', name: '-Select Blood Group-', key: 0 },
            { val: 'A+ve', name: 'A +ve', key: '1' },
            { val: 'A-ve', name: 'A -ve', key: '2' },
            { val: 'AB+ve', name: 'AB +ve', key: '3' },
            { val: 'AB-ve', name: 'AB -ve', key: '4' },
            { val: 'B+ve', name: 'B +ve', key: '5' },
            { val: 'B-ve', name: 'B-ve', key: '6' },
            { val: 'O+ve', name: 'O +ve', key: '7' },
            { val: 'O-ve', name: 'O -ve', key: '8' }
        ];



        var emailEditField = null;
        if (this.state.formData.id == undefined) {
            emailEditField = <div className="m6 s6 w3-col">
                <FormTextField
                    id="email"
                    name="email"
                    label="Email"
                    onChange={this.handleChange.bind(this)}
                    value={(this.state.formData.email) ? this.state.formData.email : ''}
                    type="email"
                    inputClass={"w3-input w3-border w3-round"}
                    topClass={"w3-padding-small"} />
                <label className="red">{this.state.errors.email}</label>
            </div>
        } else {
            emailEditField = <div className="m6 s6 w3-col dietician-email">

                <label>Email
                                    <input
                        id="email"
                        name="email"
                        value={this.state.formData.email}
                        disabled="true"
                        type="email"
                        className="w3-input w3-border w3-round" />
                </label>
            </div>
        }

        const statusCheck = this.state.formData.status;

        return (
            <Fragment>
                <NutrifyReveal openCls={this.props.revealFormOpen} customWidth={"600px"}>
                    <div className="w3-row">
                        <div className="w3-margin w3-center reveal-heading">
                            <h3>{formHeading}</h3>
                        </div>
                        <div className="m12 w3-col">
                            <div className={"w3-margin w3-padding success-message " + showMessage}>
                                {this.state.successMessage}
                            </div>
                            <form name="dietitian_add_form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
                                <div className="">
                                    <div className="w3-row w3-row-padding w3-margin-bottom">
                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="first_name"
                                                name="first_name"
                                                label="First Name"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.first_name) ? this.state.formData.first_name : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.first_name}</label>
                                        </div>
                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="last_name"
                                                name="last_name"
                                                label="Last Name"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.last_name) ? this.state.formData.last_name : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.last_name}</label>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-row-padding w3-margin-bottom">
                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="description"
                                                name="description"
                                                label="Description"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.description) ? this.state.formData.description : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.description}</label>
                                        </div>

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="speciality"
                                                name="speciality"
                                                label="Speciality"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.speciality) ? this.state.formData.speciality : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.speciality}</label>
                                        </div>
                                    </div>
                                    <div className="row w3-row-padding w3-margin-bottom">

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="experience"
                                                name="experience"
                                                label="Experience"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.experience) ? this.state.formData.experience : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.experience}</label>
                                        </div>

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="certification"
                                                name="certification"
                                                label="Certification"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.certification) ? this.state.formData.certification : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.certification}</label>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-row-padding w3-margin-bottom">

                                        <div >
                                            {emailEditField}
                                        </div>

                                        <div className="m6 s9 w3-col">
                                            <FormTextField
                                                id="phone"
                                                name="phone"
                                                label="Phone"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.phone) ? this.state.formData.phone : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.phone}</label>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-row-padding w3-margin-bottom">

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="address"
                                                name="address"
                                                label="Address"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.address) ? this.state.formData.address : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.address}</label>
                                        </div>

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="dob"
                                                name="dob"
                                                label="Date of Birth"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.dob) ? this.state.formData.dob : ''}
                                                type="date"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.dob}</label>
                                        </div>
                                    </div>

                                    <div className="w3-row w3-row-padding w3-margin-bottom">

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="fathers_name"
                                                name="fathers_name"
                                                label="Father's Name"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.fathers_name) ? this.state.formData.fathers_name : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.fathers_name}</label>
                                        </div>

                                        <div className="m6 s6 w3-col select-padding">
                                            <FormSelectField
                                                id="blood_group"
                                                name="blood_group"
                                                label="Blood Group"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.blood_group) ? this.state.formData.blood_group : ''}
                                                options={bloodGroupList}
                                                selectClass={"w3-select w3-border w3-round"} />
                                            <label className="red">{this.state.errors.blood_group}</label>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-row-padding w3-margin-bottom">
                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="pan_card_number"
                                                name="pan_card_number"
                                                label="Pan Card Number"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.pan_card_number) ? this.state.formData.pan_card_number : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.pan_card_number}</label>
                                        </div>
                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="adhar_number"
                                                name="adhar_number"
                                                label="Aadhar Number"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.adhar_number) ? this.state.formData.adhar_number : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.adhar_number}</label>
                                        </div>
                                    </div>

                                    <div className="w3-row w3-row-padding w3-margin-bottom">

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="emergency_contact_person"
                                                name="emergency_contact_person"
                                                label="Emergency Contact Person Name"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.emergency_contact_person) ? this.state.formData.emergency_contact_person : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.emergency_contact_person}</label>
                                        </div>

                                        <div className="m6 s6 w3-col">
                                            <FormTextField
                                                id="emergency_contact_number"
                                                name="emergency_contact_number"
                                                label="Emergency Contact Number"
                                                onChange={this.handleChange.bind(this)}
                                                value={(this.state.formData.emergency_contact_number) ? this.state.formData.emergency_contact_number : ''}
                                                type="text"
                                                inputClass={"w3-input w3-border w3-round"} />
                                            <label className="red">{this.state.errors.emergency_contact_number}</label>
                                        </div>
                                    </div>

                                    <div className="w3-row w3-row-padding w3-margin-bottom">

                                        <div className="m6 s6 w3-col select-padding">
                                            <FormSelectField
                                                id="status"
                                                name="status"
                                                label="Nutritionist Status"
                                                value={(this.state.formData.status) ? this.state.formData.status : ''}
                                                onChange={this.handleChange.bind(this)}
                                                selectClass={"w3-select w3-border w3-round"}>
                                                {dietitianStatus}
                                            </FormSelectField>
                                            <label className="red">{this.state.errors.status}</label>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-padding w3-right">
                                        <button onClick={this.setRevealClass.bind(this, '')} type="button" className="w3-button w3-padding-small w3-teal">Cancel</button>
                                        <button type="submit" className="w3-button w3-padding-small nutrify-btn btn-space" disabled={showButton}>{btnText}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <button className="close-button w3-btn no-shadow w3-display-topright" onClick={this.setRevealClass.bind(this, '')} aria-label="Close modal" type="button">
                        <span aria-hidden="true" className="w3-xlarge">&times;</span>
                    </button>
                </NutrifyReveal>
            </Fragment>
        )

    }
}


function mapStateToProps(state) {
    return {
        dietitianProfile: state.dietitianProfile,
        dietitianStatusList: state.dietitianStatusList,
        revealFormOpen: state.revealFormOpen,
        revealDeacti: state.revealDeacti

    };
}
export default connect(mapStateToProps, {
    getDietitianProfile,
    getPaginatedList,
    fireRequest
})(DieticianProfileReveal);