import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getPackageDetails,
    getSurveyWorkoutDetails,
    getMealTimeDetails,
    getSurveyResponses
} from "../../js/actions.js";
import MealSection from './MealSection';
import SurveyWorkoutManager from './SurveyWorkoutManager';
import moment from 'moment';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

class Question extends Component {
    constructor(args) {
        super(args);
        this.state = {
            userId: null,
            packageId: null,
            formData: {},
            errors: {},
            successMessage: ''
        };
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    postToServer(answerId,value) {
        var self = this;
        $.ajax({
            method: 'POST',
            url: '/api/v1/survey/response/create',
            data: {
                survey_token: this.props.token,
                question_id: this.props.question.id,
                answer_id: answerId,
                text_response: value
            }
        }).done(function (resp) {
            if (resp.success) {
                gNutrifyStore.dispatch({
                    type: 'SET_DATA',
                    key: 'surveyProgress',
                    value: Math.floor(resp.data.count * 100 / (resp.data.total))
                });
                //self.forceUpdate();
            }
        });
    }

    selectChanged(evt) {
        this.postToServer(evt.target.value, evt.target.value);
    }

    handleMultiSelect(event) {
        var vals = [];
        var first = this.props.question.answers[0].id;
        this.props.question.answers.forEach(function (item) {
            if ($("#answer_" + item.id).is(':checked')) {
                first = item.id;
                vals.push(item.id);
            }
        });
        this.postToServer(first, JSON.stringify(vals)); 
    }

    handleYesNo(event) {
        this.postToServer(event.target.value, event.target.value);
    }

    handleTextChange(event) {
        this.postToServer(this.props.question.answers[0].id, event.target.value);
    }

    render() {
        //on the survey edit page
        var question = this.props.question;
        var questionItem = null;
        var self = this;
        var mandatoryField = false;
        var markRequired='';
        if (question.mandatory == 1){
            mandatoryField = true;
            markRequired='*';
        }
        var prevAnswer = null;
        if (this.props.responses && this.props.responses.data) {
            this.props.responses.data.forEach(function (item) {
                if (question.id == item.question_id) {
                    prevAnswer = item;
                }
            }) ;
        }
        var questionWidth = 'm12 s12';
        var qDivisionWidth = 'm6 s12';
        var answerWidth = ' m12 s12 ';

        switch (question.type.toLowerCase()) {
            case 'date':
            questionItem = (
                <div className={" w3-col" + answerWidth}>
                    <input
                        name={question.ref_id}
                        defaultValue={prevAnswer ?  prevAnswer.text_response : '' }
                        onBlur={(e)=> this.handleTextChange(e)}
                        type="date"
                        required={mandatoryField}
                        autoFocus={false}
                        className="w3-input w3-border"
                    />
                </div>
            ); break;

            case 'single_select':
            var list = [];           
            this.props.question.answers.forEach(function (item) {
                list.push(
                    <option
                        type="checkbox"
                        key={"key_" + item.id}
                        value={item.id}
                        name={question.ref_id}
                    >
                        {item.text_answer}
                    </option>
                );
            });
            questionItem = (
                <div className={"w3-col" + answerWidth} style={{padding:"0px"}}>
                    <select
                        defaultValue={prevAnswer ? prevAnswer.answer_id : null}
                        required={mandatoryField}
                        autoFocus={false}
                        onChange={this.selectChanged.bind(this)}
                        className="w3-select w3-border"
                    >
                        {list}
                    </select>
                </div>
            ); break;

            case 'multi_select':
            var list = [];
            var vals = [];
            try {
                if (prevAnswer && prevAnswer.text_response) {
                    vals = JSON.parse(prevAnswer.text_response);
                }
            } catch ($e) {

            };
           
            var hasSelection = false;
            this.props.question.answers.forEach(function (item) { 
                if ($("#answer_" + item.id).is(':checked')) {
                    hasSelection = true;
                }  
                if ($("#answer_" + item.id).length == 0 && vals.indexOf(item.id) != -1) {  
                    hasSelection = true;
                }
            });

            this.props.question.answers.forEach(function (item) {
                var checked = false;
                if (vals.indexOf(item.id) != -1) {
                    checked = true; 
                }
                var mand = false;
                if (!hasSelection){
                   mand = mandatoryField;
                }
                list.push(
                    <div key={"answer_div_" + item.id} className="w3-padding-small">
                        <input
                            key={"answer_" + item.id}
                            defaultChecked={checked}
                            type="checkbox"
                            id={"answer_" + item.id}
                            onChange={self.handleMultiSelect.bind(self)}
                            required={mand}
                            className="w3-check check-small"
                        />
                        <label
                            key={"answer_lbl" + item.id}
                            className="w3-padding-small"
                        >
                            {item.text_answer} 
                        </label>
                    </div>
                );
            });
            questionWidth = 'm12 s12';
            questionItem = (
                <div className="m12 s12 w3-col">
                    {list}
                </div>
            ); break;

            case 'yes_no':
            questionWidth = 'm7 s7'
            var list  = []
            this.props.question.answers.forEach(function (item) {
                var checked = false;
                if (prevAnswer && prevAnswer.answer_id == item.id) {
                    checked = true;
                }
                list.push(
                    <div className="m6 s12 w3-col " key={"answer_" + item.id} >
                        <label>
                            {item.text_answer + " "} 
                            <input defaultChecked={checked}
                                type="radio" name={"qn_" + question.id} value={item.id} id={"answer_" + item.id}
                                onChange={self.handleYesNo.bind(self)} required={mandatoryField}/> 
                        </label>
                    </div>
                );
            });
            questionItem = (
                <div className="m5 s5 w3-col">
                    <div className="w3-row">
                        {list}
                    </div>
                </div>
            ); break;

            case 'text_area':
            if (question.category.toLowerCase() == 'general'){
                answerWidth = 'm10 s12 survey-margin';
                qDivisionWidth = 'm10 s12';
            }

            questionItem = (
                <div className="w3-margin-bottom">
                    <textarea name={question.ref_id} 
                        defaultValue={prevAnswer ?  prevAnswer.text_response : '' }
                        onBlur={(e)=> this.handleTextChange(e)} 
                        type="text"
                        required={mandatoryField} 
                        className="w3-input w3-border"
                    />
                </div>
            ); break;

            default:
            questionItem = (
                <div>
                    <input
                        name={question.ref_id}
                        defaultValue={prevAnswer ?  prevAnswer.text_response : '' }
                        onBlur={(e)=> this.handleTextChange(e)} 
                        type="text" required={mandatoryField}
                        className="w3-input w3-border"
                    />
                </div>
            ); break;
        }

        return (
            <div className={"question-block w3-col " + qDivisionWidth} style={{minHeight:"76px"}}>
                <div>
                    <label className="survey-bold">{question.text+""+markRequired}</label>
                </div>
                {questionItem}
            </div>
        );
    }
}

class Accordion extends Component {
    toggleClass(id){
        this.props.onClick(id)
    }

    render() {
        var symptomCategory = this.props.category;
        var categoryItem = [];
        if (this.props.question.text == symptomCategory) {
            categoryItem.push(
                <div key={this.props.id} id={symptomCategory} className={`w3-border ${(this.props.toggleCls == symptomCategory) || (this.props.toggleCls == "open-all") ? 'w3-show':'w3-hide'}`}>
                    <div className="w3-row w3-row-padding w3-margin-top w3-margin-bottom">
                        <div className="m12 s12 w3-col">
                            <Question token={this.props.token} question={this.props.question} responses={this.props.responses} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <li  className="w3-block li-no-border">
                <div className={`symptoms-accordion ${(this.props.toggleCls == symptomCategory) || (this.props.toggleCls == "open-all")  ? 'active':''}`} onClick={this.toggleClass.bind(this,symptomCategory)}>
                    <h6 className={`symptom accordion-head ${(this.props.toggleCls == symptomCategory) || (this.props.toggleCls == "open-all")  ? 'active':''}`}>{`${symptomCategory}`} <span className="w3-small">{`${(this.props.mandatory == 1) ?' (Mandatory)':''}`}</span></h6>
                </div>
                <div>
                    {categoryItem}
                </div>
            </li>
        );
    }
}

class QuestionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            toggleCls: "",
            generalFitnessTable: false,
            generalTrainingTable: false,
            excerciseNotes: ''
        };
    }

    componentWillUpdate(nextProps){
        if (nextProps.openAll !="" && nextProps.openAll == "open-all" && this.props.openAll != "open-all"){
            this.setState({toggleCls:"open-all"});
        }
    } 

    handleToggle(id){
        this.setState({toggleCls:id});
    }

    enableEdit(index, evt){
        evt.preventDefault();
        var editRow = [];
        var workout = this.props.surveyWorkoutDetails;
        if (workout && workout.data && workout.data.length > 0) {
            editRow = workout.data[index];
        }
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'workoutEditData',
            value: editRow
        });
        this.showModal(editRow.category);
    }

    handleDeleteRow(index, evt) {
        evt.preventDefault();
        var deleteRow = [];
        const { questions, packageDetail, surveyWorkoutDetails } = this.props;
        if (surveyWorkoutDetails && surveyWorkoutDetails.data && surveyWorkoutDetails.data.length > 0) {
            deleteRow = surveyWorkoutDetails.data[index];
        } else {
            return;
        }
        $.ajax({
            type: 'POST',
            url: '/api/v1/survey/surveyworkout/delete',
            data: {
                id: deleteRow.id,
                client_id: packageDetail.data.client_id,
                package_id: packageDetail.data.id,
                question_id: questions[0].id,
            },
            success: ((resp) => {
                this.props.getSurveyWorkoutDetails(packageDetail.data.id)
                //this.updateEmptyField();
            }).bind(this)
        });
    }

    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealFormOpen',
            value: name
        });
    }

    updateEmptyField(type) {
        const {
            token,
            questions,
            packageDetail,
            surveyResponses
        } = this.props;
        if(!type){
            return
        }
        const status = surveyResponses.data.find(i => i.text_response === 'SPORT OR EXERCISE TRAINING ROUTINE');
         $.ajax({
            type: 'POST',
            url: '/api/v1/survey/response/empty-work-out-data',
            data: {
                client_id: packageDetail.data.client_id,
                question_id: questions[0].id,
                text_response: questions[0].category,
                package_id: packageDetail.data.id,
                // training: this.state.generalTrainingTable,
                // workout: this.state.generalFitnessTable 
                training: (type=="training") ? this.state.generalTrainingTable : (status ? status.training : null),
                workout: (type=="workout") ? this.state.generalFitnessTable : (status ? status.workout: null)
            },
            success: (() => {
                this.props.getSurveyWorkoutDetails(packageDetail.data.id);
                this.props.getSurveyResponses(token);
            }).bind(this)
        });
    }

    addWorkoutNotes(event) {
        const {
            token,
            questions,
            packageDetail
        } = this.props;
        this.setState({
            excerciseNotes: event.target.value
        }, () => {
            $.ajax({
                type: 'POST',
                url: '/api/v1/survey/response/add-work-out-notes',
                data: {
                    client_id: packageDetail.data.client_id,
                    question_id: questions[0].id,
                    text_response: questions[0].category,
                    package_id: packageDetail.data.id,
                    notes: this.state.excerciseNotes,
                },
                success: (() => {
                    this.props.getSurveyWorkoutDetails(packageDetail.data.id);
                    this.props.getSurveyResponses(token);
                }).bind(this)
            });
        });
    }

    handleToggleActivity(type, value) {
        // const {
        //     questions,
        //     surveyWorkoutDetails,
        //     packageDetail
        // } = this.props;
        if (type === 'training') {
            this.setState({
                generalTrainingTable: !value
            }, () => {
                this.updateEmptyField(type);
            });
        } else if (type === 'workout') {
            this.setState({
                generalFitnessTable: !value
            }, ()=> {
                this.updateEmptyField(type);
            });
        }
        // if (this.state.generalFitnessTable && surveyWorkoutDetails) {
        //     surveyWorkoutDetails.data.length > 0 &&
        //     surveyWorkoutDetails.data.filter(item => item.category == type).map((item) => {
        //         $.ajax({
        //             type: 'POST',
        //             url: '/api/v1/survey/surveyworkout/delete',
        //             data: {
        //                 id: item.id,
        //                 client_id: packageDetail.data.client_id,
        //                 dietitian_id: packageDetail.data.dietitian_id,
        //                 survey_token: token,
        //                 question_id: questions[0].id,
        //                 text_response: questions[0].category,
        //                 package_id: packageDetail.data.id,
        //                 json_data: ({
        //                     training: this.state.generalTrainingTable,
        //                     workout: this.state.generalFitnessTable
        //                 })
        //             },
        //             success: ((response) => {
        //                 this.props.getSurveyWorkoutDetails(packageDetail.data.id);
        //             }).bind(this)
        //         });
        //     });
        // }
    }
    
    showModal(name) {
        let packageCategory  = this.props.packageDetail.data.category;
        const self = this;
        if (packageCategory == 'Sports'){
            if (name == 'workout') {
                self.setState({
                    type: 'workout'
                }, function () {
                    self.setRevealClass('open');
                }.bind(this));
            } else if (name == 'training') {
                self.setState({
                    type: 'training'
                }, function () {
                    self.setRevealClass('open');
                }.bind(this));
            }
        } else {
            self.setState({
                type: 'workout'
            }, function () {
                self.setRevealClass('open');
            }.bind(this));
        }
    }

    render() {
        const {
            questions,
            surveyResponses,
            surveyWorkoutDetails,
            packageDetail
        } = this.props;
        const {
            type
        } = this.state;
        const packageId = packageDetail.data.id;
        const clientId = packageDetail.data.client_id;
        const packageCategory = packageDetail.data.category;
        var workout = surveyWorkoutDetails;
        let questionsList = [];
        const self = this;
        let tableData = null;
        questions.forEach((item, idx) => {        
            questionsList.push(
                <Question
                    token={self.props.token}
                    key={idx}
                    question={item}
                    responses={self.props.responses}
                />
            );
        });
        
        if (questions[0].category == 'SPORT OR EXERCISE TRAINING ROUTINE') {
            let workoutTable = [];
            let trainingTable = [];
            const daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            const status = surveyResponses.data.find(i => i.text_response === questions[0].category);
            const hideTrainingButton = ((status && status.training) ? JSON.parse(status.training) : false);
            const hideWorkoutButton = ((status && status.workout) ? JSON.parse(status.workout) : false);
            if (workout && workout.data && workout.data.length > 0) {
                workout.data.forEach((item, idx) => {
                    if (item.category == 'workout') {
                        var workoutDays = [];
                        for (var i = 0; i < daysList.length; i++) {
                            if (item[`${daysList[i].toLowerCase()}`] == '1') {
                                workoutDays.push(daysList[i]);
                            }
                        }
                        workoutTable.push(
                            <tr key={idx}>
                                <td>{item.routine_type}</td>
                                <td>{moment(item.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                                <td>{moment(item.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                <td className="desc-col">{workoutDays.toString()}
                                    <button className="w3-button survey-workout-edit"
                                        type="button" onClick={this.enableEdit.bind(this,idx)}>
                                    <i class="fa fa-pencil-square w3-large" aria-hidden="true"></i>
                                    </button>
                                    <button className="w3-button survey-workout-delete"
                                        type="button"
                                        onClick={this.handleDeleteRow.bind(this,idx)}>
                                    <i className="fa fa-times w3-large" aria-hidden="true" ></i>
                                    </button>
                                </td>
                            </tr>
                        );
                    } else if (item.category == 'training') {
                        var trainingDays = [];
                        for (var i = 0; i < daysList.length; i++) {
                            if (item[`${daysList[i].toLowerCase()}`] == '1') {
                                trainingDays.push(daysList[i]);
                            }
                        }
                        trainingTable.push(
                            <tr key={idx}>
                                <td>{item.routine_type}</td>
                                <td>{moment(item.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                                <td>{moment(item.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                <td className="desc-col">{trainingDays.toString()}
                                    <button className="w3-button survey-workout-edit"
                                        type="button" onClick={this.enableEdit.bind(this,idx)}>
                                    <i class="fa fa-pencil-square w3-large" aria-hidden="true"></i>
                                    </button>
                                    <button className="w3-button survey-workout-delete"
                                    type="button"
                                    onClick={this.handleDeleteRow.bind(this, idx)}>
                                    <i className="fa fa-times w3-large" aria-hidden="true" ></i>
                                    </button>
                                </td>
                            </tr>
                        );
                    }
                });
            }

            if (workoutTable.length == 0) {
                workoutTable = (
                    <tr>
                        <td colSpan="10">
                            <div className="w3-row">
                                <div className="w3-col m6 s6 l6 w3-padding-small">
                                    <label className="w3-text-center">Workout data not available</label>
                                </div>
                                <div className="w3-col m6 s6 l6">
                                    <label className="w3-padding-small w3-right w3-text-center w3-medium" style={{ color: '#1779ba' }}>
                                        <input
                                            checked={status && status.workout ? JSON.parse(status.workout) : false}
                                            type="checkbox"
                                            onClick={this.handleToggleActivity.bind(this, "workout", (status && status.workout ? JSON.parse(status.workout) : false))}
                                            className="w3-check"
                                        />
                                        No Excercise/Training
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            }
            if (trainingTable.length == 0){
                trainingTable = (
                    <tr>
                        <td colSpan="10">
                            <div className="w3-row">
                                <div className="w3-col m6 s6 l6 w3-padding-small">
                                    <label className="w3-text-center">Training data not available</label>
                                </div>
                                <div className="w3-col m6 s6 l6">
                                    <label className="w3-padding-small w3-right w3-text-center w3-medium" style={{ color: '#1779ba' }}>
                                        <input
                                            checked={status && status.training ? JSON.parse(status.training) : false}
                                            type="checkbox"
                                            onClick={this.handleToggleActivity.bind(this, "training", (status && status.training ? JSON.parse(status.training) : false))}
                                            className="w3-check"
                                        />
                                        No Sports Training
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            }

            if (packageCategory === 'Sports') {
                tableData = (
                    <div className="w3-col m12 s12">
                        <div className=" w3-row w3-margin-bottom">
                            <h6>Training</h6>
                            <div className="w3-responsive">
                                <table className="w3-table-all sports-survey">
                                    <thead>
                                        <tr className="w3-light-grey">
                                            <th>Type</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trainingTable}
                                    </tbody>
                                </table>
                            </div>
                        
                            { (!hideTrainingButton) &&
                                <div className="w3-margin">
                                    <div className="w3-padding-small w3-col m6 s6 l6" />
                                    <div className="w3-col m6 l6 s6">
                                        <button
                                            type="button"
                                            className="w3-button w3-right w3-padding-small nutrify-btn"
                                            onClick={this.showModal.bind(this, 'training')}
                                        >
                                            Add Sports
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="w3-row w3-margin-top">
                            <h6>General Fitness</h6>
                            <div className="w3-responsive">
                                <table className="w3-table-all sports-survey">
                                    <thead>
                                        <tr className="w3-light-grey">
                                            <th>Type</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workoutTable}
                                    </tbody>
                                </table>
                            </div>
                            <SurveyWorkoutManager
                                packageId={packageId}
                                clientId={clientId}
                                type={type}
                                question={questions[0]}
                                packageCategory={packageCategory}
                                token={this.props.token}
                            />
                            { (!hideWorkoutButton) &&
                                <div className="w3-margin">
                                    <div className="w3-padding-small w3-col m6 s6 l6" />
                                    <div className="w3-col m6 s6 l6">
                                        <button
                                            type="button"
                                            className="w3-button w3-right w3-padding-small nutrify-btn"
                                            onClick={this.showModal.bind(this, 'workout')}
                                        >
                                            Add Exercise Schedule
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="w3-margin-top">
                            <div>
                                <label className="survey-bold">Notes</label>
                                <textarea
                                    name="notes" 
                                    defaultValue={status && status.notes ? status.notes : ''}
                                    onBlur={(e) => this.addWorkoutNotes(e)} 
                                    type="text"
                                    className="w3-input w3-border"
                                />
                            </div>
                        </div>
                    </div>
                );
            } else if (packageCategory !== 'Sports') {
                tableData = (
                    <div className="w3-col m12 s12">
                        <div>
                            <h6>General Fitness</h6>
                            <div className="w3-responsive">
                                <table className="w3-table-all sports-survey">
                                    <thead>
                                        <tr className="w3-light-grey">
                                            <th>Type</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workoutTable}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <SurveyWorkoutManager
                            packageId={packageId}
                            clientId={clientId}
                            type={type}
                            packageCategory={packageCategory}
                            token={self.props.token}
                        />
                        { (!hideWorkoutButton) &&
                             <div className="w3-margin">
                                <div className="w3-padding-small w3-col m6 s6 l6" />
                                <div className="w3-col m6 s6 l6">
                                    <button
                                        type="button"
                                        className="w3-button w3-right w3-padding-small nutrify-btn"
                                        onClick={this.showModal.bind(this, 'workout')}
                                    >
                                        Add Exercise Schedule
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="w3-margin-top">
                            <div>
                                <label className="survey-bold">Notes</label>
                                <textarea
                                    name="notes"
                                    defaultValue={status && status.notes ? status.notes : ''}
                                    onBlur={(e) => this.addWorkoutNotes(e)} 
                                    type="text"
                                    className="w3-input w3-border"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        }

        if (questions[0].category == 'MEDICAL CONDITIONS') {
            if (packageCategory == 'Sports') {
                tableData = (<div className="w3-row w3-row-padding">
                    <div className="m12 s12 w3-col">
                        <Question token={self.props.token} question={questions[0]} responses={self.props.responses} />
                    </div>
                </div>);
            } 
        }

        if (questions[0].category == 'SYMPTOMS') {
            if (packageCategory == 'Sports') {
                tableData = (<div className="w3-row w3-row-padding">
                    <div className="m12 s12 w3-col">
                        <Question token={self.props.token} question={questions[0]} responses={self.props.responses} />
                    </div>
                </div>);

            } else if (packageCategory != 'Sports') {
                var data = [];
                var id = 1;
                for (var i = 0; i < questions.length; i++) {
                    var activeCls = '';
                    if (id == 1) {
                        id++;
                        activeCls = 'w3-show';
                    }
                    data.push(
                        <Accordion
                            active={activeCls}
                            category={questions[i].text}
                            token={self.props.token}
                            question={questions[i]}
                            responses={self.props.responses}
                            mandatory={questions[i].mandatory}
                            toggleCls={self.state.toggleCls}
                            onClick={self.handleToggle.bind(self)}
                            key={i}
                            id={i}
                        />
                    );
                }
                tableData = (
                    <div className="w3-content" style={{maxWidth:"900px"}}>
                        <div className="m12 s12 w3-col">
                            <ul id='symptoms-accordion' className="w3-row w3-row-padding">
                                {data}
                            </ul>
                        </div>
                    </div>
                )

            }
        }

        if (questions[0].category == 'DIETARY RECALL') {
               tableData = (
                <div className="w3-row">
                    <div className="m12 w3-col">
                        <MealSection
                            token={this.props.token} 
                            packageDetail={this.props.packageDetail}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="w3-padding-small"> 
                <div className="w3-row w3-row-padding w3-margin-top w3-margin-bottom">
                    {tableData != null ? tableData : questionsList}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        surveyResponses: state.surveyResponses,
        progress: state.surveyProgress,
        packageDetail: state.packageDetail,
        surveyWorkoutDetails: state.surveyWorkoutDetails,
        mealList : state.surveyMealList
    };
}
export default connect(mapStateToProps, {
    getPackageDetails,
    getMealTimeDetails,
    getSurveyWorkoutDetails,
    getSurveyResponses
})(QuestionList);