import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import { getdietPlanListbyPackageId } from "../../js/actions.js";
import {LoadingIndicator} from "../Common/HelperComponents.js";
import moment from 'moment';

class DietPlanPackageList extends Component {

    render() {
        return (
            <tr>
                <td>{this.props.si_no}</td>
                <td>{this.props.item.description}</td>
                <td>{moment(this.props.item.created_at).format('DD-MMM-YYYY hh:mm:ss')}</td>
                <td>{this.props.item.max_calories}</td>
                <td>
                    <Link to={"/workspace/" + this.props.item.package_id + "/" + this.props.item.id} className="no-decoration"> View </Link> 
                </td>
            </tr>
        )
    }
}

class DietPlanListbyPackage extends Component {

    constructor(args) {
        super(args);

        this.state = {
            planId: null
        };
    }

    componentDidMount(){
        this.props.getdietPlanListbyPackageId(this.props.packageId);
    }

    componentDidUpdate(prevProps,prevState) {
        if (!prevProps || prevProps.packageId != this.props.packageId) {
            this.props.getdietPlanListbyPackageId(this.props.packageId);
        }
    }

    render() {
        var rows = [];
        var dietPlanList = this.props.dietPlanList;
        var keyId = 0;
        
        if(!dietPlanList){
            return <LoadingIndicator />;
        }else{
            if (dietPlanList.length == 0){
                return(
                    <div className="alert info"> 
                    There are no results matching the current filter
                </div>)
            }
        }
        if (this.props.dietPlanList && this.props.dietPlanList.data && this.props.dietPlanList.data.length > 0) {
            this.props.dietPlanList.data.forEach(function (item) {
                    rows.push(<DietPlanPackageList key={ "r1_" + keyId++}
                            targetPackageId = {this.props.packageId}
                            item = {item} 
                            si_no={keyId}   />);
                }.bind(this));
        }

        return (<div className="m12 w3-col">
                    <div className="w3-responsive">
                        <table className="w3-table-all listing plan-list">
                            <thead>
                                <tr className="w3-light-grey">
                                    <th>Sl.No</th>
                                    <th>Description</th>
                                    <th>Created At</th>
                                    <th>Maximum Calories</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                   </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dietPlanList: state.dietPlanListbyPackageId
    };
}
export default connect(mapStateToProps, {getdietPlanListbyPackageId})(DietPlanListbyPackage);
