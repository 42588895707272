import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { fireRequest, getClientDetails, getBodyVitalsDetails } from "../../js/actions.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { LoadingIndicator } from "../Common/HelperComponents.js";
import VitalBodyManager from './VitalBodyManager.js';
import moment from 'moment';
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';


class VitalBodyParams extends Component {

    constructor(args) {
        super(args);
        this.state = {
            showMoreButton: false
        }
    }

    componentDidMount() {
        if (!this.props.bodyVitals) {
            this.props.getBodyVitalsDetails(this.props.clientId);
        }
    }

    componentWillReceiveProps(newProps) {
        if (!this.props.bodyVitals && (newProps.clientId != this.props.clientId)) {
            this.props.getBodyVitalsDetails(newProps.clientId);
        }
    }

    closeBodyVitalView(e) {
        e.preventDefault();
        if (this.props.bodyVitals) {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'closeBodyVitalView',
                value: ''
            })
        } else {
            $("#offCanvas").toggleClass('is-open');
        }
    }

    handleMore() {
        this.setState({ showMoreButton: !this.state.showMoreButton })
    }


    render() {
        var headerColumns = [];
        var paramData = '';
        var paramItems = [];
        var categoryParamObj = [];
        var categoryArray = [];
        var finalParams = [];
        var bodyVitalParamsData = [];
        var paramTable = [];
        var paramTr = [];
        var self = this;

        //to handle calls from  client detail page and diet plan workspace differently
        if (this.props.bodyVitals) {
            bodyVitalParamsData = this.props.bodyVitals;
        } else {
            bodyVitalParamsData = this.props.bodyVitalParams;
        }

        if (bodyVitalParamsData == null) {
            return <LoadingIndicator />;
        }

        //no of parameters entered are stored in meta of api
        finalParams = bodyVitalParamsData.meta ? bodyVitalParamsData.meta.final : [];
        bodyVitalParamsData = bodyVitalParamsData.data ? bodyVitalParamsData.data : [];

        let reportParam = bodyVitalParamsData.filter(obj => obj.type == '');
        reportParam.reverse();
        if (reportParam.length > 0 && finalParams.length > 0) {
            headerColumns[0] = (
                <th
                    key="key_0"
                    style={{ position: 'sticky' }}
                >
                    Parameters
                </th>
            );
            headerColumns[1] = (
                <th key="key_range" style={{ position: 'sticky' }}>Range</th>
            );
        } else {
            paramTable.push(
                <div className="s12 w3-col w3-center">
                    <h6>No Body Parameters for the Client</h6>
                </div>
            );
        }

        reportParam.forEach(function (item, idx) {
            headerColumns.push(
                <th 
                    style={{textAlign:"center"}}
                    key={"key_" + item.id}>
                    {moment(item.reading_date).format('DD-MMM-YY')}
                </th>
            );
        }.bind(this));

        finalParams.forEach(function (final, index) {

            if (!paramItems[final.key]) {
                paramItems[final.key] = [];
            }
            //add name and range of parameters
            paramItems[final.key][0] = (
                <td
                    style={{ whiteSpace: 'pre-wrap', width: '150px' }}
                    key={"param_td_" + final.key + "_0_" + index}
                >
                    {final.name}
                </td>
            );
            paramItems[final.key][1] = (
                <td
                    style={{ whiteSpace: 'pre-wrap', width: '200px' }}
                    key={"param_td_" + final.key + "_1_" + index}
                >
                    {final.range}
                </td>
            );

            reportParam.forEach(function (reading, indx) {

                paramItems[final.key][indx + 2] = (
                    <td
                        style={{ width: "150px", textAlign:"center"}}
                        key={"td_empty_" + final.key + "_" + indx + "_" + reading.id}
                    ></td>
                );
                reading.reports.forEach(function (report, idx) {

                    if (report.body_parameter == final.key) {

                        paramData = report.data_type == 'decimal' ? report.decimal_value : report.string_value;

                        paramItems[final.key][indx + 2] = <td style={{ width: "150px", textAlign:"center" }} key={"param_td_" + report.id + "_" + idx}>{paramData}</td>;

                        categoryParamObj[report.body_parameter] = report.category;
                        categoryArray.push(report.category);
                    }
                });
            });

        });


        var categoryArray = categoryArray.filter((v, i, a) => a.indexOf(v) === i);
        categoryArray.forEach(function (value, idx) {
            paramTr = [];
            for (var eachParam in paramItems) {
                if (paramItems[eachParam].length > 0 && value == categoryParamObj[eachParam]) {
                    self.state.showMoreButton ?
                        paramTr.push(<tr key={idx + "_" + eachParam}>{paramItems[eachParam]}</tr>) :
                        paramTr.push(<tr key={idx + "_" + eachParam}>{paramItems[eachParam].slice(0, 7)}</tr>)

                }
            }

            const trimItems = self.state.showMoreButton ? headerColumns : headerColumns.slice(0, 7);
            paramTable.push(
                <div key={"div_" + idx}><h4>{value.charAt(0).toUpperCase() + value.slice(1) + " Report"}</h4>
                    <div className="w3-responsive">
                        <table className="report-table w3-table-all">
                            <thead>
                                <tr className="w3-light-grey">{trimItems}</tr>
                            </thead>
                            <tbody>{paramTr}</tbody>
                        </table>
                    </div>
                </div>
            );
        });
        return (
            <div className="m12 w3-col">
                <div className="params-view">
                    {this.props.bodyVitals ? <div className="w3-row">
                        <div className="w3-margin w3-center reveal-heading">
                            Body Vital Parameter Report
                        </div>
                        <button className="close-button w3-btn no-shadow w3-display-topright" onClick={this.closeBodyVitalView.bind(this)} aria-label="Close modal" type="button">
                            <span aria-hidden="true" className="w3-xlarge">&times;</span>
                        </button>
                    </div> : null}
                    <div className="w3-row">
                        <div className="vital-params-report w3-padding-16">
                            {paramTable}
                            {paramTr.length > 0 ?
                                <button className="w3-blue w3-button" style={{ marginTop: "16px" }} onClick={() => this.handleMore()}>{this.state.showMoreButton ? 'Show Less' : 'Show More'}</button>
                                : null}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToPropsBodyParams(state) {
    return {
        bodyVitalParams: state.bodyVitalParams,
        closeBodyVitalView: state.closeBodyVitalView
    };
}

export default connect(mapStateToPropsBodyParams,
    {
        fireRequest,
        getBodyVitalsDetails,
        getClientDetails
    })(VitalBodyParams);
