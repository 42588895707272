import React, { Component } from 'react'; 
import DieticianProfileReveal from './DieticianProfileReveal.js';
import {getDietitianList, getPaginatedList, fireRequest} from "../../js/actions.js";
import moment from 'moment';
import { Link,hashHistory } from 'react-router';
import { connect } from 'react-redux';
import {NavigationSwitcher,FilterList , LoadingIndicator, PaginationSwitcher} from "../Common/HelperComponents.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

class DieticiansRow extends Component {

    handleEdit(userId) {
        if (this.props.handleEdit) {
            this.props.handleEdit(userId);
        }
    }

    handleActi(userId) {
            if (this.props.handleActi) {
                this.props.handleActi(userId);
        }
        
    }

    render() {
        var dieticiansData = this.props.item;
        var status = dieticiansData.status;
        return (
            <tr className="align-left">
                <td>{dieticiansData.first_name+" "+dieticiansData.last_name}</td>
                <td>{dieticiansData.email}</td>
                <td>{dieticiansData.speciality}</td>
                <td>{moment(dieticiansData.created_at).format('DD-MMM-YYYY')}</td>
                <td>{status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}</td>
                <td>
                    <Link to={"/dietitian-details/" + dieticiansData.id} className="no-decoration">View </Link>  |
                    <a className="no-decoration" onClick={this.handleEdit.bind(this, dieticiansData.id)}> Edit</a>
                    {/* <button className="w3-blue w3-button" onClick={this.handleActi.bind(this, dieticiansData.id)} style={{marginLeft:"20px"}}>Activate/Deactivate</button> */}
                </td>
            </tr>
        );
    }
}

class SortableTh extends Component {

    constructor(args){
        super(args);
        this.state = {
            direction: null,
            icon: null
        };
        this.handleSort = this.handleSort.bind(this);
    }
    handleSort(evt){
        if (this.state.direction == null || this.state.direction == 'desc'){
            this.setState({direction:'asc',icon:<div className="sort-indicator" style={{paddingTop:'5px'}}><i className="icon ion-arrow-up-b"></i></div>});
        } else {
            this.setState({direction:'desc',icon:<div className="sort-indicator" style={{paddingTop:'5px'}}><i className="icon ion-arrow-down-b"></i></div>});
        } 
        this.props.onSort(this.props.title,this.state.direction);
    }
    render (){
        var sortBtn = <div className="sort-indicator"><i className="icon ion-arrow-up-b"></i><i className="icon ion-arrow-down-b"></i></div>;
        if (this.props.title != this.props.current){
            this.state.icon = sortBtn;
        }
        return <th className="sortable-th" onClick={e => this.handleSort()} >
            <div className="sort-row">
                {this.props.title}
                {this.state.icon}
            </div></th>
    }
}

class DietitianProfileList extends FilterList {

    constructor(args) {
        super(args);

        this.state = {
            dietitianIdForEdit: null,
            filterAll: true,
            searchQuery: '',
            revealFormOpen: '',
            revealDeacti : '',
            sortColumn: 'all'
        };
    }

    componentDidMount() {

        var $modal = $('#DieticianProfileReveal');
        $modal.foundation();
        $modal.on('click', function (e) {
            e.preventDefault();
        });

        if (this.props.dietitianStatus == null) {
            this.props.getPaginatedList('dietitianFilter', this.props.location.query);
        }

        if (!this.props.dietitianStatusList) {
            this.props.fireRequest('dietitianStatusList');
        }
    }

 
    setRevealClass(name) {
        this.setState({
            dietitianIdForEdit: null
        }, function () {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealFormOpen',
                value: name
            })
        }.bind(this));
    }

    setRevealClassDe(name) {
        this.setState({
            dietitianIdForEdit: null
        }, function () {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealDeacti',
                value: name
            })
        }.bind(this));
    }

    handleEdit(userId) {
        if (userId) {
            this.setState({
                dietitianIdForEdit: userId
            }, function () {
                gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealFormOpen',
                value: 'open'
            })
            }.bind(this))
        }
    }

    handleActi(userId) {
        if (userId) {
            this.setState({
                dietitianIdForEdit: userId
            }, function () {
                gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'revealDeacti',
                value: 'open'
            })
            }.bind(this))
        }
    }

    handleSearch(evt) {
        if (evt) {
            var filter = this.props.location.query.status ? this.props.location.query.status: "all";
            hashHistory.push("nutritionists?page=1&status=" + filter + "&q=" + evt.target.value);
            this.setState({
                searchQuery: evt.target.value
            });
        }
    }

    componentDidUpdate(prevProps,prevState) {
        var query = this.props.location.query 
        var prevQuery = prevProps.location.query
        if (prevQuery.status != query.status || query.page != prevQuery.page || query.q != prevQuery.q || query.order != prevQuery.order) {
            this.props.getPaginatedList('dietitianFilter', query);
        }
        $("#filter-dietitian").foundation();
    }

    orderDieticians(col) {
        this.state.sortColumn = col;
        var order = this.props.location.query.order == 'asc' ? 'desc' : 'asc';
        var filter = this.props.location.query.status ? this.props.location.query.status: "all";
        var page = this.props.location.query.page ? this.props.location.query.page : 1;
        var query = this.props.location.query.q ? this.props.location.query.q : '';
        hashHistory.push("nutritionists?page=1&status=" + filter + "&order=" + order + "&q=" + query + "&col=" + col);
    }

    render() {
        const dietitianFilter  = this.props.dietitianFilter;
        const order = this.props.location.query.order ? this.props.location.query.order : 'asc';
        const { searchQuery } = this.state;

        if (!dietitianFilter) {
            //return null;
            return <LoadingIndicator/>;
        }
        if (!this.props.dietitianStatusList) {
            //return null;
            return <LoadingIndicator/>
        }

        let firstNameList = [];
        let lastNameList = [];
        var rows = [];
        if ( searchQuery) {
            const query = searchQuery.toLowerCase();
            dietitianFilter.data.data.filter(item => {
                if (item.first_name.toLowerCase().indexOf(query) !== -1) {
                    firstNameList.push(item);
                } else if (item.last_name.toLowerCase().indexOf(query) !== -1) {
                    lastNameList.push(item);
                }
            });
            firstNameList.concat(lastNameList).map((item,idx) => {
                rows.push(
                    <DieticiansRow
                        key={ "idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                        handleActi={this.handleActi.bind(this)}

                    />
                );
            });
        } else {
            dietitianFilter.data.data.forEach((item,idx) => {
                rows.push(
                    <DieticiansRow
                        key={ "idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                        handleActi={this.handleActi.bind(this)}

                    />
                );
            });
        }

        var pagination = '';
        if(rows.length > 0){
            var filter = this.props.location.query.status ? this.props.location.query.status :  'all';
            var query = this.props.location.query.q ? this.props.location.query.q : '';
            pagination = 
            <div className="pagination-left w3-margin-top">
                    <ul className="pagination">
                        <li className="w3-padding-small w3-button"><Link to={"/nutritionists?status="+ filter +"&page=1&q="+ query + "&order=" + order + "&col="+ this.state.sortColumn}>«</Link></li>
                        <PaginationSwitcher basePath="/nutritionists" 
                            query={query}
                            filter={filter}   
                            order={order}
                            col={this.state.sortColumn}
                            selected={this.props.location.query.page || 1} 
                            options={dietitianFilter.data.last_page} />
                        <li className="w3-padding-small w3-button"><Link to={"/nutritionists?status="+ filter +"&page=" + dietitianFilter.data.last_page + "&q="+ query + "&order=" + order + "&col="+ this.state.sortColumn} >»</Link></li>
                    </ul>
            </div>
        }

        if (rows.length == 0 && this.isEmptyFilter()) {
            rows = <tr><td colSpan="6" className="no-data-text w3-center w3-padding-64" >
            <div className="w3-light-grey">Please add Nutritionists</div>
            </td></tr>
        } else if (rows.length == 0) {
            rows = <tr>
                <td colSpan="6" className="no-data-text w3-center w3-padding-64">
                <div className="w3-light-grey"> 
                    There are no results matching the current filter
                </div>
                </td>
            </tr>
        }

        return (
            <div className="w3-container w3-padding">
                <div className="m12 w3-col">
                  <h4 className="section-header">Nutritionist Profile</h4>
                </div>
                <div className="m10 w3-col">
                    <div className="w3-row w3-row-padding">
                        <div className="m4 w3-col">
                            <label>Search
                                <input
                                    type="search"
                                    onChange={this.handleSearch.bind(this)}
                                    placeholder="Search"
                                    className="search-btn w3-input w3-border w3-round"
                                    style={{height: "40px"}}
                                />
                            </label>
                        </div>
                        <div className="m3 w3-col">
                            <label>Status
                                <NavigationSwitcher
                                    page={this.props.location.query.page || 1}
                                    query={query}
                                    basePath="/nutritionists"
                                    options={this.props.dietitianStatusList.data}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="m2 w3-col">
                    <div className="w3-row w3-row-padding">
                        <div style={{textAlign: "right", paddingTop: "20px" }}>
                            <button
                                onClick={this.setRevealClass.bind(this, 'open')}
                                className="w3-button w3-padding-small w3-round w3-blue"
                                style={{ height: "40px" }}
                            >
                                Add Profile
                            </button>
                        </div>
                    </div>
                </div>
                <div className="s12 m12 w3-col w3-margin-top table">     
                    <div className="w3-responsive">     
                        <table className="w3-table-all listing">
                            <tbody>
                                <tr>
                                    <SortableTh onSort={this.orderDieticians.bind(this, 'Name')} title="Name" 
                                        current={this.state.sortColumn}/>
                                    <SortableTh onSort={this.orderDieticians.bind(this, 'Email')} title="Email" 
                                        current={this.state.sortColumn}/>
                                    <SortableTh onSort={this.orderDieticians.bind(this, 'Speciality')} title="Speciality" 
                                        current={this.state.sortColumn}/>
                                    <SortableTh onSort={this.orderDieticians.bind(this, 'Joined On')} title="Joined On" 
                                        current={this.state.sortColumn}/>
                                    <SortableTh onSort={this.orderDieticians.bind(this, 'Status')} title="Status" 
                                        current={this.state.sortColumn}/>
                                    <th>Action</th>
                                </tr>
                                {rows}
                            </tbody>
                    </table>
                    {pagination}
                    </div>
                </div>
                <DieticianProfileReveal query={this.props.location.query} dietitianId={this.state.dietitianIdForEdit} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dietitians: state.dietitianList,
        dietitianFilter: state.dietitianFilter,
        dietitianStatusList: state.dietitianStatusList
    };
}
export default connect(mapStateToProps, {
    getDietitianList, 
    getPaginatedList,
    fireRequest})(DietitianProfileList);

