import  React, { Component } from 'react';
import { Link } from 'react-router';
import $ from 'jquery'; 
import { connect } from 'react-redux';
import {getDietitianDetails} from "../../js/actions.js";

class Login extends Component {
    constructor(args) {
        super(args);
        this.state  = {
            errorMessage: null,
            valid : true,
        };

    }
    
    handleLogin(e) {
        e.preventDefault();
        var self = this;
        self.setState({errorMessage: null});
        $.ajax({
            url: '/api/v1/auth/login',
            method: 'POST',
            data: {
                email: $("#email").val(),
                password: $("#password").val()
            }
        }).done(function (resp) {

            if (resp.success == false) {
                self.setState({
                    errorMessage: resp.message
                })
            }else {
                document.location.href = "/#/";
            }
        })
    }

    render() {
        var msg = null;
        if (this.state.errorMessage) {
            msg = <div className="w3-margin w3-padding error-message ">
              <p>{this.state.errorMessage}</p>
            </div>

        }
        return (
            <div className="w3-container">
                <div className="w3-row">
                        <div className="m6 l4 w3-quarter login-block"> 

                            <form style={{padding: "50px 0px"}} onSubmit={this.handleLogin.bind(this)}>
                                <div className="w3-border w3-padding"> 
                                {msg}
                                    <label>Email
                                        <input className="w3-input w3-border w3-margin-bottom" id="email" name="email" type="text" placeholder="somebody@example.com" />
                                    </label>
                                    <label>Password
                                        <input className="w3-input w3-border w3-margin-bottom" id="password" name="password" type="text" type="password" placeholder="Password" />
                                    </label>
                                    <input className="w3-check check-small" id="remember-password" type="checkbox" /><label className="w3-padding-small" htmlFor="remember-password">Remember password</label>
                                    <p><button  type="submit" className="w3-button w3-block  w3-round  nutrify-btn">Log In</button></p>  
                                    <p className="w3-row"><Link className="no-decoration w3-right w3-link" to="/forgot-password">Forgot your password?</Link></p>   
                                </div>
                            </form>

                        </div>
                    </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dietitianDetails: state.dietitianDetails
    };
}


export default connect(mapStateToProps,{getDietitianDetails})(Login);
