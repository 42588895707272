import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getSurveyQuestions,
    getSurveyResponses,
    setData,
    getPackageDetails,
} from "../../js/actions.js";
import WorkoutDetails from './WorkoutDetails.js';
import DietaryRecall from './DietaryRecall.js';
import moment from 'moment';
import { LoadingIndicator } from '../Common/HelperComponents.js';

class QuestionResponse extends Component {
    constructor(args) {
        super(args);
        this.state = {
            userId: null,
        };
    }
    render() {
        var question = this.props.question;
        var answerItem = null;
        var prevAnswer = null;
        if (this.props.responses && this.props.responses.data) {
            this.props.responses.data.forEach(function (item) {
                if (question.id == item.question_id) {
                    prevAnswer = item;
                }
            });
        }

        switch (question.type.toLowerCase()) {
            case 'date':
                answerItem = (
                   <div className="m6 w3-col">
                        {prevAnswer ? (moment(prevAnswer.text_response).format('DD-MMM-YYYY')) : ' ' } 
                    </div>
                );
            break;

            case 'single_select':
                var list = [];
                var answerText = '';
                this.props.question.answers.forEach(function (item) {
                    if (prevAnswer && item.id == prevAnswer.answer_id) {
                        answerText = item.text_answer;
                    }
                });
                answerItem = (
                    <div className="m6 w3-col">
                        {answerText}
                    </div>
                );
            break;

            case 'multi_select':
                var list = [];
                var vals = [];
                try {
                    if (prevAnswer && prevAnswer.text_response) {
                        vals = JSON.parse(prevAnswer.text_response);
                    }
                } catch (ex) {
                }

                this.props.question.answers.forEach(function (item) {
                    if (vals.indexOf(item.id) != -1) {
                        list.push(
                           <div  key={"answer_" + item.id}>
                                 {item.text_answer + " : Yes"} 
                            </div>)
                    } else {
                        // list.push(<div  key={"answer_" + item.id}>
                        //      {item.text_answer + " : (-not selected-)"} 
                        // </div>)
                    }
                });
                answerItem = (
                    <div className="m11 s11 w3-col">
                        {list} 
                    </div>
               );
            break;

            case 'yes_no':
                var list  = [];
                var answer = '';
                this.props.question.answers.forEach(function (item) {
                    if (prevAnswer && prevAnswer.answer_id == item.id) {
                        answer = item.text_answer;
                    }
                });
                answerItem = (
                    <div className="m4 s5 w3-col"> 
                        {answer} 
                    </div>
                );
            break;

            default:
                answerItem = (
                   <div className="m11 w3-col w3-padding" style={{ whiteSpace: "pre-wrap" }}>
                        {prevAnswer ?  prevAnswer.text_response : '' } 
                    </div>
                );
            break;
        }

        return (
            <div className="s12 m12 w3-col question-view">
                <div className="w3-row">
                    <div className="s12 m7 w3-col">
                        <label>{question.text  }:</label>
                    </div>
                    <div className="s12 m5 w3-col">
                        <div className="w3-row">
                            {answerItem} 
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

class QuestionResponseList extends Component {
    render() {
        var questions = this.props.questions;
        return (
            <div className="left-padding"> 
                <div className="w3-row">
                    { questions.map((item, idx) => {
                        return (
                            <QuestionResponse
                                key={idx} 
                                question={item} 
                                responses={this.props.responses}
                            />  
                        );
                    })}
                </div>
            </div>
        );
    }
}

class SurveyResponse extends Component{
    constructor(args) {
        super(args);
        this.state = {
            questionCategoryMap: {},
        };
    }

    clearData(){
        this.props.setData('surveyResponses',null);
    }

    reloadData(token){
        this.clearData();
        const {
            packageDetail,
            surveyQuestions
        } = this.props;
        this.props.getSurveyResponses(token).then(resp => {
            if (!packageDetail || !packageDetail.data ||
                packageDetail.data.survey_token != token) {
                return this.props.getPackageDetails(0,token);
            } else {
                return packageDetail;
            }    
        }).then(resp => {
            if (surveyQuestions && surveyQuestions.data) {
                return surveyQuestions;
            }
            return this.props.getSurveyQuestions(token);
        }).catch(ex => {
        });
    } 
    
    componentDidMount() {
        const { token } = this.props;
        this.reloadData(token); 
    }
 
    componentWillReceiveProps(nextProps) {
        if (nextProps.token == this.props.token ) { 
            return;
        }
        this.reloadData(nextProps.token);
    }

    getSurveyQuestionsMap() { 
        const categoryMap = {};
        if (this.props.surveyQuestions) {
            this.props.surveyQuestions.data.forEach( (item, idx) => {
                if (item == null) {
                    return;
                } 
                if (item.category == 'DIETARY RECALL OLD' || 
                    item.category == 'SPORT OR EXERCISE TRAINING ROUTINE OLD'){
                    if (moment(this.props.packageDetail.data.created_at) >  moment('2018-07-04')) {
                        return;
                    }
                }
                if (!categoryMap[item.category]) {
                    categoryMap[item.category] = [];
                    categoryMap[item.category].push(item);
                } else {
                    categoryMap[item.category].push(item);
                }
            });
        }
        return categoryMap;
    }

    render() {
        let {
            packageDetail,
            surveyResponses,
            showCloseButton
        } = this.props;
        if (!packageDetail){
            return <LoadingIndicator />;
        }
        if (surveyResponses && surveyResponses.success == false) {
            return (
                <div className="  w3-padding-64">
                    <h3>Invalid assessment token</h3>
                </div>
            );
        }
        let showClsBtn="hide";
        if (showCloseButton){
            showClsBtn="show";
        }
        var packageCategory = packageDetail.data.category;
        var questionResponseList = [];
        const categoryMap = this.getSurveyQuestionsMap();
        var idx = 0;
        var questionTitle ="";

        for (var category in categoryMap) {
            idx++;
            let activeCls = '';
            if (idx == 1) {
                activeCls = 'is-active';
            }
            
            if (packageCategory == 'Sports' && category == 'SYMPTOMS' ||
                packageCategory == 'Sports' && category == 'MEDICAL CONDITIONS') {
                continue;
            } else if(packageCategory != 'Sports' && category == 'MEDICAL CONDITIONS_SPORTS'||
                packageCategory != 'Sports' && category == 'INJURIES_SPORTS'||
                packageCategory != 'Sports' && category == 'SYMPTOMS_SPORTS'){
                continue;
            }

            questionTitle = category;
            questionTitle = questionTitle.replace("_SPORTS", "");
            questionTitle = questionTitle.replace("OLD", "");
            questionTitle = questionTitle.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });

            if (category == 'SPORT OR EXERCISE TRAINING ROUTINE') {
                const status = surveyResponses && surveyResponses.data.find(i => i.text_response === category);
                questionResponseList.push(
                    <WorkoutDetails key={idx} notes={status && status.notes}/>
                );
            } else if (category == 'DIETARY RECALL') {
                questionResponseList.push(
                    <DietaryRecall key={idx}  />
                );
            } else {
                questionResponseList.push(
                    <div key={idx} className="w3-col m12 s12">
                        <h6>{questionTitle}</h6>
                        <QuestionResponseList
                            questions={categoryMap[category]}
                            responses={this.props.surveyResponses}
                        />       
                    </div>
                );
            }
        }

        return (
            <div className="left-padding">   
                <div className="w3-row">
                    <div className="s6 w3-col">
                        <h4> Body Assessment Readings Report </h4>
                    </div>
                    <div className="s12 w3-col">
                    {questionResponseList}
                    </div>
                </div> 
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        surveyQuestions: state.surveyQuestions,
        surveyResponses: state.surveyResponses,
        progress: state.surveyProgress,
        packageDetail: state.packageDetail,
    };
}
export default connect(mapStateToProps, {
    getSurveyQuestions,
    getSurveyResponses,
    setData,
    getPackageDetails
})(SurveyResponse);
