import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getSessionUser, getFoodConfigVal } from "../../js/actions.js";
import { LoadingIndicatorImage } from "../Common/HelperComponents.js";
import { FormTextField } from "../Common/HelperComponents.js";
import $ from 'jquery'; 


class UploadDataConfig extends Component{

    constructor(args) {
        super(args);

        this.state = {
            foodDBList: [],
            foodDBName: null,
            foodUrl: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if(this.props.foodConfigList && this.props.foodConfigList.data){
            this.setState({
                foodDBList: this.props.foodConfigList.data
            })
        }else{
            this.props.getFoodConfigVal();
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.foodConfigList !== this.props.foodConfigList){
            this.setState({
                foodDBList: nextProps.foodConfigList.data
            })
        }
    }

    addFoodDB(){
        if(!this.state.foodDBName || !this.state.foodUrl){
            return;
        }
        this.setState({
            foodDBList: [...this.state.foodDBList, {
                name: this.state.foodDBName,
                sheetId: this.state.foodUrl
                }
            ],
            foodDBName: null,
            foodUrl: null
        });
    }

    cancelChanges(){
        let updateData = [];
        if(this.props.foodConfigList && this.props.foodConfigList.data ){
            updateData = this.props.foodConfigList.data;
        }
        this.setState({
            foodDBList: updateData,
            foodDBName: null,
            foodUrl: null
        });
        this.props.getFoodConfigVal();
    }

    saveAllFoodDB(){
        let self = this;
        let data = this.state.foodDBList;
        $.ajax({
            url: '/api/v1/settings/food-config-value',
            type: 'POST',

            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(data),
        }).done(function (resp) {
            if(resp.success){
                self.props.getFoodConfigVal();
                alert("Urls updated successfully"); 
            }else{
                alert("Failed to update Urls");
            } 
        });
    }

    handleChange(e){
        const {name, value} = e.target
        console.log(name, value);
        if(name === 'foodDBName'){
            this.setState({
                foodDBName: e.target.value
            });
        }else{
            this.setState({
                foodUrl: e.target.value
            });
        }
    }

    handleDeleteRow(index){
        let urlList =  this.state.foodDBList;
        let removed = urlList.splice(index, 1);
        this.setState({
            foodDBList: urlList
        });
    }

    render(){ 
        let foodDBData = [];
        if(this.state.foodDBList && this.state.foodDBList.length>0){
            foodDBData = this.state.foodDBList;
        }

        let buttonIcon =  null;
        if(this.state.foodDBName && this.state.foodUrl){
            buttonIcon = (<i className="ion-checkmark" />);
        }

        let foodDBListRows = [];
        if (foodDBData && foodDBData.length){
            foodDBListRows = foodDBData.map((item, idx)=>{
                return(
                  <div className="w3-white w3-row w3-small w3-border w3-padding-small nutrify-flex">
                     <div style={{width:"25%"}}> 
                          <span>{item.name}</span> 
                      </div>
                     <div className="nutrify-flex" style={{width:"75%"}}> 
                          <span className="nutrify-url-list">{item.sheetId}</span> 
                     </div> 
                     <button className="w3-button w3-tiny settings-delete"
                              type="button"
                              onClick={this.handleDeleteRow.bind(this,idx)}>
                          <i className="ion-close"></i>
                      </button>
                  </div> 
                ) 
              } 
            );
        }
        foodDBListRows.push(
            <div className="w3-light-grey w3-row w3-margin-top">
               <div  className="s4 m4 w3-col">
                    <FormTextField
                        label=""
                        id="foodDBName"
                        name="foodDBName"
                        placeholder="Name"
                        onChange={this.handleChange}
                        value={this.state.foodDBName ? this.state.foodDBName: null } 
                        inputClass={"w3-input w3-small w3-border"}
                    />
                </div>
                <div className="s8 m8 w3-col">
                    <div className="w3-row" style={{display: "flex", flexDirection: "row"}}>
                    <div style={{width:"100%"}}>
                        <FormTextField
                            label=""
                            id="foodUrl"
                            name="foodUrl"
                            placeholder="Food Database Url"
                            onChange={this.handleChange}
                            value={this.state.foodUrl ? this.state.foodUrl : null} 
                            inputClass={"w3-input w3-small w3-border w3-padding"}
                        />
                    </div>
                        <button type="button" 
                                onClick={this.addFoodDB.bind(this)} 
                                className="w3-button settings-tick">
                                {buttonIcon}
                        </button>
                    </div>
                </div>
            </div>
            );
        
        return(
            <div className="w3-row">
            <div className="w3-col m12 s12">
                <div className="w3-light-grey w3-padding-small">
                    <h6><span>Food Database, Supplements & Recipes</span></h6>
                    <div className="w3-section w3-padding-small">
                            {foodDBListRows} 
                    </div>
                    <div className="w3-section w3-padding-small">
                        <button type="button" onClick={this.saveAllFoodDB.bind(this)} className="w3-button w3-tiny w3-padding-small nutrify-btn">Save Changes</button>
                        <button type="button" onClick={this.cancelChanges.bind(this)} className="w3-button w3-tiny w3-padding-small nutrify-btn w3-right">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        foodConfigList: state.foodConfigVal
    };
}
export default connect(mapStateToProps, {
    getFoodConfigVal,
    getSessionUser} )(UploadDataConfig);