import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import PackageCreateEditModal from './PackageCreateEditModal.js';
import {
    getClientProfileList,
    getPaginatedList,
    fireRequest
} from "../../js/actions.js";
import { 
    FilterList,
    LoadingIndicator,
    PaginationSwitcher,
    FormSelectField,
    FormTextField
} from "../Common/HelperComponents.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'querystring';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

function getAllList(list) {
    // console.log('list',list);
    let options = [];
    if(list) {
        options = list.map(item => ({name:item,val:item}));
    }
    options.unshift({name:'All',val:''});
    return options;
}
class PackageRow extends Component {

    handleEdit(packageData) {
        if (this.props.handleEdit) {
            this.props.handleEdit(packageData);
        }
    }

    render() {
        var packageData = this.props.item;
        var startDate;
        var endDate;
        if (packageData.start_date == '0000-00-00' || packageData.start_date == null || packageData.start_date == undefined) {
            startDate = "Not Selected";
        } else {
            startDate = moment(packageData.start_date).format('DD-MMM-YYYY');
        }
        if (packageData.end_date == '0000-00-00' || packageData.end_date == null || packageData.end_date == undefined) {
            endDate = "Not Selected";
        } else {
            endDate = moment(packageData.end_date).format('DD-MMM-YYYY');
        }
        return (
            <tr>
                <td>{packageData.client_name + ' ' + packageData.last_name}</td>
                <td>{packageData.dietitian_name + ' ' + packageData.dietitian_last_name}</td>
                <td>{packageData.sub_category}</td>
                <td>{packageData.duration}</td>
                <td>{packageData.organisation_name}</td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td>{packageData.status}</td>
                <td>
                    <Link to={"/workspace/" + packageData.id + "/list"} className="no-decoration">View</Link> |
                    <a className="no-decoration" onClick={this.handleEdit.bind(this, packageData)}> Edit</a>
                </td>
            </tr>
        );
    }
}

class SortableTh extends Component {
    constructor(args) {
        super(args);
        this.state = {
            direction: null,
            icon: null
        };
        this.handleSort = this.handleSort.bind(this);
    }
    handleSort(evt) {
        if (this.state.direction == null || this.state.direction == 'desc') {
            this.setState({ direction: 'asc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-up-b"></i></div> });
        } else {
            this.setState({ direction: 'desc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-down-b"></i></div> });
        }
        this.props.onSort(this.props.title, this.state.direction);
    }
    render() {
        var sortBtn = <div className="sort-indicator"><i className="icon ion-arrow-up-b"></i><i className="icon ion-arrow-down-b"></i></div>;
        if (this.props.title != this.props.current) {
            this.state.icon = sortBtn;
        }
        return <th className="sortable-th" onClick={e => this.handleSort()} >
            <div className="sort-row">
                {this.props.title}
                {this.state.icon}
            </div>
        </th>
    }
}

class ClientPackageList extends FilterList {
    constructor(args) {
        super(args);
        this.state = {
            revealFormOpen: '',
            filterAll: true,
            searchQuery: '',
            sortColumn: 'all',
            category: null, 
        };
    }

    componentDidMount() {
        if (this.props.location.query.col) {
            this.state.sortColumn = this.props.location.query.col;
        }//sets sort column as passed by pagination in sidebar menu
        this.props.getPaginatedList('packageStatus', this.props.location.query);
        if (this.props.ClientList == null) {
            this.props.getClientProfileList();
        }
        if ($("#filter-package").length > 0) {
            $("#filter-package").foundation();
        }
        if (!this.props.packageStatusList) {
            this.props.fireRequest('packageStatusList');
        }
        if (!this.props.categoryList) {
            this.props.fireRequest('categoryList');
        }
        if (this.props.dietitianListAuto == null) {
            this.props.fireRequest('dietitianListAuto');
        }
    }

    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: {}
        });
        this.setState({
            revealFormOpen: name
        });
    }

    handleEdit(packageData) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'packageEditData',
            value: packageData
        });
        this.setState({
            revealFormOpen: 'open'
        });
    }

    handleSearch(evt) {
        const { location } = this.props;
        if (evt) {
            this.chagneValue({
                q: evt.target.value
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const query = nextProps.location.query;
        const prevQuery = this.props.location.query; 
        const q1 = queryString.stringify(query);
        const q2 = queryString.stringify(prevQuery); 
       
        if (q1 != q2) {  
            console.log(q2, q1 == q2)
            this.props.getPaginatedList('packageStatus', query);
        } 

        if (query.category != prevQuery.category) {
            this.props.fireRequest('subCategoryList', [], {
                category: 'Categories',
                parent: query.category
            });
        }
    }

    orderPackage(col) {
        var order = this.props.location.query.order == 'asc' ? 'desc' : 'asc';
        this.chagneValue({
            col,
            order
        });
    }

    chagneValue(object) {
        const { location: { query } } = this.props;
        hashHistory.push(`/packages?${queryString.stringify(Object.assign({},query, object))}`)
    }

    handleChange(evt) { 
        const value = evt.target.value;
        const name = $(evt.target).attr('id');
        this.chagneValue({
            [name]: value
        });
    }

    render() {
        const {
            categoryList,
            durationList,
            packageFilterList,
            location,
            packageStatusList,
            dietitianListAuto,
            subCategoryList,
        } = this.props;
        const { 
            searchQuery
        } = this.state;
        
        const {query} = location;
        const { start_date = '', end_date = '' } = query;

        const order = query.order ? query.order : 'default';

        if (!packageFilterList) {
            return <LoadingIndicator />;
        }
        if (!packageStatusList) {
            return <LoadingIndicator />;
        }

        let packageFilterListData = packageFilterList.data.data.map((item) => {
            if (item.status == 'Send Survey') {
                item.status = 'Send Assessment Form';
            } else if (item.status == 'Survey Response Pending') {
                item.status = 'Assessment Response Pending';
            } else if (item.status == 'Survey Response Received') {
                item.status = 'Assessment Response Received';
            }
            return item;
        });
        //console.log('packageFilterList: ',packageFilterListData);

        const options = getAllList(categoryList && categoryList.data);
        const subOptionList = getAllList(subCategoryList && subCategoryList.data);  
        console.log('Subs',subOptionList,subCategoryList);
        const durationOptions = getAllList(durationList && durationList.data);


        const dietitian = [];
        dietitian.push({ val: '', name: 'All' });
        if (dietitianListAuto && dietitianListAuto.data.length > 0) {
            dietitianListAuto.data.forEach(item => {
                if (item.status.toUpperCase() != 'ACTIVE') {
                    return;
                }
                dietitian.push({
                    val: item.id,
                    name: item.first_name + ' ' + item.last_name
                });
            });
        }

        let rows = [];
        let firstNameList = [];
        let lastNameList = [];
        if (searchQuery) {

           const q = searchQuery.toLowerCase();
            packageFilterListData.filter(item => {
                if (item.client_name.toLowerCase().indexOf(q) !== -1) {
                    firstNameList.push(item);
                } else if (item.last_name.toLowerCase().indexOf(q) !== -1) {
                    lastNameList.push(item);
                }
            });
            firstNameList.concat(lastNameList).map((item, idx) => {
                rows.push(
                    <PackageRow
                        key={"idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                    />
                );
            });
        } else {
            packageFilterListData.forEach((item, idx) => {
                rows.push(
                    <PackageRow
                        key={"idx_" + idx}
                        item={item}
                        handleEdit={this.handleEdit.bind(this)}
                    />
                );
            });
        }

        let pagination = '';
        if (rows.length > 0) { 
            const q = query.q ? query.q : '';
            pagination = (
                <div className="pagination-left">
                    <ul className="pagination">
                        <li className="w3-padding-small w3-button">
                            <Link to={{
                                pathname: "/packages",
                                query: {
                                    status:query.status,
                                    page: 1,
                                    q,
                                    order,
                                    col: this.state.sortColumn,
                                    start_date,
                                    end_date
                                }
                            }}>«</Link>
                        </li>
                        <PaginationSwitcher
                            basePath="/packages"
                            query={q}
                            startDate={start_date}
                            endDate={query.end_date}
                            // eslint-disable-next-line no-restricted-globals
                            filter={status}
                            order={order}
                            col={this.state.sortColumn}
                            selected={this.props.location.query.page || 1}
                            options={packageFilterList.data.last_page}
                        />
                        <li className="w3-padding-small w3-button">
                            <Link to={{
                                pathname: "/packages",
                                query: {
                            // eslint-disable-next-line no-restricted-globals
                                    status,
                                    page: packageFilterList.data.last_page,
                                    q,
                                    order,
                                    col: this.state.sortColumn,
                                    start_date,
                                    end_date
                                }
                            }}>»</Link>
                        </li>
                    </ul>
                </div>
            );
        }

        if (rows.length == 0 && this.isEmptyFilter()) {
            rows = (
                <tr>
                    <td colSpan="9" className="no-data-text w3-center w3-padding-64" >
                        <div className="w3-light-grey">There are no existing packages.
                        Add your first package!!<br />
                            To add a new Package make sure you have added
                        <br /> at least one Client and a Nutritionist.
                    </div>
                    </td>
                </tr>
            );
        } else if (rows.length == 0) {
            rows = (
                <tr>
                    <td colSpan="9" className="no-data-text w3-center w3-padding-64" >
                        <div className="w3-light-grey">
                            There are no results matching the current filter
                    </div>
                    </td>
                </tr>
            );
        }

        const clientTypeOptions = [
            { 'val': '', 'name': 'All' },
            { 'val': 'Corporate', 'name': 'Corporate' },
            { 'val': 'Clinic', 'name': 'Clinic' },
            { 'val': 'Club', 'name': 'Club' },
            { 'val': 'Defense', 'name': 'Defense' },
            { 'val': 'Hospital', 'name': 'Hospital' },
            { 'val': 'Institute', 'name': 'Institute' },
            { 'val': 'Sports Academy', 'name': 'Sports Academy' },
            { 'val': 'Team', 'name': 'Team' }
        ];
        const statusList =  getAllList(packageStatusList && packageStatusList.data);
        
        return (
            <div className="w3-container w3-padding">
                <div className="m12 w3-col">
                    <h4 className="section-header">Packages</h4>
                </div>
                <div className="m12 w3-col">
                    <div className="w3-row ">
                        <div className="m12 w3-col">
                            <div className="w3-row w3-row-padding">
                                <div className="m2 w3-col">
                                    <label>Search</label>
                                    <input
                                        id="search"
                                        type="text"
                                        onChange={this.handleSearch.bind(this)}
                                        placeholder="Client Name.."
                                        className="w3-input w3-border w3-round"
                                        style={{ height: "40px" }}
                                    />
                                </div>
                                <div className="m2 w3-col"> 
                                    <FormSelectField
                                        label="Status"
                                        id="status"
                                        name="status"
                                        value={query.status ? query.status : 'All'}
                                        onChange={this.handleChange.bind(this)}
                                        options={statusList}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />

                                </div>
                                <div className="m2 w3-col">
                                    <FormSelectField
                                        label="Category"
                                        id="category"
                                        name="category"
                                        value={query.category}
                                        onChange={this.handleChange.bind(this)}
                                        options={options}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />
                                </div>
                                <div className="m2 w3-col">
                                    <FormSelectField
                                        label="Sub Category"
                                        id="sub_category"
                                        name="sub-category" 
                                        value={(query.sub_category && query.sub_category !== '') ? query.sub_category : 'All'}
                                        onChange={this.handleChange.bind(this)}
                                        options={subOptionList}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />
                                </div>
                                <div className="m2 w3-col">
                                    <FormSelectField
                                        label="Nutritionist"
                                        id="nutritionist"
                                        name="nutritionist"
                                        value={(query.nutritionist && query.nutritionist !== '') ? query.nutritionist : 'All'}
                                        onChange={this.handleChange.bind(this)}
                                        options={dietitian}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />
                                </div>
                                <div className="w3-col m2">
                                    <div style={{ textAlign: "right", paddingTop: "20px" }}>
                                        <button
                                            onClick={this.setRevealClass.bind(this, 'open')}
                                            className="w3-button w3-padding-small w3-blue w3-round add-btn"
                                            style={{ height: "40px" }}
                                        >
                                            Add Package
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col">
                        <div className="w3-col m12">
                            <div className="w3-row w3-row-padding w3-margin-top">
                                <div className="m2 w3-col">
                                    <label>Organisation Name
                                        <input
                                            id="organisation"
                                            type="search"
                                            onChange={this.handleSearch.bind(this)}
                                            placeholder="Organisation"
                                            className="w3-input w3-border w3-round search-btn"
                                            style={{ height: "40px" }}
                                        />
                                    </label>
                                </div>
                                <div className="m2 w3-col">
                                    <FormSelectField
                                        label="Client Type"
                                        id="client_type"
                                        name="client_type"
                                        value={query.client_type}
                                        onChange={this.handleChange.bind(this)}
                                        options={clientTypeOptions}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />
                                </div>
                                <div className="m2 w3-col">
                                    <FormSelectField
                                        label="Duration"
                                        id="duration"
                                        name="duration"
                                        value={query.duration}
                                        onChange={this.handleChange.bind(this)}
                                        options={durationOptions}
                                        selectClass={'w3-select w3-border w3-round'}
                                    />
                                </div>
                                <div className="m3 w3-col">
                                    <FormTextField
                                        id="start_date"
                                        label="Start Date"
                                        name="start_date"
                                        placeholder="DD-MM-YYYY"
                                        value={start_date}
                                        onChange={this.handleChange.bind(this)}
                                        type="date"
                                        inputClass={"w3-input w3-border w3-round"}
                                    />
                                </div>
                                <div className="w3-col m3">
                                    <FormTextField
                                        id="end_date"
                                        label="End Date"
                                        placeholder="DD/MM/YYYY"
                                        name="end_date"
                                        value={end_date}
                                        onChange={this.handleChange.bind(this)}
                                        type="date"
                                        inputClass={"w3-input w3-border w3-round"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="s12 w3-col">
                    <div className="w3-responsive">
                        <table className="w3-table-all listing w3-margin-top">
                            <thead>
                                <tr className="w3-light-grey">
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Client')} title="Client"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Nutritionist')} current={this.state.sortColumn}
                                        title="Nutritionist" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Sub Category')} current={this.state.sortColumn}
                                        title="Sub Category" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Duration')} current={this.state.sortColumn}
                                        title="Duration" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Organisation Name')} current={this.state.sortColumn}
                                        title="Organisation Name" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Start Date')} current={this.state.sortColumn}
                                        title="Start Date" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'End Date')} current={this.state.sortColumn}
                                        title="End Date" />
                                    <SortableTh onSort={this.orderPackage.bind(this, 'Status')} current={this.state.sortColumn}
                                        title="Package Status" />
                                    <th className="table-actions">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        {pagination}
                    </div>
                </div>
                <NutrifyReveal openCls={this.state.revealFormOpen} customWidth={"600px"}>
                    <PackageCreateEditModal query={query} params={this.props.params} />
                    <button className="close-button w3-button  w3-display-topright" onClick={this.setRevealClass.bind(this, '')} aria-label="Close modal" type="button">
                        <span aria-hidden="true" className="w3-xlarge">&times;</span>
                    </button>
                </NutrifyReveal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        packageFilterList: state.packageStatus,
        packageStatusList: state.packageStatusList,
        categoryList: state.categoryList,
        dietitianListAuto: state.dietitianListAuto,
        durationList: state.durationList,
        subCategoryList: state.subCategoryList,
    };
}
export default connect(mapStateToProps, {
    getClientProfileList,
    getPaginatedList,
    fireRequest
})(ClientPackageList);
