import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {getDietitianDetails} from "../../js/actions.js";
import moment from 'moment';


class DietitianProfileList extends Component {

   
    componentDidMount() {
        this.props.getDietitianDetails(this.props.params.id);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.params.id != this.props.params.id) { 
            this.props.getDietitianDetails(newProps.params.id);  
        }
    }

    render() {
        var data = null;

        if (!this.props.dietitianDetails || this.props.dietitianDetails == null) {
            return (<div>Loading...</div>);
        } else {
            data = this.props.dietitianDetails.data.data[0];
        }
        return (
                    <div className="w3-container w3-border w3-padding">
                        <div className="w3-row">
                            <div className="s12">
                                <h4 className="left-padding">Nutritionist Details</h4>
                            </div>
                        <hr />
                        </div>
                        <div className="s12 ">
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Name
                                </div>
                                <div className="s6 m8 w3-col">
                                    <b>{data.first_name} {data.last_name}</b>
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Description
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.description}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Speciality
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.speciality}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Experience
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.experience}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Email
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.email}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Phone
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.phone}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Address
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.address}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Date of Birth
                                </div>
                                <div className="s6 m8 w3-col">
                                   {(data.dob)?(moment(data.dob).format('DD-MMM-YYYY')):''}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Father's Name
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.fathers_name}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Blood Group
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.blood_group}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Pan Card Number
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.pan_card_number}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Aadhar Number
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.adhar_number}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Emergency Contact Person Name
                                </div>
                                <div className="s6 m8 w3-col">
                                      {data.emergency_contact_person}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Emergency Contact Number
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.emergency_contact_number}
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m4 w3-col">
                                    Nutritionist Status
                                </div>
                                <div className="s6 m8 w3-col">
                                    {data.status}
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dietitianDetails: state.dietitianDetails
    };
}
export default connect(mapStateToProps,{getDietitianDetails})(DietitianProfileList);
