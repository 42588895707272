import React, { Component } from 'react';
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { connect } from 'react-redux';
import {
    fireRequest,
    getSurveyWorkoutDetails,
    getSurveyResponses
} from "../../js/actions.js";
import Select from 'react-select'; 
import moment from 'moment';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';


class CheckBox extends Component {
    toggleChange(evt) {
        if(evt.target.id == 'check_all'){
            this.props.handleCheckAllChange(evt.target.id);
        } else if (evt.target.id == 'week_days'){
            this.props.handleCheckWeekDaysChange(evt.target.id);
        } else if (evt.target.id == 'week_ends'){
            this.props.handleCheckWeekEndsChange(evt.target.id);
        } else{
            this.props.handleCheckBoxChange(this, evt.target.checked);
        }
    }

   

    render() {
        let { values, cls } = this.props;
        return (
            <div className={`m3 w3-col ${cls}`}>
                <label className={values.errors ? "checkbox-error":""}>
                    <input
                        type="checkbox"
                        id={values.id}
                        value={values.value}
                        checked={values.checked}
                        onChange={this.toggleChange.bind(this)} 
                        style={{marginRight:"3px"}}/>
                    {values.label}
                </label>
            </div>
        );
    }
}


class SurveyWorkoutManager extends Component {
    constructor(props) {
        super(props);
        var daysArray = [];
        this.daysList = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ];
        this.daysList.forEach((day) => {
            daysArray.push({
                id: day,
                label: day,
                value: day,
                checked: false,
                errors:false
            });
        });

        var dayCheckBoxes = daysArray.map((day, idx) => {
            var dayObj = {
                id: day.id,
                label: day.label,
                value: day.value,
                checked: day.checked,
                errors:day.errors
            };
            return dayObj;
        });

        this.state = {
            formData: {},
            errors: {},
            checkBoxOption: dayCheckBoxes,
            checkAll: false,
            checkAllChecked: false,
            checkWeekDays:false,
            checkWeekEnds: false,
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.resetData = this.resetData.bind(this);
        this.intersect = this.intersect.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
    }

    componentDidMount() {
        if (!this.props.timingList) {
            this.props.fireRequest('fitnessTimings');
        }
        //this.props.getSurveyWorkoutDetails(this.props.packageId); 
    }
    
    componentWillReceiveProps(newProps){
        let {
            checkBoxOption,
            checkAll,
            checkAllChecked,
            checkWeekDays,
            checkWeekEnds
        } = this.state;

        if (newProps.workoutEditData) {
            var data = newProps.workoutEditData;
            const obj = {};
            let formKey = ['id', 'category', 'routine_type', 'package_id', 'client_id'];
            var weekDays = this.daysList.slice(0, 5);
            var weekEnds = this.daysList.slice(5, 7);
            var event = '';
            var validDays = [];
    
            Object.keys(data).forEach(function (key) {
                checkBoxOption.forEach((day, idx) => {
                    if (day.id.toLowerCase() == key) {
                        checkBoxOption[idx].checked = (data[key] == 1) ? true : false;
                    }
                });
                for (let j = 0; j < formKey.length; j++) {
                    if (key == formKey[j]) {
                        obj[key] = data[key];
                    }
                }
                if (key == 'start_time' || key == 'end_time') {
                    obj[key] = moment(data[key], ["HH:mm"]).format("hh:mm A");
                }
            });

            checkBoxOption.forEach((day, idx) => {
                if (checkBoxOption[idx].checked == true) {
                    validDays.push(day.id);
                }
            });

            if (JSON.stringify(validDays) === JSON.stringify(this.daysList)) {
                event = 'check_all';
                checkAll = true;
                checkAllChecked = true;
                checkWeekEnds = false;
                checkWeekDays = false;
            }
            else if (JSON.stringify(validDays) === JSON.stringify(weekDays)) {
                event = 'week_days';
                checkAll = false;
                checkAllChecked = false;
                checkWeekEnds = false;
                checkWeekDays = true;
            } else if (JSON.stringify(validDays) === JSON.stringify(weekEnds)) {
                event = 'week_ends';
                checkAll = false;
                checkAllChecked = false;
                checkWeekEnds = true;
                checkWeekDays = false;
            } else {
                checkAll = false;
                checkAllChecked = false;
                checkWeekEnds = false;
                checkWeekDays = false;
            }
            this.setState({
                formData: obj,
                checkAll: checkAll,
                checkAllChecked: checkAllChecked,
                checkWeekDays: checkWeekDays,
                checkWeekEnds: checkWeekEnds
            });
        }
    }

    resetData() {
        let { checkBoxOption } = this.state;
        if (this.props.workoutEditData != null) {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'workoutEditData',
                value: null
            });
        }
        for (let i = 0; i < checkBoxOption.length; i++) {
            checkBoxOption[i].checked = false;
            checkBoxOption[i].errors = false;
        }
        this.setState({
            formData: {},
            checkBoxOption: checkBoxOption,
            checkAll: false,
            checkAllChecked: false,
            checkWeekDays: false,
            checkWeekEnds: false,
            errors:{}
        })
    }

    intersect(workoutDataList,formData){
        const self = this;
        let { checkBoxOption } = this.state;
        var intersectDays = [];
        const intersection = workoutDataList.find( workoutData => {
            const day = this.daysList.find(day => workoutData[day.toLowerCase()] == 1 && formData[day] == true);
            if (!day){
                return false;
            }
            if (formData.id == workoutData.id) {
                if( moment(formData.start_time, "hh:mm A").isSame(moment(workoutData.start_time, "hh:mm A")) &&
                moment(formData.end_time, "hh:mm A").isSame(moment(workoutData.end_time, "hh:mm A"))){
                    return false;
                }else if (moment(formData.start_time, "hh:mm A").isBetween(moment(workoutData.start_time, "hh:mm A"), moment(workoutData.end_time, "hh:mm A")) ||
                moment(formData.end_time, "hh:mm A").isBetween(moment(workoutData.start_time, "hh:mm A"), moment(workoutData.end_time, "hh:mm A"))){
                    return false;
                }else if (moment(workoutData.start_time, "hh:mm A").isBetween(moment(formData.start_time, "hh:mm A"), moment(formData.end_time, "hh:mm A")) ||
                    moment(workoutData.end_time, "hh:mm A").isBetween(moment(formData.start_time, "hh:mm A"), moment(formData.end_time, "hh:mm A"))) {
                    return true;
                }
            } else {
                if(moment(formData.start_time, "hh:mm A").isSame(moment(workoutData.start_time, "hh:mm A")) &&
                    moment(formData.end_time, "hh:mm A").isSame(moment(workoutData.end_time, "hh:mm A"))){
                    return true;
            } else if (moment(formData.start_time, "hh:mm A").isBetween(moment(workoutData.start_time, "hh:mm A"), moment(workoutData.end_time, "hh:mm A")) ||
                    moment(formData.end_time, "hh:mm A").isBetween(moment(workoutData.start_time, "hh:mm A"), moment(workoutData.end_time, "hh:mm A"))){
                    return true;
                } else if (moment(workoutData.start_time, "hh:mm A").isBetween(moment(formData.start_time, "hh:mm A"), moment(formData.end_time, "hh:mm A")) ||
                    moment(workoutData.end_time, "hh:mm A").isBetween(moment(formData.start_time, "hh:mm A"), moment(formData.end_time, "hh:mm A"))) {
                    return true;
                }
            }
        });      

        if (intersection != undefined) {
            Object.keys(intersection).forEach((key => {
                self.daysList.forEach(day=>{
                    let dayText= day.toLowerCase();
                    if (dayText == key && intersection[dayText] == 1) {
                        intersectDays.push(day);
                    }
                });
            }))

            intersectDays.forEach((day)=> {
                for (var i = 0; i < checkBoxOption.length; i++) {
                    if(checkBoxOption[i].id == day &&
                        checkBoxOption[i].checked == true){
                        checkBoxOption[i].errors = true;
                    }
                }
            });
            
            this.setState({ errors:intersection });
        }
        return intersection;
    }

    // updateEmptyField() {
    //     const {
    //         token,
    //         clientId,
    //         question,
    //         packageId
    //     } = this.props;
    //     $.ajax({
    //         type: 'POST',
    //         url: '/api/v1/survey/response/empty-work-out-data',
    //         data: {
    //             client_id: clientId,
    //             question_id: question.id,
    //             text_response: question.category,
    //             package_id: packageId,
    //             training: this.props.type === 'training' ? false : true,
    //             workout: this.props.type === 'workout' ? false : true
    //         },
    //         success: (() => {
    //             this.props.getSurveyWorkoutDetails(packageId);
    //             this.props.getSurveyResponses(token);
    //         }).bind(this)
    //     });
    // }

    handleSubmit(evt) {
        evt.preventDefault();
        let { formData } = this.state;
        let {
            clientId,
            packageId,
            type,
            surveyWorkoutDetails,
            token
        } = this.props;
        const self = this;
        let dupeCheck = false;
        let intersectType;
        let workoutDataList = [];
        const selectedDays = this.getCheckedValues();
        selectedDays.forEach((day)=> {
            for (var i = 0; i < self.daysList.length; i++) {
                if (day == self.daysList[i]) {
                    formData[day] = true;
                } else if (selectedDays.indexOf(self.daysList[i]) == -1){
                    delete formData[self.daysList[i]];
                }
            }
        });

        if (formData && formData.id) {
            packageId = formData.package_id;
        } else {
            formData['category'] = (type == 'workout') ? 'workout' : 'training';
            formData['clientId'] = clientId;
            formData['packageId'] = packageId;
        }

        if (formData['routine_type'] == null) {
            alert("Please Select the Routine Type");
            return;
        } else if (formData['start_time'] == null || formData['end_time'] == null) {
            alert("Please Select the Time");
            return;
        } else if (selectedDays.length == 0) {
            alert("Please Select the atleast one day");
            return;
        }

        if (surveyWorkoutDetails && surveyWorkoutDetails.data && surveyWorkoutDetails.data.length > 0){
            workoutDataList = surveyWorkoutDetails.data;
            let checkIntersect = this.intersect(workoutDataList,formData);
            if(checkIntersect && checkIntersect.id){
                intersectType = checkIntersect.routine_type;
               let warning =  window.confirm('The current schedule overlaps with other schedule!');
               if(warning == false){
                   return;
               }
                // alert("The current schedule overlaps with other schedule!")
                // dupeCheck = true;
            }
        }
            // if (dupeCheck) {
            //     alert(`Your current entry overlaps with '${intersectType}'.. Please modify the timings or the highlighted days to save`);
            //     return;
            // }

            if (formData && formData.id) {
                $.ajax({
                    type: "POST",
                    url: '/api/v1/survey/surveyworkout/edit',
                    data: formData,
                    success: (response) => {
                        if (response.success == true) {
                            self.resetData();
                            self.props.getSurveyWorkoutDetails(packageId);
                            self.setRevealClass('');
                        }
                    }
                });
            }
            else {
                $.ajax({
                    type: 'POST',
                    url: '/api/v1/survey/surveyworkout/create',
                    data: formData,
                    success: (response) => {
                        if (response.success == true) {
                            self.resetData();
                            self.props.getSurveyWorkoutDetails(packageId);
                            self.setRevealClass('');
                           // this.updateEmptyField();
                            this.props.getSurveyResponses(token);
                        }
                    }
                });
            }
    }

    getCheckedValues() {
        let { checkBoxOption } = this.state;
        let checkedDaysList = [];
        const checkedDays = checkBoxOption.filter((day, idx) => {
            return day.checked;
        });
        checkedDays.forEach((day) => {
            checkedDaysList.push(day.value);
        })
        return checkedDaysList;
    }

    handleWeekEndsCheckBoxes() {
        let { checkAll, checkWeekDays, checkWeekEnds, checkBoxOption } = this.state;
        if (checkWeekEnds === true && checkWeekDays === false && checkAll === false) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = false;
            });
        } else if (checkWeekEnds === false && checkWeekDays === false) {
            checkBoxOption.forEach((day, idx) => {
                if (day.value === 'Saturday' || day.value === 'Sunday') {
                    checkBoxOption[idx].checked = true;
                }
                else {
                    checkBoxOption[idx].checked = false;
                }
            });
        } else if (checkWeekEnds === false && checkWeekDays === true) {
            checkBoxOption.forEach((day, idx) => {
                if (day.value === 'Saturday' || day.value === 'Sunday') {
                    checkBoxOption[idx].checked = true;
                }
                else {
                    checkBoxOption[idx].checked = false;
                }
            });
        }
    }

    handleWeekDaysCheckBoxes() {
        let { checkWeekDays, checkWeekEnds, checkBoxOption } = this.state;
        if (checkWeekDays === true && checkWeekEnds === false) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = false;
            });
        } else if (checkWeekDays === false && checkWeekEnds === false) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = true;
                if (day.value === 'Saturday' || day.value === 'Sunday') {
                    checkBoxOption[idx].checked = false;
                }
            });
        } else if (checkWeekDays === false && checkWeekEnds === true) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = true;
                if (day.value === 'Saturday' || day.value === 'Sunday') {
                    checkBoxOption[idx].checked = false;
                }
            });
        }
    }

    handleCheckAll() {
        let { checkAll, checkAllChecked, checkBoxOption } = this.state;
        if (checkAll === false && checkAllChecked === false) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = true;
            });
        } else if (checkAll === true && checkAllChecked === true) {
            checkBoxOption.forEach((day, idx) => {
                checkBoxOption[idx].checked = false;
            });
        }
    }

    toggleCheckAll(evt) {
        const {
            checkAll,
            checkAllChecked,
            checkWeekDays,
            checkWeekEnds
        } = this.state;
        if (evt === 'check_all') {
            this.setState({
                checkAll: !checkAll,
                checkAllChecked: !checkAllChecked,
                checkWeekDays: false,
                checkWeekEnds: false
            });
            this.handleCheckAll();
        } else if (evt === 'week_days') {
            this.setState({ 
                checkWeekDays: !checkWeekDays,
                checkAll: false,
                checkAllChecked: false,
                checkWeekEnds:false
            });
            this.handleWeekDaysCheckBoxes();
        } else if (evt === 'week_ends') {
            this.setState({ 
                checkWeekEnds: !checkWeekEnds,
                checkWeekDays: false,
                checkAll: false,
                checkAllChecked: false,
            });
            this.handleWeekEndsCheckBoxes();
        }
    }

    handleCheckbox(changed, newState) {
        const self = this;
        let { checkBoxOption,errors } = this.state;
        var changedCheckBoxOption = checkBoxOption;
        checkBoxOption.forEach((day, idx) => {
            if (day.id === changed.props.values.id) {
                changedCheckBoxOption[idx].checked = newState;
                if(errors){
                    Object.keys(errors).forEach((key=>{
                        self.daysList.forEach(day=>{
                            let dayText= day.toLowerCase()
                            if (dayText == key && errors[dayText] == 1){
                                changedCheckBoxOption[idx].errors = newState;
                            } else if (dayText == key && errors[key] == 0 ){
                                changedCheckBoxOption[idx].errors = false;
                            }
                        })
                    }))
                }
            }
        });
        this.setState({
            checkBoxOption: changedCheckBoxOption
        });
    }

    handleSelect(name, selected) {
        if (!selected || !selected.value) {
            return;
        }
        let { formData, errors } = this.state;
        formData[name] = selected.value;
        this.setState({
            formData: formData
        });
    }

    setRevealClass(name) {
        this.resetData();
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealFormOpen',
            value: name
        });
    }

    filterOptions(timesOption,concatTimes,starTime){
        var filteredOptions = [];
        var foundOptionObj = timesOption.find((timesOpt)=> {
            const selectedOpt = concatTimes.find(concatOpt=>timesOpt["value"] == (starTime) && concatOpt["value"] == (starTime))
            return selectedOpt;
        });
        if(foundOptionObj != undefined) { 
            filteredOptions = timesOption.filter((option) => option.id > foundOptionObj.id);
        }
        return filteredOptions;
    }

    render() {
        const self = this;
        let {
            revealFormOpen,
            type,
            fitnessTimings,
            packageCategory,
            workoutEditData
        } = this.props;
        var modalTitle = ''
        var buttonText = '';

        if (workoutEditData != null){
            modalTitle = (workoutEditData.category == 'workout') ? 'Edit Exercise Schedule' : 'Edit Training';
            buttonText = 'Update';
        } else {
            modalTitle = (type == 'workout') ? 'Add Exercise Schedule' : 'Add Training';
            buttonText = 'Save';
        }

        let {
            formData,
            checkBoxOption,
            checkAll, 
            checkAllChecked,
            checkWeekDays,
            checkWeekEnds,
            errors
        } = this.state;
        
        var selectOptions = [];

        if (fitnessTimings != undefined) {
            var options = [];

            if (packageCategory == 'Sports' && type == 'training') {
                options = fitnessTimings.data['sports'];
                options = options.sort();
                options.forEach((option)=>{
                    selectOptions.push({label: option, value: option})
                });
            } else if (packageCategory == 'Sports' && type == 'workout'){
                options = fitnessTimings.data['general fitness_s'];
                options = options.sort();
                options.forEach((option)=>{
                    selectOptions.push({label: option, value: option})
                });
            } else if (packageCategory != 'Sports' && type == 'workout'){
                options = fitnessTimings.data['general fitness_ns'];
                options = options.sort();
                options.forEach((option)=>{
                    selectOptions.push({label: option, value: option})
                });
            }
        }

        var times = [];
        var interval = ["00", "15", "30", "45"];
        for (var i = 0; i < 24; i++) {
            var ampm = 'AM';
            var hour = i > 12 ? i - 12 : i;
            if (i > 11) {
                ampm = 'PM';
            }
            for (var j = 0; j < 4; j++) {
                var hours = hour < 10 ? `0${hour}` : hour;
                var time = hours + ":" + interval[j] + ' ' + ampm;
                times.push({ label: time, value: time });
            }
        }

        var indexedTimes =[]
        times.forEach((time,idx)=>{
            indexedTimes.push(Object.assign(time,{"id":idx}));
        });
               
        var timesOption = [];
        var concatTimes = indexedTimes.splice(16, indexedTimes.length).concat(indexedTimes);
        concatTimes.forEach(option => {
            for (var k = 0; k < interval.length; k++) {
                if ((option.label && option.value) == (`0:${interval[k]} AM`)) {
                    option.label = `12:${interval[k]} AM`;
                    option.value = `12:${interval[k]} AM`;
                }
            }
            timesOption.push(option);
        });

        var checkBoxes = [];
        var values = {
            id: '',
            label: '',
            value: '',
            checked:'',
            errors:''
        }
        checkBoxOption.forEach((day, idx) => {
            values = {
                id: day.id,
                label: day.label,
                value: day.value,
                checked: day.checked,
                errors: day.errors
            }
            checkBoxes.push(
                <CheckBox
                    key={idx}
                    values={values}
                    cls={day.value == 'Sunday'? 'end' : ''}
                    handleCheckBoxChange={self.handleCheckbox.bind(self)}
                />
            );
        });

        values = {
            id: 'check_all',
            label: checkAll == false ? 'Check All' : 'Uncheck All',
            value: checkAll,
            checked: checkAllChecked
        }

        const weekDayValues = {
            id: 'week_days',
            label: 'Weekdays',
            value: 'weekdays',
            checked: checkWeekDays
        }
        const weekEndValues = {
            id: 'week_ends',
            label: 'Weekends',
            value: 'weekends',
            checked: checkWeekEnds
        }
  
        return (
            <NutrifyReveal openCls={revealFormOpen} handleClose={this.setRevealClass} customWidth={"600px"}>
                <div className="w3-row">
                    <div className="w3-margin w3-center reveal-heading">
                        {modalTitle}
                    </div>
                    <div className="w3-col s12 m12">
                        <div className="w3-row w3-row-padding bottom-padding">
                            <div className="m4 s12 w3-col">
                                <label>Type</label>
                                <Select
                                    name="routine_type"
                                    id="routine_type"
                                    type="search"
                                    clearable={false}
                                    value={(formData.routine_type) ? formData.routine_type : ''}
                                    onChange={(selected) => this.handleSelect('routine_type', selected)}
                                    options={selectOptions}
                                    placeholder = {(formData.routine_type) ? formData.routine_type : 'Select..'}
                                />
                            </div>
                            <div className="m4 s12 w3-col">
                                <label>Start Time</label>
                                <Select
                                    name="start_time"
                                    id="start_time"
                                    type="search"
                                    clearable={false}
                                    placeholder={(formData.start_time) ? formData.start_time : 'Start time'}
                                    value={(formData.start_time) ? formData.start_time : ''}
                                    onChange={(selected) => this.handleSelect('start_time', selected)}
                                    options={timesOption}
                                    disabled={formData.routine_type ? false : true}                                
                                    className={errors.start_time ? "select-error" :""}
                                    matchPos="start"
                                />    
                            </div>
                            <div className="m4 s12 w3-col">
                                <label>End Time</label>
                                <Select
                                    name="end_time"
                                    id="end_time"
                                    type="search"
                                    placeholder={(formData.start_time) ? formData.end_time : 'End time'}
                                    clearable={false}
                                    value={(formData.end_time) ? formData.end_time : ''}
                                    onChange={(selected) => this.handleSelect('end_time', selected)}
                                    options={this.filterOptions(timesOption,concatTimes,formData.start_time)}  
                                    disabled={formData.start_time ? false : true}
                                    className={errors.end_time ? "select-error" :""}
                                    matchPos="start"
                                />
                            </div>
                        </div>
                        <div className="w3-row w3-row-padding">
                            <div className="s12 w3-col w3-margin-bottom">
                                <label>Days of {(type == 'workout')? "Exercise Schedule" : "Training"}</label>
                            </div>
                            <div className="s12 w3-col bottom-padding">
                                {checkBoxes}
                            </div>
                            <div className="w3-col s12">
                                <CheckBox values={values} handleCheckAllChange={self.toggleCheckAll.bind(self)}/>
                                <CheckBox values={weekDayValues} handleCheckWeekDaysChange={self.toggleCheckAll.bind(self)}/>
                                <CheckBox values={weekEndValues} handleCheckWeekEndsChange={self.toggleCheckAll.bind(self)} cls={'end'}/>
                            </div>
                        </div>
                        <div className="w3-col s12">
                            {buttonText == 'Update' ?
                                <button type="button" className="w3-button w3-padding-small w3-teal w3-right btn-space" onClick={this.setRevealClass.bind(this, '')}>
                                    Cancel
                                </button> : null
                            }
                            <button type="button" className="w3-button w3-padding-small nutrify-btn w3-right" onClick={this.handleSubmit.bind(this)}>
                                {buttonText}
                            </button>
                        </div>

                        <div className="w3-padding">
                            <span className="w3-small add-meal-note">Please click on Add {(type == 'workout')? "Exercise Schedule" : "Sport"} to enter mutiple timings</span>
                        </div>
                    </div>
                </div>
                <button className="w3-btn no-shadow w3-display-topright" onClick={this.setRevealClass.bind(this, '')} aria-label="Close modal" type="button">
                    <span aria-hidden="true" className="w3-xlarge">&times;</span>
                </button>
            </NutrifyReveal>

        )
    }
}


function mapStateToProps(state) {
    return {
        revealFormOpen: state.revealFormOpen,
        fitnessTimings: state.fitnessTimings,
        workoutEditData: state.workoutEditData,
        surveyWorkoutDetails: state.surveyWorkoutDetails
    };
}
export default connect(mapStateToProps, {
    fireRequest,
    getSurveyWorkoutDetails,
    getSurveyResponses
})(SurveyWorkoutManager);

