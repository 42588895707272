import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    getSurveyQuestions,
    getSurveyResponses,
    setData,
    getPackageDetails,
    getSurveyWorkoutDetails,
    getMealTimeDetails
} from "../../js/actions.js";
import QuestionList from './QuestionList';
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

class SurveyQuestions extends Component {
    constructor(args) {
        super(args);

        this.state = {
            questionCategoryMap: {},
            clientId:0,
            packageId:0,
            toggleCls:""
        };
    }

    clearData(){
        this.props.setData('packageDetail',null);
        this.props.setData('surveyResponses',null);
        this.props.setData('surveyWorkoutDetails',null);
    }

    reloadData(token){
        this.clearData();
        let {
            packageDetail,
            surveyQuestions,
            surveyWorkoutDetails
        } = this.props;
        let packageId = null;
        this.props.getSurveyResponses(token)
        .then(resp => {
            if (!packageDetail || !packageDetail.data ||
                 packageDetail.data.survey_token != token) {
                return this.props.getPackageDetails(0,token);
            } else {
                return packageDetail;
            }    
        }).then(resp => {
            if (surveyQuestions && surveyQuestions.data) {
                return surveyQuestions;
            }
            return this.props.getSurveyQuestions(token);
        }).then(resp => {
            const packageId = this.props.packageDetail.data.id;
            if (!surveyWorkoutDetails || !surveyWorkoutDetails.data ||
                surveyWorkoutDetails.data.package_id != packageId) {
                    return this.props.getSurveyWorkoutDetails(packageId);
            } else {
                return surveyWorkoutDetails;
            }
        }).catch(ex => {

        });
    }

    componentDidMount() { 
        const {packageDetail} = this.props;
        if (packageDetail && packageDetail.data.survey_token == this.props.token) {
            return;
        }
        this.reloadData(this.props.token); 
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token == this.props.token) { 
            return;
        }
        this.reloadData(nextProps.token);
    }
 
    getSurveyQuestionsMap() { 
        const categoryMap = {};
        if (this.props.surveyQuestions) {
            this.props.surveyQuestions.data.forEach( (item, idx) => {
                if (item == null) {
                    return;
                }
                if (item.category == 'DIETARY RECALL OLD' 
                    || item.category == 'SPORT OR EXERCISE TRAINING ROUTINE OLD'){
                    return;
                }
 
                if (!categoryMap[item.category]) {
                    categoryMap[item.category] = [];
                    categoryMap[item.category].push(item);
                } else {
                    categoryMap[item.category].push(item);
                }
            });
        }
        return categoryMap;
    }

    postDietaryRecall(){
        var self = this;
        let { mealList } = this.props;

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList
        });

        let mealData = {
            mealList: mealList,
            clientId: this.state.clientId,
            packageId: this.state.packageId ? this.state.packageId : this.props.packageDetail.data.id
        };
        
        $.ajax({
            type: 'POST',
            url: '/api/v1/survey/dietaryrecall/create', 
            data: JSON.stringify(mealData),
            contentType: "application/json",
            dataType: "json",
            success: (response) => {
                if (response == false) {
                    self.setState({
                        errors: response.data
                    });
                    return;
                }
                self.setState({
                    successMessage: "Meal List updated Successfully!"
                });
                this.props.getMealTimeDetails(this.props.packageDetail.data.id);
            }
        }); 
    }

    handleSave(evt) {
        evt.preventDefault();
        let { mealList } = this.props;
        var mealEmptyFlag = false;

        if (!this.props.token) {
            return
        }   

        if (mealList){
            var self = this;
            mealList.forEach( function(eachMeal, index, mealList){
                if (!eachMeal.mealId){
                    if (eachMeal.type == "" && eachMeal.description==""){
                        mealList.splice(index, 1);
                    } else if(eachMeal.type == "" || eachMeal.time == "" || eachMeal.description==""){
                        mealEmptyFlag = true;
                        eachMeal.isSaved = false;
                    } else{
                        eachMeal.isSaved = true;
                    }
                } else if (eachMeal.type == "" || eachMeal.time == "" || eachMeal.description==""){
                    mealEmptyFlag = true;
                    eachMeal.isSaved = false;
                } else {
                    eachMeal.isSaved = true;
                }
            });
            if (mealEmptyFlag){
                alert('Please update Dietary Recall section');
                return;
            }
            this.postDietaryRecall();
        }
        alert("Your data is saved. Please come back later to complete the Survey");
        hashHistory.push("/survey/" + this.props.token + "/view");
        this.props.getSurveyResponses(this.props.token);
        return false;
    }

    openAll(){
        this.setState({ toggleCls: "open-all" });
    }


    handleCompleted(evt) {
        evt.preventDefault();
        const {
            surveyResponses,
            mealList,
            surveyWorkoutDetails,
            token
        } = this.props;
        const self =this;
        if (!token) {
            return
        }   
        if (!mealList) {
            alert('Please update dietary recall section');
            return
        }
        let mealEmptyFlag = false;

        if (!surveyWorkoutDetails || !surveyWorkoutDetails.data || surveyWorkoutDetails.data.length === 0) {
            const status = surveyResponses.data.find(i => i.text_response === 'SPORT OR EXERCISE TRAINING ROUTINE');
            if ((status && status.workout === 'true') ||  (status && status.training === 'true')) {
                
            } else {
                alert(`Please update Sports or Exercise Training Routine Section. If you don't have any training/workout routine, pleace check the 'No Training/No Workout' button.`);
                return;
            }
        }

        mealList.forEach(function(eachMeal, index, mealList){
            if (!eachMeal.mealId){
                if (eachMeal.type == "" && eachMeal.description=="") {
                    mealList.splice(index, 1);
                } else if (eachMeal.type == "" || eachMeal.time == "" || eachMeal.description=="") {
                    mealEmptyFlag = true;
                    eachMeal.isSaved = false;
                } else {
                    eachMeal.isSaved = true;
                }
            } else if (eachMeal.type == "" || eachMeal.time == "" || eachMeal.description==""){
                mealEmptyFlag = true;
                eachMeal.isSaved = false;
            } else {
                eachMeal.isSaved = true;
            }
        });

        // if (workoutEmptyFlag) {
        //     alert('Please update Sport or Exercise Training Routine Section');
        //     return;
        // }
        
        if(mealEmptyFlag){
            alert('Please update Dietary Recall section');
            return;
        }
        this.postDietaryRecall();
        $.ajax({
            type: "POST",
            url: '/api/v1/survey/response/update-status',
            data: {
                survey_token: this.props.token
            },
            success: function (response) {
                if (response.success == true) {
                    hashHistory.push("/survey/" + this.props.token  + "/view");
                    this.props.getSurveyResponses(self.props.token);
                    alert("Congratulations!!! You have sucessfully completed the Nutrition Assessment Questionnaire. The details have been sent to your nutritionist who will get back to you in 2-3 days.");
                } else if (response.success == false) {
                    //Foundation.Accordion($('#survey-questions-accordion'));
                    // $('#survey-questions-accordion data-accordion data-multi-expand="true"').show();

                }
            }.bind(this),
            error: function (error) {
            }.bind(this)
        });
        return false;
    }

    toggleClass(id){
        this.setState({toggleCls:id})
    }

    render() {
        if(!this.props.packageDetail){
            return null;
        }
        let { packageDetail, surveyWorkoutDetails,getSurveyWorkoutDetails } 
        = this.props;

        if(packageDetail && packageDetail.data!=null){
            this.state.clientId = packageDetail.data.client_id;
            this.state.packageId = packageDetail.data.id;
        }
       
        var questionCategories = [];
        if (this.props.surveyResponses &&
                this.props.surveyResponses.success == false) {
            return <div className="w3-container w3-padding-64">
                <h3>Invalid assessment token</h3>
            </div>
        }

        const categoryMap = this.getSurveyQuestionsMap();
        var packageCategory = '';
        if(packageDetail != undefined && packageDetail.data != null){
            packageCategory = packageDetail.data.category;
        }

        var idx = 0;
        var questionTitle = "";
        for (var category in categoryMap) {
            idx++;
            var activeCls = '';
            if (idx == 1) {
                activeCls = 'is-active';
            }
            questionTitle = category.toUpperCase();
            
            if(packageCategory == 'Sports' && category == 'MEDICAL CONDITIONS' ||
                packageCategory == 'Sports' && category == 'SYMPTOMS'){
                continue;
            }

            if (packageCategory != 'Sports' && category == 'MEDICAL CONDITIONS_SPORTS' ||
                packageCategory != 'Sports' && category == 'INJURIES_SPORTS'||
                packageCategory != 'Sports' && category == 'SYMPTOMS_SPORTS') {
                continue;
            }

            questionTitle = questionTitle.replace("_SPORTS", "");

            questionCategories.push(
                <li key={idx} className="w3-block li-no-border">
                    <div onClick={this.toggleClass.bind(this,questionTitle)} className="no-decoration" style={{textAlign: 'center',color: 'ghostwhite',padding: '4px',backgroundColor: '#205081'}}>
                        <h5 className={`accordion-head ${(this.state.toggleCls == questionTitle) || (this.state.toggleCls == "open-all") ? 'active':''}`}>{questionTitle}  <span className="label w3-left w3-small"style={{padding: '2px',backgroundColor: '#205081'}}>{ categoryMap[category].length }</span></h5>
                    </div>
                    <div id={questionTitle} className={`w3-border ${(this.state.toggleCls == questionTitle ) || (this.state.toggleCls == "open-all")? 'w3-show':'w3-hide'}`} style={{backgroundColor: 'aliceblue'}}>
                        <QuestionList
                            questions={categoryMap[category]} 
                            responses={this.props.surveyResponses} 
                            token={this.props.token} 
                            packageDetail = {this.props.packageDetail}
                            openAll={(this.state.toggleCls == "open-all") ? "open-all" : ""}
                        />
                    </div>
                </li>
            );

        } 

        let progress = 0;
        if (this.props.progress != null) {
            progress = this.props.progress;
            if (this.props.surveyWorkoutDetails && this.props.surveyWorkoutDetails.data) {
                if (this.props.surveyWorkoutDetails.data.length>0) {
                    progress+=1;
                }
            }
            if (this.props.mealList) {
                progress+=1;
            }
        }
        
        if (progress  > 100) {
            progress = 100;
        }

        return (
            <div className="w3-row">
                <div className="w3-col s12">
                    <div className="w3-small" style={{backgroundColor: '#3b5998',padding: "0px",textAlign: "center",margin: "10px 0px", color:"#FFFFFF",fontWeight:"600"}}>
                        <div className="w3-container"
                            style={{width: `${progress}%`,padding: "0px",backgroundColor: '#249fbb'}}>
                            {progress}%
                        </div>
                    </div>
                </div>
                <form  onSubmit={this.handleCompleted.bind(this)}>
                    <div className="w3-col s12">    
                        <ul id='survey-questions-accordion' className="w3-row w3-ul">
                            {questionCategories}
                        </ul>  
                    </div>
                    <div className="w3-col s12 w3-margin-top w3-margin-bottom" >
                        <button  type="Submit" onClick={this.openAll.bind(this)} className="w3-button w3-small btn-success new-submit-btn">Submit</button>
                        <button type="button" onClick={this.handleSave.bind(this)} className="w3-button w3-small nutrify-btn new-save-btn" >Save for Now</button>
                    </div> 
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        surveyQuestions: state.surveyQuestions,
        surveyResponses: state.surveyResponses,
        progress: state.surveyProgress,
        packageDetail: state.packageDetail,
        surveyWorkoutDetails: state.surveyWorkoutDetails,
        mealList : state.surveyMealList
    };
}
export default connect(mapStateToProps, {
    getSurveyQuestions,
    getSurveyResponses,
    getPackageDetails,
    setData,
    getMealTimeDetails,
    getSurveyWorkoutDetails
})(SurveyQuestions);

