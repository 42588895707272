import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingIndicator  } from "../Common/HelperComponents.js";
import {
    getMealTimeDetails,
    fireRequest, 
    getPackageDetails
} from "../../js/actions.js";
import AddMealTimeRow from "./AddMealTimeRow";
import moment from 'moment'; 
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

class MealSection extends Component {
   
    componentDidMount() {
        if (!this.props.categoryTimings) {
            this.props.fireRequest('categoryTimings',[]);
        }
        if (this.props.packageDetail){
            this.props.getMealTimeDetails(this.props.packageDetail.data.id); 
        }
    }

    componentWillReceiveProps(newProps){
       
        if(!newProps.mealTimeDetails){
            return;
        }
        if(this.props.mealTimeDetails == newProps.mealTimeDetails){
            return;
        }


        if (this.props.packageDetail != newProps.packageDetail) { 
            this.props.getMealTimeDetails(newProps.packageDetail.data.id);
        }

        const mealTimeList = newProps.mealTimeDetails.data;
        const mealList = mealTimeList.map(item => {
            return {
                mealId:item.id,
                type:item.meal_type,
                time: moment(item.meal_time, "HH:mm:ss").format("hh:mm A"),
                description:item.meal_description,
                mandatory:false,
                isSaved:true
            }
        });

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList
        });
    }
 

    addMeal(){
        const { mealList } = this.props;
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList.concat([{
                type:'',
                time:"11:00 PM",
                description:'',
                isSaved:false
            }])
        });
    }

    saveAllMeal(){
        let { mealList } = this.props;
        const packageId = this.props.packageDetail.data.id;
        const clientId = this.props.packageDetail.data.client_id;
        var self = this;
        var emptyFlag = false;
        if (!mealList){
            alert("Please fill dietary information");
            return;
        }
        const dupeCheck = mealList.some((eachMeal, idx) => (
            mealList.filter((item,pos) => pos != idx )
            .some((meal )=> (meal.time == eachMeal.time && meal.type == eachMeal.type))
        ));
        //console.log("dupe check", dupeCheck);
        if (dupeCheck) {
            alert("Duplicate entry exists with same Time and Type!!");
            return;
        }
        mealList.forEach((eachMeal, index, mealList) => {
            if (!eachMeal.mealId){
                if(eachMeal.type == "" && eachMeal.description==""){
                    mealList.splice(index, 1);
                } else if(eachMeal.type == "" || eachMeal.time == "" || eachMeal.description==""){
                    emptyFlag = true;
                    eachMeal.isSaved = false;
                } else{
                    eachMeal.isSaved = true;
                }
            }else if(eachMeal.type == "" || eachMeal.time == "" || eachMeal.description==""){
                emptyFlag = true;
                eachMeal.isSaved = false;
            }else{
                eachMeal.isSaved = true;
            }
        });
        // if(emptyFlag){
        //     alert("Please fill all fields to save dietary information");
        //     return;
        // }
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'surveyMealList',
            value: mealList
        });
        let mealData = {
            mealList: mealList,
            clientId: clientId,
            packageId: packageId
        };
        $.ajax({
            method: 'POST',
            url: '/api/v1/survey/dietaryrecall/create', 
            data: JSON.stringify(mealData),
            contentType: "application/json",
            dataType: "json",
        }).done(function (resp) {
            if(resp.success == false){
                self.setState({
                    errors: resp.data
                });
                return;
            }
            self.setState({
                successMessage: "Meal List updated Successfully!"
            });
            self.props.getMealTimeDetails(packageId);
        });
        if(emptyFlag){
            alert("Please fill all fields in dietary information");
            return;
        }
    }

    render() { 
        let { mealTimeDetails, 
            mealList, 
            packageDetail, 
            categoryTimings, 
        } = this.props;
     
 
        if (!categoryTimings || !categoryTimings.data){
            return <LoadingIndicator />
        }

        if (!packageDetail || !packageDetail.data){
            return <LoadingIndicator />
        }

        var mealTimeListTitle = [];
        var mealTimeListRow = [];
        var mealTypeMan =[];
        var mealTypeOpt =[];
        var mandatory =[];


       let packageId = packageDetail.data.id,
       clientId = packageDetail.data.client_id, 
       category = packageDetail.data.category;

        if (category !='Sports'){
            category = 'NonSports';
            mealTypeMan = categoryTimings.data.nonsports_m;
            mealTypeOpt = categoryTimings.data.nonsports_nm;
        } else {
            mealTypeMan = categoryTimings.data.sports_m;
            mealTypeOpt = categoryTimings.data.sports_nm;
        }
        var timeIdx = "";
 

       const timeMap = {
           "On Waking Up" : "04:00 AM", 
           "Breakfast" : "06:00 AM", 
           "Morning Snack":  "09:00 AM", 
           "Lunch" :  "12:00 PM", 
           "Evening Snack" : "04:00 PM", 
           "Dinner" : "07:00 PM" 
       };
       

        mandatory =  mealTypeMan.map(item => ({
             type:item,
             time: timeMap[item] ? timeMap[item]:'',
             mandatory:true
        }));

        mandatory.reverse().forEach( man => {
            let idx = mealList.findIndex(item => item.type == man.type && item.mandatory == true);
            if (idx < 0){
                idx = mealList.findIndex(item => item.type == man.type );             
            }
            if (idx < 0){
                mealList.unshift(
                    {
                        type:man.type,
                        time:man.time,
                        description: '',
                        mandatory: true,
                        isSaved: false
                    },
                );
               
            } else {
                mealList[idx].mandatory = true;  
            }
        });  

        const nonManMap = {
           "Pre-Training": "11:57 PM", 
           "During Training" : "11:58 PM", 
           "Post-Training" : "11:59 PM",
        };

        const res = mealList.sort((a,b) => { 
            let aTime = a.time;
            let bTime = b.time;
            if (aTime == '') {
                aTime = nonManMap[a.type] ? nonManMap[a.type]: '11:59 PM';
            }
            if (bTime == '') {
                bTime = nonManMap[b.type] ? nonManMap[b.type] : '11:59 PM';
            }
             
            const res = moment("2000-01-01 "+aTime).diff(moment('2000-01-01 '+bTime),'seconds');
            return res;
        });
        for(var idx=0; idx<mealList.length ; idx++){
            mealTimeListRow.push(
                <AddMealTimeRow 
                    //saveAllMeal = {this.saveAllMeal.bind(this)}
                    key ={'key_'+idx}
                    category={category}
                    mealList={mealList}
                    idx = {idx}
                    timingList ={mealTypeOpt}
                    packageId = {packageId} 
                    clientId = {clientId}
                />
            );
        }

        return (
            <div>
                <div className="w3-col m12 s12">
                    <div className="">
                        <table className="mealtime-survey w3-table w3-table-all">
                            <thead> 
                                <tr>
                                    <th>Type</th>
                                    <th>Time*</th>
                                    <th>Meal Description* <br/>
                                        <span className="w3-small"> (Weekly meals with approx quantities)</span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="dietaryRecall" style={{whiteSpace:"pre-wrap"}}>
                                {mealTimeListRow}
                            </tbody>
                        </table>
                    </div>
                    <div className="w3-margin-top w3-margin-bottom">
                        <button type="button" onClick={this.addMeal.bind(this)} className="w3-button w3-padding-small nutrify-btn">Add Meal</button>
                        <button type="button" onClick={this.saveAllMeal.bind(this)} className="w3-button w3-padding-small nutrify-btn w3-right">Save Dietary Info</button>
                    </div>
                    <div className="w3-margin-bottom">
                        <span className="w3-small add-meal-note">Please Click on Add Meal to add your workout and additional meals if applicable.</span>
                    </div>
                    <div className="w3-margin-bottom">
                    <span className="w3-small dietary-save-reminder">Please Click on 'Save Dietary Info' Button to save the items before proceeding to another section.</span>
                </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        mealTimeDetails: state.mealTimeDetails, 
        mealList : state.surveyMealList,
        categoryTimings : state.categoryTimings,
    }
}

export default connect(mapStateToProps, { 
    fireRequest,
    getMealTimeDetails,
    getPackageDetails
})(MealSection);