import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import HTML5Backend from 'react-dnd-html5-backend';
import { getPackageDetails, getdietPlanListbyPackageId, getDietPlanDayDetails } from "../../js/actions.js";
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import { FormTextField, FormTextarea, LoadingIndicator } from "../Common/HelperComponents.js";
import { connect } from 'react-redux';
import moment from 'moment';
import { DNDWrapper } from "../utility/utility.js";
import $ from 'jquery'; 
import gNutrifyStore from '../../js/clientStore';

class _Meal extends Component {
    constructor() {
        super();
        this.state = {
            showFoodItems: true,
            hideTime : false,
        }
        this.updateTimer = null;
        this.renderRequestedAt = null;
    }

    handleToggle() {
        this.setState({
            showFoodItems: !this.state.showFoodItems
        })
    }

    handleDelete(mId, evt) {
        evt.preventDefault();
        var state = gNutrifyStore.getState();
        var work = state.currentWorkspace;
        var mealId = this.props.meal.meal_id ? this.props.meal.meal_id : mId;
        var self = this;

        if (!work.mealMap) {
            work.mealMap = {}
        }

        if (!mealId) {
            alert('Meal Id missing, please try again');
            return false;
        }

        delete work.mealMap[this.props.meal.mealKey];
        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/plandaymeal/delete',
            data: {
                mealId: mealId
            }
        }).done(function (resp) {
            if (resp.success == false) {
                alert(resp.data.meal_id);
            }

            if (resp.data.total_meal_count != Object.keys(work.mealMap).length) {
                self.props.getDietPlanDayDetails(work.planId);
            }
        });

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentWorkspace',
            value: Object.assign({}, work)
        });
    }

    handleEdit() {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: this.props.meal
        });
    }

    handleHideTime(){
        this.setState({
            hideTime : !this.state.hideTime,
        })
    }

    
    

    render() {

        const { connectDragSource, isDragging } = this.props;
        var items = [];
        var self = this;
        var conflictCount = 0;
        var conflictClass = '';
        var mealId = this.props.meal.meal_id;
        let mealItems = this.props.meal.items;
        mealItems.sort((a, b) => parseInt(a.order,10) - parseInt(b.order,10)); 
        const parentItems = mealItems.filter((item) => (!item.ref_id));
        parentItems.forEach(function (item, idx) {
            var clsConflict = '';
            let mealRow = [];
            if (item.is_conflict == 1) {
                conflictCount++;
                conflictClass = 'conflict';
                clsConflict = 'txt-conflict';
            }
            mealRow = [<div>{`${item.label} - ${item.quantity * item.value.output} ${item.value.uom}`}</div>];

            let style = {};
            mealItems.forEach(function (mealItem, mealIdx) {
                if (mealItem.ref_id &&
                    (mealItem.ref_id == item.food_item_id)) {
                    mealRow.push(
                        <div>
                            <span><b>Or</b></span>
                            <span>{`  ${mealItem.label}  ${mealItem.quantity * mealItem.value.output} ${mealItem.value.uom}`}</span>
                        </div>
                    );
                }
            })

            items.push(
                <li style={style} key={self.props.meal.mealKey + "_" + idx} className={clsConflict}>
                    {mealRow}
                </li>
            )
        })

        var foodItems = <ol className="ol-food-items">
            {items}    
        </ol>

        var toggle = "fa fa-chevron-up";
        if (this.state.showFoodItems == false) {
            toggle = "fa fa-chevron-down";
            foodItems = null;
        }
        toggle = <span className={toggle}></span>

        var timeMeal = this.props.meal.time;
        var str = " am";
        var hour = parseInt(this.props.meal.hh);
        if (hour > 12) {
            hour = hour - 12;
            str = " pm";
            if (hour >= 12) {
                hour -= 12;
                str = " am";
            }
        }
        if (hour == 12) {
            str = " pm";
        }
        timeMeal = hour + ":" + this.props.meal.mm + str;

        var clsHide = '';
        if (this.props.meal.temporary) {
            clsHide = 'w3-hide';
        }
        const display = this.state.hideTime ? 'display-none' : 'display-block' ;

        return connectDragSource(
            <div className={"day-meal-card " + conflictClass}>
                <div className="w3-row top-header clearfix" >
                    {/* <em><div className={`w3-left title ${display}`}>{timeMeal + " (" + this.props.meal.hypothetical_time + ")"}</div></em> */}
                    <div className={`w3-left title ${display}`}><em>{"("+timeMeal+")"}</em> { this.props.meal.hypothetical_time }</div>

                    <div className={"button-groups w3-tiny w3-right " + clsHide} >
                        <button className="w3-button w3-white w3-border w3-border-blue w3-small w3-padding-small " onClick={this.handleEdit.bind(this)} >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button className="w3-button w3-white w3-border w3-border-blue w3-small w3-padding-small " onClick={this.handleToggle.bind(this)} > {toggle} </button>
                        <button className="w3-button w3-white w3-border w3-border-blue w3-small w3-padding-small " onClick={this.handleDelete.bind(this, mealId)}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                {foodItems}
            </div>
        );
    }
}

const Meal = connect(() => { return {} }, {
    getDietPlanDayDetails
})(_Meal);


class MealTime extends Component {
    constructor(arg) {
        super(arg);
        this.state = {
            activeCls: ''
        }
    }
    handleMealAdd(day) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: {
                openCls: 'open',
                items: [],
                day: this.props.day,
                time: this.props.time,
                strTime: this.props.timeVal,
                mealKey: this.props.mealKey,
                timeIdx: this.props.timeIdx,
                hypothetical_time: "Custom",
                hh: this.props.timeVal.slice(0, 2),
                mm: this.props.timeVal.slice(3)
            }
        })
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.isOver && nextProps.isOver) {
            this.state.activeCls = 'active';
        }

        if (this.props.isOver && !nextProps.isOver) {
            this.state.activeCls = '';
        }

        if (this.props.isOverCurrent && !nextProps.isOverCurrent) {
            // You can be more specific and track enter/leave
            // shallowly, not including nested targets
        }
    }
    render() {
        const { connectDropTarget } = this.props;
        var msg = "Click to add meal";
        if (this.state.activeCls != '') {
            msg = 'Drop to add meal';
        }
        var children = this.props.children;
        if (this.props.printModeCls == 'print-mode' || this.props.viewPlanId == "create") {
            children = this.props.children;
        } else if (!children || !children.length == 0) {
            children = <div className={"day-meal-placeholder " + this.state.activeCls} onClick={this.handleMealAdd.bind(this)} >
                <span  >{this.props.time}</span>
                <span className="label secondary">{msg}</span>
            </div>
        }
        return connectDropTarget(
            <td className={"meal-table-cell " + this.props.clsName}>
                {children}
            </td>
        );
    }
}

class CopyDaySelect extends Component {

    handleDayCopy(target, evt) {
        var sourceDay = evt.target.value;
        var map = this.props.workspace.mealMap;
        var newMap = Object.assign({}, map);
        $.ajax({
            url: '/api/v1/diet/plan/copy-day',
            method: 'POST',
            data: {
                planId: this.props.planId,
                sourceDay: sourceDay,
                targetDay: target
            }
        }).done(function (resp) {
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'currentWorkspace',
                value: resp.data
            });
        });

    }

    render() {

        var list = this.props.list;
        var target = this.props.target;
        if (list.length == 0) {
            return null;
        }
        var options = list.map(function (item) {
            return <option value={item.day}>{item.str}</option>
        })
        return (
            <div>
                <div className='copy-plan-box'>
                    <select onChange={this.handleDayCopy.bind(this, target)}
                        className="w3-select w3-border">
                        <option >-Copy Day-</option>
                        {options}
                    </select>
                </div>
            </div>)
    }
}



class EditableDayHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayDesc: props.dayString,
            editMode: false
        }
    }

    handleChange(e) {
        this.setState({
            dayDesc: e.target.value
        });
    }

    handleCancel(idx) {
        this.setState({
            editMode: false
        });
    }
    handleDescrEdit(idx) {
        this.setState({
            editMode: true
        });
    }

    handleDayDescrSave() {
        if (!this.state.dayDesc) {
            alert("Please enter a Day Description to save.");
            return;
        }
        const { dayId, dayPos } = this.props;
        const state = gNutrifyStore.getState();
        const work = state.currentWorkspace;
        const packageId = work.packageId;
        const planId = work.planId; 
        const description = this.state.dayDesc 
        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/planday/edit-day',
            data: {
                day: dayPos, 
                day_description: description,
                diet_plan_id: planId
            }
        }).done((resp) => {

            work.dayMap = Object.assign({},resp.data);
            const w  = Object.assign({}, work);
            gNutrifyStore.dispatch({
                type: 'SET_DATA',
                key: 'currentWorkspace',
                value: w
            });
            this.setState({editMode:false});
        });
    }

    handleDescrEdit() {
        this.setState({ editMode: true });
    }

    render() {
        const { editMode, dayDesc } = this.state;
        const { dayString ,editable } = this.props;
        return <div >

            {!editMode && <div>
                <span>{dayString}</span>
                {editable && <button className={"w3-button w3-tiny day-descr-edit "}
                    type="button" onClick={this.handleDescrEdit.bind(this)}>
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>}
            </div>}
            {editMode && <form onSubmit={this.handleDayDescrSave.bind(this)}>
                <input
                    type="text"
                    value={this.state.dayDesc}
                    placeholder={this.props.dayString}
                    id="day_title"
                    onChange={this.handleChange.bind(this)}
                    className="w3-input w3-border w3-small"
                />
                <div className="edit-desc-spacing w3-right-align">
                    <button type="button" onClick={this.handleCancel.bind(this)}
                        className="w3-button w3-tiny w3-teal edit-desc-button">
                        CANCEL
                        </button>
                    <button type="submit" className="w3-button w3-tiny w3-blue edit-desc-button">
                        OK
                        </button>
                </div>
            </form>}
        </div>
    }
}


// function mapCopyDayStateToProps(state) {
//     return { 
//         // list:state.copyDayList
//     };
// }
// var CopyDaySelect = connect(mapCopyDayStateToProps)(_CopyDaySelect);

class Board extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packageId: null,
            formData: {},
            errors: {},
            scrollColumnHeight: "0px",
            headerTop: 0,
            dayDescrMap: [],
            editDayNameMap: [],
            hideCarb : false,

        };
    }


    resizeWorkspace() {
        const hh = $("#app-top-header").height();
        const dh = window.innerHeight;
        const height = (dh - (hh + 20));
        this.setState({
            scrollColumnHeight: height + "px"
        })

    }

    componentDidMount() {
        setTimeout(() => {
            this.resizeWorkspace();
        }, 2000)
        $(window).resize(() => {
            this.resizeWorkspace();
        });

        this.resetWorkspace();
        this.props.getDietPlanDayDetails(this.props.planId);
    }


    resetWorkspace() {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentWorkspace',
            value: {
                totalDays: 0,
                mealMap: {
                }
            }
        });
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.planId != this.props.planId) {
            this.resetWorkspace();
            this.props.getDietPlanDayDetails(nextProps.planId);
        }
        if (nextProps.workspace.plan && nextProps.workspace.plan.id != this.state.formData.id) {
            this.state.formData = Object.assign(nextProps.workspace.plan, {})
        }
    }


    handleDeleteDay(day, evt) {
        evt.preventDefault();
        if (!window.confirm("Are you sure, you want to delete this Day from the Nutrition Plan?")) {
            return;
        }
        var state = gNutrifyStore.getState();
        var work = state.currentWorkspace;
        var packageId = work.packageId;
        var planId = work.planId;
        var self = this;
        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/planday/delete',
            data: {
                day: day,
                diet_plan_id: planId,
                package_id: packageId
            }
        }).done(function (resp) {
            self.props.getDietPlanDayDetails(planId);
        });
    }

    // componentDidUpdate(){
    //     if(this.props.onClick){
    //         this.props.onClick(this.state);
    //     }
    // }

    handleHide(day,evt){
        evt.preventDefault();
        this.setState({
            hideCarb : !this.state.hideCarb
        }, ()=>{
            if(this.props.parentCall){
                this.props.parentCall(this.state);
            }
        }
        )
    }



    addDayToWorkspace() {
        var work = Object.assign({}, this.props.workspace);
        work.totalDays = work.totalDays + 1;

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentWorkspace',
            value: work
        })
    }



    getDayFromStartDay(pos) {
        var dayStr = '';
        if (this.props.workspace.plan.day_format == 'day_numbers') {
            return "Day " + (pos + 1);
        } else if (this.props.workspace.plan.day_format == 'week_day') {
            var startDay = moment(this.props.workspace.plan.start_date).weekday();
            var list = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            for (var idx = 0; idx <= 7; idx++) {
                if (startDay == idx) {
                    return list[(idx + pos) % list.length];
                }
            }
        } else if (this.props.workspace.plan.day_format == 'custom') {
            if (this.props.workspace.dayMap && this.props.workspace.dayMap[pos]) {
                return this.props.workspace.dayMap[pos].dayDescr;
            }
            const num = pos + 1;
            return "Option " + num;
        }
    }
    hideDayColum(evt, day, x, y, z) {
        $(".col_day_" + evt).hide();
    }
    renderDays(viewPlanId) {

        let days = [];
        var copyDays = [];
        var totalPlanCal = 0;
        var totalPlanCarb = 0;
        var totalPlanProt = 0;
        var totalPlanFat = 0;
        var self = this;
        let showEditButton = false;
        const { mealMap, dayMap, plan } = this.props.workspace;
        if (plan.day_format == 'custom') {
            showEditButton = true;
        }
        var total = 0;
        if (this.props.workspace.totalDays > 0) {
            total = this.props.workspace.totalDays;
        }
        var topRow = [];
        var dayStats = [];
        var dayTimeSlotEnd = 112;
        var btnGroup = '';

        var deleteDayCls = '';
        if (this.props.printModeCls == 'print-mode' || viewPlanId == 'create') {
            deleteDayCls = 'w3-hide';
            btnGroup = 'print-mode';
        }

        for (var idx = 16; idx < dayTimeSlotEnd; idx++) {
            var times = [];
            var rowHasMeal = false;
            for (var day = 0; day < total; day++) {

                var totalDayCal = 0;
                var totalDayCarb = 0;
                var totalDayProt = 0;
                var totalDayFat = 0;
                var mealCount = 0;

                if (dayStats.length - 1 < total) {
                    dayStats.push({
                        totalDayCal: 0,
                        totalDayCarb: 0,
                        totalDayProt: 0,
                        totalDayFat: 0,
                        mealCount: 0
                    });
                }

                var firstMeal = null;
                var intVal = Math.floor(idx / 4);
                var hour = intVal;

                var strVal = intVal;
                if (intVal > 12) {
                    intVal -= 12;
                    if (hour >= 24) {
                        intVal -= 12;
                        strVal -= 24;
                    }
                }

                var str = intVal;
                var tm = idx % 4;

                if (tm == 1) {
                    strVal += ":15";
                    str += ":15";
                } else if (tm == 2) {
                    strVal += ":30";
                    str += ":30";
                } else if (tm == 3) {
                    strVal += ":45";
                    str += ":45";
                } else {
                    strVal += ":00";
                    str += ":00";
                }

                if (hour < 12) {
                    str += " am";
                }
                else if (hour >= 24) {
                    str += " am";
                }
                else {
                    str += " pm";
                }
                if (str.length < 8) {
                    str = "0" + str;
                }
                if (strVal.length < 5) {
                    strVal = "0" + strVal;
                }

                var meal = null;
                var key = "day_" + day + "_time_" + strVal;
                var totalMealCal = 0;
                var totalMealCarb = 0;
                var totalMealProt = 0;
                var totalMealFat = 0;
                if (mealMap[key] != undefined) {
                    var meal = mealMap[key];
                    meal.items.forEach(function (item) {
                        if (!item.ref_id) {
                            var itemCal = item.value.qty_calories * item.quantity;
                            var itemCarb = item.value.qty_carbohydrate * item.quantity;
                            var itemProt = item.value.qty_protein * item.quantity;
                            var itemFat = item.value.qty_fat * item.quantity;
                            totalMealCal += itemCal;
                            totalMealCarb += itemCarb;
                            totalMealProt += itemProt;
                            totalMealFat += itemFat;
                        }
                    });

                    dayStats[day].totalDayCarb += totalMealCarb;
                    dayStats[day].totalDayCal += totalMealCal;
                    dayStats[day].totalDayProt += totalMealProt;
                    dayStats[day].totalDayFat += totalMealFat;
                    dayStats[day].mealCount = dayStats[day].mealCount + 1;
                    rowHasMeal = true;

                    meal = <DSMeal
                        meal={meal}
                        key={"meal" + key}
                        day={day}
                        timeIdx={idx}
                        time={str}
                        printModeCls={self.props.printModeCls}
                        timeVal={strVal}
                        mealKey={key} />
                    firstMeal = meal;
                }

                times.push(
                    <DropMealTime
                        clsName={" col_day_" + day}
                        printModeCls={self.props.printModeCls}
                        day={day}
                        viewPlanId={viewPlanId}
                        mealKey={key}
                        timeIdx={idx}
                        time={str}
                        timeVal={strVal}
                        key={"day_" + day + "_" + idx}>
                        {meal}
                    </DropMealTime>
                );
                if (idx == dayTimeSlotEnd - 1) {
                    //end of for loop to show time slots

                    totalPlanCal += dayStats[day].totalDayCal;
                    totalPlanCarb += dayStats[day].totalDayCarb;
                    totalPlanProt += dayStats[day].totalDayProt;
                    totalPlanFat += dayStats[day].totalDayFat;

                    var dayString = this.getDayFromStartDay(day);
                    var topContent = null;
                    var carbPercent = 0;
                    var protPercent = 0;
                    var fatPercent = 0;
                    var hideBtn = null;
                    const content = this.state.hideCarb ? 'display-none' : 'display-block' ;
                    //if (dayStats[day].totalDayCal > 0) {
                    if (dayStats[day].mealCount > 0) {
                        if (dayStats[day].totalDayCarb > 0) {
                            carbPercent = Math.floor(dayStats[day].totalDayCarb * 400 / dayStats[day].totalDayCal);
                        }
                        if (dayStats[day].totalDayProt > 0) {
                            protPercent = Math.floor(dayStats[day].totalDayProt * 400 / dayStats[day].totalDayCal);
                        }
                        if (dayStats[day].totalDayFat > 0) {
                            fatPercent = Math.floor(dayStats[day].totalDayFat * 900 / dayStats[day].totalDayCal);
                        }
                        topContent = <div className="day-top-content w3-right">
                            Total Calories: {Math.floor(dayStats[day].totalDayCal)} kcal<br></br>
                            Total Carbs: {Math.floor(dayStats[day].totalDayCarb)} g ({carbPercent}%)<br></br>
                            Total Protein: {Math.floor(dayStats[day].totalDayProt)} g ({protPercent}%)<br></br>
                            Total Fat: {Math.floor(dayStats[day].totalDayFat)} g ({fatPercent}%)
                            </div>
                        copyDays.push({
                            day: day,
                            str: (day + 1) + "." + dayString
                        });
                    } else {
                        showEditButton = false;
                        topContent =
                            <CopyDaySelect
                                list={copyDays}
                                planId={this.props.planId}
                                workspace={this.props.workspace}
                                target={day}
                            /> 
                        // if (self.props.printModeCls == 'print-mode' || viewPlanId == 'create') {
                        //     hideBtn = <button className="w3-button w3-light-grey w3-border w3-border-blue w3-small  w3-right w3-padding-small" onClick={this.hideDayColum.bind(this, day)}>hide</button>
                        // }
                    } 
                    var disable = dayStats[day].mealCount == 0 ? "disabled" : "";
                    topRow.push(
                        <th className={"meal-table-cell top col_day_" + day}>
                            <div className="day-meal-column-header">
                                <div className="diet-plan-table-thead">
                                    <div className="w3-display-container">
                                        <EditableDayHeader
                                            getDietPlanDayDetails={this.props.getDietPlanDayDetails}
                                            dayPos={day}
                                            dayString={dayString} 
                                            editable={showEditButton  } />
                                     

                                        <button className={"w3-button w3-light-grey w3-display-topright w3-border-blue w3-small w3-padding-small day-delete-button " + deleteDayCls}
                                            disabled={disable}
                                            onClick={this.handleDeleteDay.bind(this, day)}>
                                            <i className="fa fa-times w3-large" style={{color:"darkgray"}} aria-hidden="true" ></i>

                                            {/* <i className="ion-android-close w3-small" /> */}
                                        </button>

                                        {/* <button onClick={this.handleHide.bind(this,day)} className="w3-button w3-light-grey w3-left w3-display-topleft w3-small w3-padding-small">
                                            {this.state.hideCarb ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                            : <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                        }
                                        </button> */}
                                    </div>
                                    {hideBtn}
                                </div>
                                <div className={content}>
                                    {topContent}
                                </div>
                                
                            </div>
                        </th>)
                }
            }
            if (self.props.printModeCls == 'print-mode') {
                if (rowHasMeal) {
                    days.push(<tr key={"day_" + idx} >{times}</tr>)
                }
            } else {
                days.push(<tr key={"day_" + idx} >{times}</tr>)
            }
        }
        // days.unshift(<tr id="diet-plan-table-top-row">{topRow}</tr>);
        return <table className={"meal-table " + btnGroup}
            cellSpacing="0" >
            <thead id="diet-plan-table-top-row" style={{ top: this.state.headerTop + "px" }}>
                <tr>{topRow}</tr>
            </thead>
            <tbody id="diet-plan-table">{days}</tbody>
        </table>;
    }

    scrollHandler(e) {

        if (this.state.headerTop == this.refs.plannerTable.scrollTop) {
            return;
        }

        this.renderRequestedAt = new Date().getTime();
        if (this.updateTimer) {
            return;
        }

        this.setState({
            headerTop: -100
        });

        this.updateTimer = setInterval(() => {
            if (this.renderRequestedAt + 100 > new Date().getTime()) {
                return;
            }
            this.setState({
                headerTop: this.refs.plannerTable.scrollTop
            }, () => {
                clearInterval(this.updateTimer);
                this.updateTimer = null;
            })
        }, 100)

    }


    render() {
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var viewPlanId = this.props.viewPlanId;

        var verticalScroll = "vertical-scroll";
        if (isEdge || isEdge) {
            verticalScroll = "";
        }



        var packageId = this.props.workspace.packageId;
        var planId = this.props.workspace.planId;


        if (!this.props.workspace.plan) {
            return <LoadingIndicator />
        }
        return (
            <div className="drag-drop-container">
                <div className={"day-column w3-responsive"} id="day-column" style={{ height: this.state.scrollColumnHeight }}
                    ref="plannerTable" onScroll={this.scrollHandler.bind(this)} >
                    {this.renderDays(viewPlanId)}
                    <div className="day-meal-column">
                        <div className="s12">
                            <button onClick={this.addDayToWorkspace.bind(this)} className="w3-button w3-white w3-border w3-border-blue w3-block " >Add a Day</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Board.defaultProps = {
    mealMap: {}
};

const DDIdentifier = 'A';

var DSMeal = DragSource(
    DDIdentifier, {
        beginDrag(props) {
            var obj = {
                meal: props.meal
            }
            return obj;
        }
    },
    function (connect, monitor) {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        }
    }
)(Meal);

const DDropTarget = {
    canDrop(props, monitor) {
        if (!props.children || props.children.length == 0) {
            return true;
        }
    },
    drop(props, monitor) {

        var item = monitor.getItem(); 
        var meal = JSON.parse(JSON.stringify(item.meal));
        meal.mealKey = props.mealKey;
        meal.time = props.time;
        meal.day = props.day;
        meal.strTime = props.timeVal;
        meal.timeIdx = props.timeIdx;
        meal.timeVal = props.timeVal;
        meal.hh = props.timeVal.slice(0, 2);
        meal.mm = props.timeVal.slice(3);
        delete meal.meal_id;

        var state = gNutrifyStore.getState();
        var work = state.currentWorkspace;
        if (!work.mealMap) {
            work.mealMap = {}
        }

        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'currentMeal',
            value: meal
        });
    }
};

var DropMealTime = DropTarget(
    DDIdentifier, DDropTarget, function (connect, monitor) {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
        }
    }
)(MealTime);


var DDCBoard = DNDWrapper(Board)

function mapStateToProps(state) {

    return {
        workspace: state.currentWorkspace,
        printModeCls: state.printModeCls,
        planEditToggleClass: state.planEditToggleClass,
        planDeleteConfirmClass: state.planDeleteConfirmClass
    };
}
export default connect(mapStateToProps, {
    getPackageDetails,
    getdietPlanListbyPackageId,
    getDietPlanDayDetails
})(DDCBoard);

