import React, { Component } from 'react';


export default class Footer extends Component {

    render() {
        return (
            <div className="w3-row footer">
                <div className="s12 w3-col w3-margin-left">
                	@NutrifyMyDiet
                </div>
            </div>
        )
    }
}