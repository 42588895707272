import React, { Component } from 'react';
import { Link, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    getDietPlanlist,
    getDietPlanPackageProfileList,
    getPaginatedList,
    fireRequest,
} from "../../js/actions.js";
// import { FormSelectField } from "../Common/HelperComponents.js";
import { NavigationSwitcher, FilterList, LoadingIndicator, PaginationSwitcher } from "../Common/HelperComponents.js";
import moment from 'moment';
import $ from 'jquery'; 

class SortableTh extends Component {

    constructor(args) {
        super(args);
        this.state = {
            direction: null,
            icon: "",
        };
        this.handleSort = this.handleSort.bind(this);
    }
    handleSort(evt) {
        if (this.state.direction == null || this.state.direction == 'desc') {
            this.setState({ direction: 'asc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-up-b"></i></div> });
        } else {
            this.setState({ direction: 'desc', icon: <div className="sort-indicator" style={{ paddingTop: '5px' }}><i className="icon ion-arrow-down-b"></i></div> });
        }
        this.props.onSort(this.props.title, this.state.direction);
    }
    render() {
        var sortBtn = <div className="sort-indicator"><i className="icon ion-arrow-up-b"></i><i className="icon ion-arrow-down-b"></i></div>;
        if (this.props.title != this.props.current) {
            this.state.icon = sortBtn;
        }
        return <th className="sortable-th" onClick={e => this.handleSort()} >
            <div className="sort-row">
                {this.props.title}
                {this.state.icon}
            </div></th>
    }
}


class DietPlanListRow extends Component {

    constructor(props){
        super(props);
    }


    render() {
        var url = "/workspace/" + this.props.item.package_id + "/" + this.props.item.id;
        return (
            <tr>
                <td>{this.props.item.client_name + ' ' + this.props.item.last_name}</td>
                <td>{this.props.item.description}</td>
                <td>{moment(this.props.item.created_at).format('DD-MMM-YYYY hh:mm:ss')}</td>
                <td>{this.props.item.tags}</td>
                <td>{this.props.item.category}</td>
                <td>{this.props.item.food_preferences}</td>
                <td>{this.props.item.max_calories}</td>
                <td>
                    <Link to={"/workspace/" + this.props.item.package_id + "/" + this.props.item.id} className="no-decoration" > View </Link>
                </td>
            </tr>
        )
    }
}

class NutritionPlanLists extends FilterList {
    constructor(args) {
        super(args);
        this.state = {
            formData: {},
            filterAll: true,
            sortColumn: 'all',
            searchQuery: ''
        };
    }

    setFormData(id, value) {
        let {formData} = this.state;
        let filterQuery = '';

        if (id == 'category') {
            if (formData.category != value) {
                formData.sub_category = '';
            }
        }
        this.state.formData[id] = value;
        this.props.getDietPlanPackageProfileList(this.state.formData);
        this.setState({
            formData: this.state.formData
        })

        if (Object.keys(formData).length > 0) {
            filterQuery = "&" + $.param(formData);
            hashHistory.push("nutrition-plans?page=1&order=desc&col=Created At"+ filterQuery);
        }
    }

    handleChange(evt) {
        evt.preventDefault();
        const targetElement = $(evt.target).attr('id');
        const targetValue = $(evt.target).val();
        if (targetElement === 'search') {
            this.setState({
                searchQuery: targetValue
            });
        }
        this.setFormData(targetElement, targetValue);
    }

    handleSubmit(evt) {
        //getDietPlanPackageProfileList(this.props.dispatch, this.state.formData);
        this.setState({
            formData: this.state.formData
        })
    }

    handleSelect(evt) {
        if (evt) {
            this.setState({
                subcat: evt.target.value
            })
        }
        var targetElement = $(evt.target).attr('id');
        this.setFormData(targetElement, $(evt.target).val());
        this.props.fireRequest('subCategoryList', [], {
            category: 'Categories',
            parent: evt.target.value
        });
        this.setState({
            formData: this.state.formData
        })
    }

    componentDidMount() {
        if (this.props.dietPlanList == null) {
            this.props.getDietPlanPackageProfileList(null);
        }

        if (!this.props.categoryList) {
            this.props.fireRequest('categoryList');
        }

        let categoryName = this.props.location.query.category;
        if (categoryName) {
            this.props.fireRequest('subCategoryList', [], {
                category: 'Categories',
                parent: categoryName,
            });
        }

        if (this.props.location.query.col) {
            this.state.sortColumn = this.props.location.query.col;
        }
        this.props.getPaginatedList('dietPlanPackageProfileList', this.props.location.query);

        var query = this.props.location.query;

        let formData = {};
        let formKey=['search','label','food_pref','age_group','category','sub_category','min_cal','max_cal'];
            
        Object.keys(query).forEach(function(key) {
                for(var i=0; i < formKey.length; i++){               
                if (key == formKey[i]) {
                    formData[key] = query[key];
                }
            }
        });
        
        this.setState({
            formData
        });

    }

    componentDidUpdate(prevProps, prevState) {
        var query = this.props.location.query
        var prevQuery = prevProps.location.query

        if (query.page != prevQuery.page || query.order != prevQuery.order || query.search != prevQuery.search
        || query.label != prevQuery.label || query.food_pref != prevQuery.food_pref || query.age_group != prevQuery.age_group
        || query.category != prevQuery.category || query.sub_category != prevQuery.sub_category || query.min_cal != prevQuery.min_cal 
        || query.max_cal != prevQuery.max_cal) {

            this.props.getPaginatedList('dietPlanPackageProfileList', query);
        }
        $("#filter-client").foundation();
    }

    componentWillReceiveProps(nextProps){      
        let formData = {};
        let {query}= this.props.location.query;
        let nextPropsQuery = nextProps.location.query;
        let formKey=['search','label','food_pref','age_group','category','sub_category','min_cal','max_cal'];
        
        if (query != nextPropsQuery) {
            Object.keys(nextPropsQuery).forEach(function (key) {
                for (var i = 0; i < formKey.length; i++) {
                    if (key == formKey[i]) {
                        formData[key] = nextPropsQuery[key];
                    }
                }
            });

            this.setState({
                formData
            });
        }
    }


    orderNutritionPlan(col) {
        this.state.sortColumn = col;
        var order = this.props.location.query.order == 'asc' ? 'desc' : 'asc';
        var page = this.props.location.query.page ? this.props.location.query.page : 1;
        let {formData} = this.state;
        let filterQuery = '';

        if (Object.keys(formData).length > 0) {
            filterQuery = "&" + $.param(formData);
        }
        hashHistory.push("nutrition-plans?page="+page+"&order=" + order + "&col=" + col + filterQuery);
    }

    render() {
        const {
            dietPlanList,
            categoryList,
            subCategoryList,
            location
        } = this.props;
        let options = [];
        let subOption = [];
        let data = [];
        let order = location.query.order ? location.query.order : 'desc';
        let pagination = '';
        const { formData, searchQuery } = this.state;

        if (categoryList) {
            for (var idx = 0; idx < categoryList.data.length; idx++) {
                options.push(<option key={idx}>{categoryList.data[idx]}</option>)
            }
        }

        if (subCategoryList) {
            for (var i = 0; i < subCategoryList.data.length; i++) {
                subOption.push(<option key={i}>{subCategoryList.data[i]}</option>)
            }
        }

        if (dietPlanList && dietPlanList.data && dietPlanList.data.data && dietPlanList.data.data.length > 0) {
            data = dietPlanList.data.data;
        }

        let rows = [];
        let firstNameList = [];
        let lastNameList = [];
        if ( searchQuery) {
            const query = searchQuery.toLowerCase();
            data.filter(item => {
                if (item.client_name.toLowerCase().indexOf(query) !== -1) {
                    firstNameList.push(item);
                } else if (item.last_name.toLowerCase().indexOf(query) !== -1) {
                    lastNameList.push(item);
                }
            });
            firstNameList.concat(lastNameList).map((item,idx) => {
                rows.push(
                    <DietPlanListRow
                        key={ "idx_" + idx}
                        item={item}
                    />
                );
            });
        } else {
            data.forEach((item, idx) => {
                rows.push(
                    <DietPlanListRow
                        key={idx}
                        item={item}
                    />
                );
            });
        }

        if (rows.length > 0) {
            let filterQuery = '';
            if (Object.keys(formData).length > 0) {
                filterQuery = "&" + $.param(formData);
            }

            pagination = <div className="pagination-left w3-margin-top">
                <ul className="pagination">
                    <li className="w3-padding-small w3-button"><Link to={"/nutrition-plans?page=1&order=" + order + "&col=" + this.state.sortColumn + filterQuery}>«</Link></li>
                    <PaginationSwitcher basePath="/nutrition-plans"
                        filterQuery={filterQuery}
                        order={order}
                        col={this.state.sortColumn}
                        selected={this.props.location.query.page || 1}
                        // selected={dietPlanList.data.current_page || 1}
                        options={dietPlanList.data.last_page} />
                    <li className="w3-padding-small w3-button"><Link to={"/nutrition-plans?page=" + dietPlanList.data.last_page + "&order=" + order + "&col=" + this.state.sortColumn + filterQuery} >»</Link></li>
                </ul>
            </div>
        }

        if (rows.length == 0 && this.isEmptyFilter()) {
            rows = <tr><td colSpan="12" className="no-data-text w3-center">
                <div className="alert info">There are no Nutrition Plans</div>
            </td></tr>
        } else if (rows.length == 0) {
            rows = <tr>
                <td colSpan="12" className="no-data-text w3-center">
                    <div className="alert info">
                        There are no results matching the current filter
                    </div>
                </td>
            </tr>
        }

        return (
            <div className="w3-container  w3-padding">
                <div className="m12">
                    <h4 className="section-header">Nutrition Plans</h4>
                </div>
                <div className="m12 w3-padding-24">
                    <form id="searchPlan" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="w3-row w3-row-padding w3-margin-bottom">
                            <div className="m3 s12 w3-col">
                                <input
                                    type="text"
                                    value={(this.state.formData.search) ? this.state.formData.search : ''}
                                    onChange={this.handleChange.bind(this)}
                                    placeholder="Search"
                                    id="search"
                                    className="w3-input w3-border w3-round"
                                    style={{ height: "40px" }}
                                />
                            </div>
                            <div className="m3 s12 w3-col">
                                <input
                                    type="text"
                                    value={(this.state.formData.label) ? this.state.formData.label : ''}
                                    onChange={this.handleChange.bind(this)}
                                    placeholder="Tags"
                                    id="label"
                                    className="w3-input w3-border w3-round"
                                    style={{ height: "40px" }}
                                />
                            </div>
                            <div className="m3 s12 w3-col">
                                <select
                                    onChange={this.handleChange.bind(this)}
                                    value={(this.state.formData.food_pref) ? this.state.formData.food_pref : ''}
                                    id="food_pref"
                                    name="food_pref"
                                    style={{ height: "40px" }}
                                    className="w3-select w3-border w3-round"
                                >
                                    <option value="">-Food Preferences-</option>
                                    <option value="Vegetarian">Vegetarian</option>
                                    <option value="Non Vegetarian">NonVegetarian</option>
                                    <option value="Non Veg (Occasionally)">Non Veg (Occasionally)</option>
                                    <option value="Eggetarian">Eggetarian</option>
                                    <option value="Vegan">Vegan</option>
                                    <option value="Pescetarian">Pescetarian</option>
                                </select>
                            </div>
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleChange.bind(this)}
                                    value={(this.state.formData.age_group) ? this.state.formData.age_group : ''}
                                    id="age_group"
                                    name="age_group"
                                    style={{ height: "40px" }}
                                    className="w3-select w3-border w3-round">
                                    <option value="">-Age Group-</option>
                                    <option value="0-05">0-05</option>
                                    <option value="06-10">06-10</option>
                                    <option value="11-15">11-15</option>
                                    <option value="16-20">16-20</option>
                                    <option value="21-25">21-25</option>
                                    <option value="26-30">26-30</option>
                                    <option value="31-35">31-35</option>
                                    <option value="36-40">36-40</option>
                                    <option value="41-45">41-45</option>
                                    <option value="46-50">46-50</option>
                                    <option value="51-55">51-55</option>
                                    <option value="56-60">56-60</option>
                                    <option value="61-65">61-65</option>
                                    <option value="66-70">66-70</option>
                                    <option value="71-75">71-75</option>
                                    <option value="76-80">76-80</option>
                                    <option value="81-100">81+</option>
                                </select>
                            </div>
                        </div>
                        <div className="w3-row w3-row-padding w3-margin-bottom">
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleSelect.bind(this)}
                                    value={(this.state.formData.category) ? this.state.formData.category : ''}
                                    id="category"
                                    name="category" style={{ height: "40px" }}
                                    className="w3-select w3-border w3-round">
                                    <option value="">-Category-</option>
                                    {options}
                                </select>
                            </div>
                            <div className="m3 s12 w3-col">
                                <select onChange={this.handleChange.bind(this)}
                                    value={(this.state.formData.sub_category) ? this.state.formData.sub_category : ''}
                                    id="sub_category"
                                    name="sub_category" style={{ height: "40px" }}
                                    className="w3-select w3-border w3-round">
                                    <option value="">-SubCategory-</option>
                                    {subOption}
                                </select>
                            </div>

                            <div className="m6 s12 w3-col">
                                <div className="w3-row">
                                    <div className="m4 s12 w3-col">
                                        <label>Total Calories Range</label>
                                    </div>
                                    <div className="m3 s12 w3-col">
                                        <input type="text"
                                            id="min_cal"
                                            name="min_cal"
                                            placeholder="Min"
                                            onChange={this.handleChange.bind(this)} style={{ height: "40px" }} 
                                            value={(this.state.formData.min_cal) ? this.state.formData.min_cal : ''}
                                            className="w3-input w3-border w3-round"/>
                                    </div>
                                    <div className="m1 s12 w3-col w3-center">
                                        <span>to</span>
                                    </div>
                                    <div className="m3 s12 w3-col">
                                        <input type="text"
                                            id="max_cal"
                                            name="max_cal"
                                            placeholder="Max"
                                            onChange={this.handleChange.bind(this)} style={{ height: "40px" }} 
                                            value={(this.state.formData.max_cal) ? this.state.formData.max_cal : ''}
                                            className="w3-input w3-border w3-round"/>
                                    </div>
                                    <div className="m1 s12 w3-col"></div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="m12">
                    <div className="w3-responsive">
                        <table className="w3-table-all listing">
                            <thead>
                                <tr className="w3-light-grey">
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Client')} title="Client"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Nutrition Plan Name')} title="Nutrition Plan Name"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Created At')} title="Created At"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Tags')} title="Tags"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Category')} title="Category"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Food Preferences')} title="Food Preferences"
                                        current={this.state.sortColumn} />
                                    <SortableTh onSort={this.orderNutritionPlan.bind(this, 'Maximum Calories')} title="Maximum Calories"
                                        current={this.state.sortColumn} />
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        {pagination}
                    </div >
                </div >

            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        dietPlanList: state.dietPlanPackageProfileList,
        categoryList: state.cdietPlanL1belList,
        categoryList: state.categoryList,
        subCategoryList: state.subCategoryList
    };
}
export default connect(mapStateToProps, {
    getDietPlanlist,
    getPaginatedList,
    getDietPlanPackageProfileList,
    fireRequest,
})(NutritionPlanLists);
