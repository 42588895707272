import 'whatwg-fetch';
import $ from 'jquery'; 

export var actions = {
    GET_APP_REQUEST : "GET_APP_REQUEST",
    GET_APP_REQUEST_SUCCESS : "GET_APP_REQUEST_SUCCESS",
    GET_APP_REQUEST_ERROR : "GET_APP_REQUEST_ERROR",
    SET_DATA: 'SET_DATA'
}

export let requestMap = {

    currentUser:{
        path:'/api/v1/auth/session-user'
    },
    clientProfileList: {
        path: '/api/v1/client/package/client-list'
    },
    clientProfile: {
        path: '/api/v1/client/package/client-list'
    },
    clientDetails: {
        path: '/api/v1/client/profile/details'
    },
    packageList: {
        path: '/api/v1/client/package/list'
    },
    packageDetail: {
        path: '/api/v1/client/package/detail'
    },
    packageStatus: {
        path: '/api/v1/client/package/list'
    },
    dietitianProfile: {
        path: '/api/v1/client/package/dietitian-list'
    },
    dietitianList : {
        path: '/api/v1/client/package/dietitian-list'
    },
    dietitianFilter : {
        path: '/api/v1/client/package/dietitian-list'
    },
    dietitianDetails: {
        path: '/api/v1/client/package/dietitian-list'
    },
    surveyQuestions: {
        path: '/api/v1/survey/question/list'
    },
    surveyQuestionLoadData: {
        path: '/api/v1/survey/question/load-data'
    },
    loadCategoryData: {
        path: '/api/v1/category/load-category-data'
    },
    referrallList: {
        path: '/api/v1/category/categories?category=Generic&parent=Referral_Source'
    },
    tagList: {
        path: '/api/v1/category/categories?category=Generic&parent=Diet_Plan_Labels'
    },
    supplementList: {
        path: '/api/v1/diet/supplement/supplements-data'
    },
    supplementDetails: {
        path: '/api/v1/diet/supplement/diet-plan-supplement'
    },
    packageStatusList: {
        path: '/api/v1/category/categories?category=Generic&parent=Package_Status'
    },
    dietitianStatusList: {
        path: '/api/v1/category/categories?category=Generic&parent=Nutritionist_Status'
    },
    profileStatusList: {
        path: '/api/v1/category/categories?category=Generic&parent=Client_Status'
    },
    surveyResponses: {
        path: '/api/v1/survey/response/response'
    },
    mealTimeDetails:{
        path: '/api/v1/survey/dietaryrecall/meal-time-details'
    },
    foodItemLoadData: {
        path: '/api/v1/food/item/load-data'
    },
    categoryList: {
        path: '/api/v1/category/categories?category=Categories&parent=Categories'
    },
    subCategoryList: {
        path: '/api/v1/category/categories'
    },
    timingList: {
        path: '/api/v1/category/categories'
    },
    categoryTimings:{
        path: '/api/v1/category/timings'
    },
    durationList: {
        path: '/api/v1/category/categories?category=Generic&parent=Package_Duration'
    },
    dietPlanListStatus: {
        path: '/api/v1/diet/plan/list'
    },
    dietPlanListbyPackageId: {
        path: '/api/v1/diet/plan/list-by-package'
    },
    dietPlanDayList: {
        path: '/api/v1/diet/planday/list'
    },
    currentWorkspace: {
        path: '/api/v1/diet/plan/meals'
    },
    dietPlanPackageProfileList: {
        path: '/api/v1/client/package/diet-plan-package-profile-list'
    },
    bodyVitalParams: {
        path: '/api/v1/vital/bodyparams/body-vital-lists'
    },
    bodyVitalParam: {
        path: '/api/v1/vital/bodyparams/body-param'
    },
    bodyVitalStatusList: {
        path: '/api/v1/category/categories?category=Generic&parent=Body_Vital_Status'
    },
    dietitianListAuto : {
        path: '/api/v1/client/package/dietitian-list?auto=true'
    },
    surveyWorkoutDetails:{
        path: '/api/v1/survey/surveyworkout/survey-workout-details'
    },
    mealTimingOptionList:{
        path: '/api/v1/diet/planday/meal-timing-list'
    },
    recipeList: {
        path: '/api/v1/diet/planrecipe/recipes-data'
    },
    fitnessTimings:{
        path:'/api/v1/category/fitness'
    },
    loadSystemConfigData:{
        path: '/api/v1/category/load-system-config-data'
    },
    printPdfSettingsData:{
        path: '/api/v1/settings/print-pdf-settings-data'
    },
    loadCreditsData:{
        path: '/api/v1/category/load-credits-data'
    },
    foodConfigVal:{
        path: '/api/v1/settings/food-config-value'
    },
}

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export function fetchDataRequest(key,request) {
    return {
        type: actions.GET_APP_REQUEST,
        key,
        request,
        response: null,
        error: null
    }
}

export function fetchResponseReceived(key,request, response) {
    return {
        type: actions.GET_APP_REQUEST_SUCCESS,
        key,
        request,
        response: response
    }
}

export function fetchDataRequestError(key,request,error) {
    return {
        type: actions.GET_APP_REQUEST_ERROR,
        key,
        request,
        response: null,
        error: error
    }
}

export function fireRequest(key,path = [], params = {}) {

    return function (dispatch) {

        var request = Object.assign({}, requestMap[key]);
        request.headers =  headers;
        request.credentials =  'same-origin';

        if (path.length > 0) {
            request.path += path.join('/');
        }

        if (request.method === undefined) {
            request.method = 'GET';
        }

        if (request.method == 'POST') {
            request.body =  JSON.stringify(params)
        } else  if(params){
            var qs = $.param(params);
            if (qs != '') {
                request.path += ("?" + qs);
            }
        }

        dispatch(fetchDataRequest(key, request));

        return fetch(request.path, request)
        .then(response => response.ok ? response.json() :  Promise.reject(new Error('Api Error. No response.' + response.json())))
        .then(json => {
            if (json == null) {
               return dispatch(fetchDataRequestError(key, request, json));
            } else {
               return dispatch(fetchResponseReceived(key, request, json));
            }
            return json;
        }, err => {
           return  dispatch(fetchDataRequestError(key, request, err));
        });

    };
}

export function getSessionUser(){
    return fireRequest('currentUser',[],{});
}

export function getDietitianList(page= 1,category=null) {
    var params = { page: page};
    return fireRequest('dietitianList', [], params);
}

export function getClientProfileList(filtersStatus=null,search='') {

    var params = {};
    if (filtersStatus != null) {
        params = { status: filtersStatus}
    }
    if (search != '') {
        params = { q: search, status: filtersStatus}
    }
    return fireRequest('clientProfileList', [], params);
}

export function getPackageList(page= 1,category=null) {
    var params = { page: page};
    return fireRequest('packageList', [], params);
}

// export function getPackageDetail(packageId) {
//     var params = { id: packageId};
//     return fireRequest('packageDetail', [], params);
// }

// export function getPackageStatus(d, Filterstatus) {
//     var params = { status: Filterstatus};
//     d(fireRequest('packageStatus', [], params));
// }

export function getPackageStatus(filtersStatus=null,search='',page=0) {

    var params = {};
    if (filtersStatus != null) {
        params = { status: filtersStatus}
    }
    if (search != '') {
        params = { q: search, status: filtersStatus}
    }
    if (page){
        params.page = page;
    }
    return fireRequest('packageStatus', [], params);
}

export function getClientProfile(userId) {
    var params = { id: userId};
    return fireRequest('clientProfile', [], params);
}

export function getDietitianProfile(dietitianId) {
    var params = { id: dietitianId};
    return fireRequest('dietitianProfile', [], params);
}

export function getPackageDetails(packageId,token) {
    var params = { id: packageId, token:token};
    return fireRequest('packageDetail', [], params);
}

export function setData(key,value){
    return {
        type:'SET_DATA',
        key:key,
        value:value
    }
}

// export function getDietitianStatus(d, Filterstatus) {
//     var params = { status: Filterstatus};
//     d(fireRequest('dietitianStatus', [], params));
// }

export function getDietitianFilter(filtersStatus=null,search='') {

    var params = {};
    if (filtersStatus != null) {
        params = { status: filtersStatus}
    }
    if (search != '') {
        params = { q: search, status: filtersStatus}
    }
    return fireRequest('dietitianFilter', [], params);
}

export function getSurveyQuestions() {
    var params = {};
    return fireRequest('surveyQuestions', [], params);
}

export function getClientDetails(userId) {
    var params = { id: userId};
    return fireRequest('clientDetails', [], params);
}
export function getDietitianDetails(userId) {
    var params = { id: userId};
    return fireRequest('dietitianDetails', [], params);
}

export function getFoodItemLoadData() { 
    return fireRequest('foodItemLoadData', [], {});
}

export function getSurveyQuestionLoadData() {
    var params = {};
    return fireRequest('surveyQuestionLoadData', [], params);
}

export function getLoadCategoryData() {
    var params = {};
    return fireRequest('loadCategoryData', [], params);
}
export function getSurveyResponses(survey_token) {
    var params = {survey_token: survey_token};
    return fireRequest('surveyResponses', [], params);
}
export function getDietPlanlist() {
    var params = {};
    return fireRequest('dietPlanList', [], params);
}
export function getDietPlanlistStatus(filtersStatus=null,search='') {

    var params = {};
    if (filtersStatus != null) {
        params['status'] =  filtersStatus;
    }
    if (search != '') {
        params['q'] = search
    }
    return fireRequest('dietPlanListStatus', [], params);
}
export function getdietPlanListbyPackageId(packageId) {
    var params = {id: packageId};
    return fireRequest('dietPlanListbyPackageId', [], params);
}

export function getDietPlanPackageProfileList(formData) {
    return fireRequest('dietPlanPackageProfileList', [], formData);
}

export const getDietPlanDaylist = (dietPlanId=null) => {
    var params = {};
    if (dietPlanId != null) {
        params = { diet_plan_id: dietPlanId};
    }
    return fireRequest('dietPlanDayList', [], params);
}

export function getDietPlanDayDetails(dietPlanId) {
    var params = {id: dietPlanId};
    return fireRequest('currentWorkspace', [], params);
}

export function getPaginatedList(requestMp='', params) {
    return fireRequest(requestMp, [], params);
}

export function getBodyVitalsDetails(userId) {
    var params = { client_id: userId};
    return fireRequest('bodyVitalParams', [], params);
}

export function getBodyVitalParam(paramId) {
    var params = { id: paramId};
    return fireRequest('bodyVitalParam', [], params);
}

export function getSupplementDetails(planId) {
    var params = { diet_plan_id: planId};
    return fireRequest('supplementDetails', [], params);
}

export function getMealTimeDetails(packageId){
    var params = {package_id : packageId};
    return fireRequest('mealTimeDetails',[],params);
}
export function getSurveyWorkoutDetails(packageId){
    var params = {package_id: packageId};
    return fireRequest('surveyWorkoutDetails',[],params);
}

export function getLoadSystemConfigData() {
    var params = {};
    return fireRequest('loadSystemConfigData', [], params);
}

export function getLoadCreditsData() {
    var params = {};
    return fireRequest('loadCreditsData', [], params);
}

export function getFoodConfigVal() {
    var params = {};
    return fireRequest('foodConfigVal', [], params);
}
