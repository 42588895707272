import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    getDietPlanlist,
    getDietPlanPackageProfileList,
    fireRequest,
} from "../../js/actions.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';
import { FormSelectField } from '../Common/HelperComponents.js';


class CheckBox extends Component {

    toggleChange(evt) {
        if (evt.target.id == 'check_all') {
            this.props.handleCheckAllChange(evt.target.id);
        } else if(evt.target.id == 'check_search_all'){
            this.props.handleSearchCheckAllChange(evt.target.id);
        } else {
            this.props.handleCheckBoxChange(this, evt.target.checked);
        }
    }

    render() {
        let { option, cls, disable } = this.props;
        return (
            <div className={`m12 w3-col w3-left-align w3-margin-left ${cls}`}>
                <label>
                    <input
                        type="checkbox"
                        id={option.id}
                        value={option.value}
                        checked={option.checked}
                        disabled={disable}
                        onChange={this.toggleChange.bind(this)}
                        className="w3-check check-small checkbox-space" />
                    {option.label}
                </label>
            </div>
        )
    }
}

class MealTimeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBoxOption: '',
            optionsArray: [],
            optionSearchArray: [],
            checkAll: false,
            checkAllChecked: false,
            searchCheckAll: false,
            searchCheckAllChecked: false,
            showButton: false,
            showExist: false,
            disable: false,
            targetId : null,

        }
    }

    componentDidMount() {
        let { item_id } = this.props;
        var tempArray = [];
        const self = this;
        this.props.fireRequest('mealTimingOptionList', [],
            {
                dietPlanId: item_id,
            }
        ).then(function (res) {
            res.response.data.forEach((option) => {
                tempArray.push({
                    id: option.hypothetical_time,
                    label: option.hypothetical_time,
                    value: option.hypothetical_time,
                    checked: false
                })
            });
            self.setState({ optionsArray: tempArray })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pack_id != prevProps.pack_id) {
            this.props.fireRequest('mealTimingOptionList', [],
                {
                    dietPlanId: this.props.item_id,
                });
        }
    }

    componentWillReceiveProps(newProps) {

        if (newProps.pack_id != this.props.pack_id) {
            this.props.fireRequest('mealTimingOptionList', [],
                {
                    dietPlanId: newProps.item_id,
                }
            )
        }
    }

    getCheckedValues() {
        let { optionsArray } = this.state;
        var checkedOptionsList = [];
        var checkedOptions = optionsArray.filter((option, idx) => {
            return option.checked;
        })
        checkedOptions.forEach((option) => {
            checkedOptionsList.push(option.value);
        })
        return checkedOptionsList;
    }
    
    getSearchCheckedValues() {
        let { optionSearchArray } = this.state;
        var checkedSearchOptionsList = [];
        var checkedOptions = optionSearchArray.filter((option, idx) => {
            return option.checked;
        })
        checkedOptions.forEach((option) => {
            checkedSearchOptionsList.push(option.value);
        })
        return checkedSearchOptionsList;
    }

    handleCheckAll() {
        let {
            checkAll,
            checkAllChecked,
            optionsArray
        } = this.state;
        if (checkAll === false && checkAllChecked === false) {
            optionsArray.forEach((option, idx) => {
                optionsArray[idx].checked = true;
            });
        } else if (checkAll === true && checkAllChecked === true) {
            optionsArray.forEach((option, idx) => {
                optionsArray[idx].checked = false;
            });
        }
    }

    handleSearchCheckAll() {
        let {
            searchCheckAll,
            searchCheckAllChecked,
            optionSearchArray
        } = this.state;
        if (searchCheckAll === false && searchCheckAllChecked === false) {
            optionSearchArray.forEach((option,idx) => {
                optionSearchArray[idx].checked = true;
            });
        } else if (searchCheckAll === true && searchCheckAllChecked === true) {
            optionSearchArray.forEach((option,idx) => {
                optionSearchArray[idx].checked = false;
            });
        }
    }

    toggleCheckAll(evt) {
        let {
            checkAll,
            checkAllChecked,
            showButton
        } = this.state;
        if (evt === 'check_all') {
            this.setState({
                checkAll: !checkAll,
                checkAllChecked: !checkAllChecked,
            });
            this.handleCheckAll();
            if (showButton) {
                this.setState({ showButton: false });
            }
        }
    }

    toggleSearchCheckAll(evt) {
        let {
            searchCheckAll,
            searchCheckAllChecked,
            showButton
        } = this.state;
        if (evt === 'check_search_all') {
            this.setState({
                searchCheckAll: !searchCheckAll,
                searchCheckAllChecked: !searchCheckAllChecked,
            });
            this.handleSearchCheckAll();
            // if (showButton) {
            //     this.setState({ showButton: false });
            // }
        }
    }

    handleCheckedBoxes(changed, newState) {
        let { optionsArray } = this.state;
        var changedOptionArray = optionsArray;

        optionsArray.forEach((option, idx) => {
            if (option.id === changed.props.option.id) {
                changedOptionArray[idx].checked = newState;
            }
        });

        this.setState({
            optionsArray: changedOptionArray
        });

    }

    handleSearchCheckedBoxes(changed, newState) {
        let { optionSearchArray } = this.state;
        var changedSearchOptionArray = optionSearchArray;

        optionSearchArray.forEach((option, idx) => {
            if (option.id === changed.props.option.id) {
                changedSearchOptionArray[idx].checked = newState;
            }
        });

        this.setState({
            optionSearchArray: changedSearchOptionArray
        });

    }

    handlePlanCopy(sourcePackageId, planId, evt) {
        evt.preventDefault();
        // evt.stopProp
        var self = this;
        var selectedTimingList = this.getCheckedValues();
        this.setState({ showButton: true });
        if(selectedTimingList.length > 0){

        
        $.ajax({
            method: 'POST',
            url: '/api/v1/diet/plan/copy-plan',
            data: {
                sourcePackageId: sourcePackageId,
                sourcePlanId: planId,
                targetPackageId: this.props.targetPackageId,
                selectTimeList: selectedTimingList,
                targetPlanId : null,
            }
        }).done(function (resp) {
            var url = '/workspace/' + self.props.targetPackageId + "/" + resp.data.planId;
            self.props.getDietPlanPackageProfileList(null);
            let { optionsArray } = self.state;
            optionsArray.forEach((option, idx) => {
                optionsArray[idx].checked = false;
            });
            self.setState({
                checkAll: false,
                checkAllChecked: false,
                showButton: false
            });
            self.mealTimeModalOpen('');
            hashHistory.push(url);
        });
    }
    else{
        alert("Please Select Timings");
        self.mealTimeModalOpen('');
        return;
     }
    }

    mealTimeModalOpen(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'mealTimingListFormOpen',
            value: name
        })
    }

    handleExistPlan() {
        var self = this;
        self.setState({
            showExist: !self.state.showExist,
            showButton: !self.state.showButton,
            disable: !self.state.disable
        })
    }
    handleSearch(evt) {
        const self = this;
        var selectedElem = evt.target.value;
        var tempSearchArray = [];
        this.props.fireRequest('mealTimingOptionList', [], {
            dietPlanId: selectedElem,
        }).then(function (response) {
            response.response.data.forEach((options) => {
                tempSearchArray.push({
                    id: options.hypothetical_time,
                    label: options.hypothetical_time,
                    value: options.hypothetical_time,
                    checked: false
                })
            })
            self.setState({ optionSearchArray: tempSearchArray,
             targetId : selectedElem })
        })


    }

    handleSearchPlanCopy(sourcePackageId,planId,evt){
        evt.preventDefault();
        var self = this;
        let targetSelectId = self.state.targetId;
        var selectedSearchTimeList = this.getSearchCheckedValues();
    }


    render() {
        const self = this;
        let { pack_id, item_id } = this.props;
        let {
            optionsArray,
            checkAll,
            checkAllChecked,
            showButton,
            optionSearchArray,
            searchCheckAll,
            searchCheckAllChecked,
            disable
        } = this.state;
        var modalTitle = "Select Meal Timings to Copy";
        var checkBoxes = [];
        var searchCheckBoxes = [];
        var warningText = "Selected Nutrition Plan is empty. Please choose another Nutrition Plan to copy.";


        if (optionsArray.length > 0) {
            var cls = ''
            optionsArray.forEach((option, idx) => {
                cls = (optionsArray.length - 1 == idx ? 'bottom-padding' : '')
                checkBoxes.push(<CheckBox key={idx} option={option} cls={cls} handleCheckBoxChange={self.handleCheckedBoxes.bind(self)} />)
            });
        }


        if (optionSearchArray.length > 0) {
            var cls = ''
            optionSearchArray.forEach((option, idx) => {
                cls = (optionSearchArray.length - 1 == idx ? 'bottom-padding' : '')
                searchCheckBoxes.push(<CheckBox key={idx} option={option} cls={cls} handleCheckBoxChange={self.handleSearchCheckedBoxes.bind(self)} />)
            });
        }

        var values = {
            id: 'check_all',
            label: checkAll == false ? 'Check All' : 'Uncheck All',
            value: checkAll,
            checked: checkAllChecked
        }

        var searchValues = {
            id: 'check_search_all',
            label: searchCheckAll == false ? 'Check All' : 'Uncheck All',
            value: searchCheckAll,
            checked: searchCheckAllChecked
        }

        var contents = [];
        var dietContent = this.props.dietplan.data.data;

        if (dietContent) {
            for (var key in dietContent) {
                var valer = dietContent[key];
                contents.push(<option value={valer.id} key={key}>{valer.description}</option>)
            }

        }

        return (
            <div>
                <div className="w3-row w3-margin-bottom">
                    <div className="w3-margin w3-center reveal-heading">
                        {modalTitle}
                    </div>
                    <div className="w3-col s12 m12">
                        <div className="">
                            {checkBoxes.length != 0 ? checkBoxes : <p className="alert">{warningText}</p>}
                        </div>
                        <div className="">
                            {checkBoxes.length != 0 ?
                                <CheckBox
                                    option={values}
                                    disable={this.state.disable}
                                    handleCheckAllChange={self.toggleCheckAll.bind(self)}
                                /> : null
                            }
                        </div>
                    </div>
                    <div className="s12 w3-right">
                        {checkBoxes.length != 0 ?
                            <button
                                type="button"
                                onClick={this.handlePlanCopy.bind(this, pack_id, item_id)}
                                className="w3-button nutrify-btn"
                                disabled={showButton}
                            >
                                Create Copy
                            </button> : null
                        }
                    </div>

                    <button className="w3-btn no-shadow w3-display-topright" onClick={this.mealTimeModalOpen.bind(this, '')} aria-label="Close modal" type="button">
                        <span className="w3-xlarge" aria-hidden="true">&times;</span>
                    </button>
                </div>
                {/* <div className="w3-padding">
                    <input type="checkbox" id="mycheck" onClick={this.handleExistPlan.bind(this)} />
                    <label className="w3-large" style={{ cursor: "pointer" }} for="mycheck"> Copy from existing plan </label>
                </div> */}
                {this.state.showExist ?
                    <div className="w3-padding">

                        <label>Select Nutrition Plan : </label>
                        <select
                            className="w3-select"
                            id="select_plan"
                            name="select_plan"
                            label="Select Nutrition Plan"
                            onChange={this.handleSearch.bind(this)}
                        >
                            {contents}
                        </select>
                        <br />

                        <div className="w3-col s12 m12 w3-padding-16">
                            <div className="">
                                {searchCheckBoxes.length != 0 ? searchCheckBoxes : <p className="alert">{warningText}</p>}
                            </div>
                            <div>
                                {searchCheckBoxes.length != 0 ?
                                    <CheckBox
                                        option={searchValues}
                                        handleSearchCheckAllChange={self.toggleSearchCheckAll.bind(self)}
                                    /> : null
                                }
                            </div>
                        </div>

                        <div className="s12 w3-right">
                        {searchCheckBoxes.length != 0 ?
                            <button
                                type="button"
                                onClick={this.handleSearchPlanCopy.bind(this, pack_id, item_id)}
                                className="w3-button nutrify-btn"
                                // disabled={showButton}
                            >
                                Merge Copy
                            </button> : null
                        }
                    </div>
                    </div>
                    : null}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mealTimingOptionList: state.mealTimingOptionList,
        getDietPlanPackageProfileList: state.getDietPlanPackageProfileList
    };
}
export default connect(mapStateToProps, {
    getDietPlanlist,
    getDietPlanPackageProfileList,
    fireRequest
})(MealTimeModal);