import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {FormTextField} from "../Common/HelperComponents.js";
import SettingsLoadData from './SettingsLoadData';
import UploadDataConfig from './UploadDataConfig';

class ManageData extends Component {

    constructor(args) {
        super(args);
        this.state = {};
    }   

    render() {
        var adminFlag = false;
        if (this.props.currentUser.type != "DIETICIAN"){
            adminFlag = true;
        }
        return (            
            <div className="w3-row-padding settings-margin">
            {   adminFlag && 
                <div className={"w3-col m8 s12"}>
                    <UploadDataConfig
                        admin={adminFlag}
                    />
                </div>
            }
                <div className={"w3-col m4 s12"}>
                    <SettingsLoadData
                        admin={adminFlag}
                    />
                </div>
            </div> 
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
    };
}
export default connect(mapStateToProps, {} )(ManageData);
