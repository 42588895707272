import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import $ from 'jquery';

class Header extends Component {

    logout(e){
        e.preventDefault();
        $.get('/api/v1/logout').always(resp=>{
            document.location.reload();
        });
    }
    render() {
        var logoutLink = null;
        var creditInfo = null;
        var expiry = null;
        if (this.props.currentUser != null) {
            logoutLink = <a href="/" onClick={e=> this.logout(e)} className="w3-right no-decoration w3-button" >Logout</a>;
            creditInfo = this.props.currentUser.credits - this.props.currentUser.plans;
            expiry = this.props.currentUser.expiry;
        }
        return (
                <div className="top-bar w3-container w3-padding">
                    <div className="w3-left">
                        <Link to="/" style={{color: "white"}}>
                            <img src="/img/nmd_logo_96.png" style={{height: "40px"}}/>
                        </Link>
                    </div>
                    <div className="w3-right">
                        <div className="w3-bar">
                            {logoutLink}
                            {logoutLink && <div className="w3-bar-item w3-right">
                                <span className="w3-tag w3-indigo w3-round w3-small w3-padding-small"  >
                                {creditInfo ? "Remaining Plans : "+creditInfo  :"Request for plans"},  
                                {expiry ? "Expires On : "+ expiry : null}
                                </span>
                            </div>}
                        </div>
                    </div>
                </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}
export default connect(mapStateToProps)(Header);
