import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fireRequest } from "../../js/actions.js";
import { FormTextarea, FormTextField } from "../Common/HelperComponents.js";
import $ from 'jquery'; 

class SettingsLogoDisclaimer extends Component {
    constructor(args) {
        super(args);
        this.state = {
            formData:{},
            errors:{},
            logoPreview:'',
            btnText:'Save',
            successMsg:'',
            successMsgCls:''
        }

    }

    componentDidMount() {

        const self = this;
        this.props.fireRequest('printPdfSettingsData').then(resp =>{
            if(resp.response.success == true){
            if (resp.data != null) {
                    let jsonData = resp.response.data;
                    self.setState({
                        logoPreview : jsonData.upload_path,
                        formData: jsonData,
                        btnText: 'Update'
                    });
                }
            }
        });
        
    }


    componentWillReceiveProps(nextProps){
        const self = this;
        if(nextProps){
            if(nextProps.printSettings.data != null){
                let jsonData = nextProps.printSettings.data; 
                self.setState({
                    formData:jsonData,
                    logoPreview :jsonData.upload_path,
                    btnText:'Update'
                });
            }
        }
    }

    handleMessage(){
        this.setState({
            successMsg:'',
            successMsgCls:''
        });
    }

    handleRemove(){

        let { formData, logoPreview } = this.state;

        formData['upload_path'] = '';
        formData['logo_name'] = '';
        formData['logo'] = '';
        $('#logo').val('');

        this.setState({
            formData: formData,
            logoPreview: logoPreview
        });
        
    }


    handleSubmit(evt) {
        evt.preventDefault();
        const self = this;
        let { formData, btnText } = this.state;
        let form = new FormData();

        Object.keys(formData).forEach(key => {
            if (key == 'logo') {
                form.append('logo', formData['logo']);
                delete formData['logo'];
            }
        });

        form.append('json', JSON.stringify(formData));

        if(btnText == 'Update'){
            $.ajax({
                type: 'POST',
                url: '/api/v1/settings/edit',
                data: form,
                processData: false,
                contentType: false
            }).done(function (resp) {
                if (resp.success == true) {
                    self.setState({
                            formData:{},
                            logoPreview:'',
                            successMsg:'Logo & Disclaimer Text updated successfully',
                            successMsgCls:'success-message'
                        });
                        self.props.fireRequest('printPdfSettingsData');
                        setTimeout(function(){
                            self.handleMessage();
                            $('#logo').val('');
                        },2000);
                } else if (resp.success == false) {
                    self.setState({
                        formData:{},
                        logoPreview:'',
                        successMsg:'Error occurred while updating Logo & Disclaimer Text',
                        successMsgCls:'error-message'
                    });
                    setTimeout(function(){
                        self.handleMessage();
                    },2000);
                }
            });
    
        }
        else{
            $.ajax({
                type: 'POST',
                url: '/api/v1/settings/create',
                data: form,
                processData: false,
                contentType: false
            }).done(function (resp) {
                if (resp.success == true) {
                        self.setState({
                            formData:{},
                            logoPreview:'',
                            successMsg:'Logo & Disclaimer text added Successfully',
                            successMsgCls:'success-message'
                        });
                        self.props.fireRequest('printPdfSettingsData');
                        setTimeout(function(){
                            self.handleMessage();
                            $('#logo').val('');
                        },2000);
                } else if (resp.success == false) {
                    self.setState({
                        successMsg:'Error occurred while adding Logo & Disclaimer text',
                        successMsgCls:'error-message'
                    });
                    setTimeout(function(){
                        self.handleMessage();
                    },2000);
                }
            });
        }
    }


    handleChange(evt) {
        const self = this;
        let { formData, errors, logoPreview } = this.state;
        var targetElement = $(evt.target).attr('id');
        let regex = (/(\.jpg|\.jpeg|\.png)$/ig);

        if (targetElement == 'logo') {
            if(formData[targetElement] = evt.target.files[0]){    
                if((formData[targetElement].size) >= 300000 ){
                    alert("Please choose an image file not more than 300KB");
                    formData[targetElement] = $(evt.target).val('');
                    return false;
                }else
                {  
                    if(!regex.test(formData[targetElement].name)){
                        alert("Please choose an image file");
                        formData[targetElement] = $(evt.target).val('');
                        return false;
                    }else{

                    let reader = new FileReader();
                    reader.onloadend = (evt) => {
                        self.setState({
                            logoPreview: evt.target.result
                        })
                    };
                    reader.readAsDataURL(evt.target.files[0]);
                    }
                }
            }    
        }
        else {
            formData[targetElement] = $(evt.target).val();
        }
        this.setState({
            formData: formData
        });
    }

    render() {
        let { formData, errors, logoPreview, btnText, successMsg, successMsgCls  } = this.state;
        var inputLogoSection = '';
        var logoLabel = '';
  
        if(formData){
            if(formData && btnText == 'Update' ){
                
                logoLabel = ( <label className="logo-label">{}
                        <button   title="Remove Logo" onClick={this.handleRemove.bind(this)}
                        className="w3-display-topright  w3-button no-decoration">
                        <i className="icon ion-close"></i>
                        </button>
                    </label>)

                inputLogoSection = (
                    <div className="w3-margin-bottom">
                        <input type="hidden" id="upload_path" name="upload_path" value={(formData.upload_path) ? formData.upload_path : ''} />
                        <input type="hidden" id="logo_name" name="logo_name" value={(formData.logo_name) ? formData.logo_name : ''} />
                        <input type="file" id="logo" name="logo" onChange={this.handleChange.bind(this)}/>
                    </div>
                )

            } else{
                inputLogoSection = <input type="file" id="logo" name="logo" onChange={this.handleChange.bind(this)}/>
            }
        } else{
            inputLogoSection = <input type="file" id="logo" name="logo" onChange={this.handleChange.bind(this)}/>
        }

        return (
            <div className="w3-light-grey">
                <div className="w3-container w3-padding w3-margin-bottom">
                    {successMsg != '' ?
                        <div className={`w3-panel ${successMsgCls} w3-display-container w3-padding-16`}>
                            <span className="w3-button w3-round w3-display-topright" onClick={this.handleMessage.bind(this)}>&times;</span>
                            {successMsg}
                        </div> : null
                    }
                    <form encType="multipart/form-data" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="w3-row">
                            <div className="w3-col m6">
                                <h5 className="section-header">Logo</h5>
                                <div className="w3-margin-bottom">
                                    <div className="logo-position">
                                        {logoPreview ?
                                            <span>{logoLabel}
                                                <img className="w3-padding-large w3-border logo-img"
                                                    src={ logoPreview ? logoPreview: '' }/>
                                            </span>: null
                                        }
                                    </div>
                                    <div className=" w3-margin-top logo-choose">        
                                        {inputLogoSection}
                                    </div>
                                </div>
                                <div className="w3-row">
                                    <span className="w3-small logo-upload-note">
                                        Please upload a logo of maximum size(300KB) with a maximum resolution(100x200) 
                                    </span>
                                </div>
                            </div>
                        </div>         
                        <h5 className="section-header">Disclaimer</h5>
                        <FormTextarea
                            label="General Disclaimer"
                            id="general_disclaimer"
                            name="general_disclaimer"
                            onChange={this.handleChange.bind(this)}
                            value={(formData.general_disclaimer) ? formData.general_disclaimer : ''}
                            errors={errors.general_disclaimer}
                            style={{ minHeight: "180px" }} 
                            inputClass={"w3-input w3-border w3-margin-bottom"}/>

                        <FormTextarea
                            label="Sports Disclaimer"
                            id="sports_disclaimer"
                            name="sports_disclaimer"
                            onChange={this.handleChange.bind(this)}
                            value={(formData.sports_disclaimer) ? formData.sports_disclaimer : ''}
                            errors={errors.sports_disclaimer}
                            style={{ minHeight: "175px" }} 
                            inputClass={"w3-input w3-border w3-margin-bottom"}/>

                        <button type="submit" className="w3-button w3-round nutrify-btn w3-right w3-padding-small">
                            {btnText}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

SettingsLogoDisclaimer.defaultProps = {
    // 'defaultLogo':'http://app.nutrifymydiet.com//site/img/nmd_logo_03.png'
}

function mapStateToProps(state){
    return{
        printSettings: state.printPdfSettingsData
    }
}


export default connect(mapStateToProps,{fireRequest})(SettingsLogoDisclaimer);