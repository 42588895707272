import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import NutrifyReveal from "../Common/NutrifyReveal.js";
import { connect } from 'react-redux';
import { getClientProfile, getPaginatedList, fireRequest } from "../../js/actions.js";
import { FormTextField, FormTextarea, FormSelectField } from "../Common/HelperComponents.js";
import $ from 'jquery';
import gNutrifyStore from '../../js/clientStore';

class ClientProfileManager extends Component {

    constructor(args) {
        super(args);

        this.state = {
            userId: null,
            formData: {},
            formDataModal: {},
            errors: {},
            successMessage: '',
            //showClientName: 'hide',
            showDisabledButton: false
        };
    }

    componentWillReceiveProps(newProps) {

        if (newProps.userId != null && this.state.userId != newProps.userId) {
            this.setState({
                userId: newProps.userId
            }, function () {
                this.props.getClientProfile(this.state.userId);
            }.bind(this))
        } else {
            if (this.props.clientProfile) {
                this.setState({
                    formData: this.props.clientProfile.data.data[0]
                })
            }
        }

        if (newProps.clientProfile && newProps.userId != null) {
            this.setState({
                formData: newProps.clientProfile.data.data[0],
                errors: {},
                successMessage: ''
            })
        } else {
            this.setState({
                formData: {},
                errors: {},
                successMessage: ''
            })
        }

        if (newProps.userId == null) {
            this.setState({
                formData: {},
                errors: {},
                successMessage: ''
            })
        }
    }

    componentDidMount() {
        if (!this.props.referrallList) {
            this.props.fireRequest('referrallList');
        }
        if (!this.props.profileStatusList) {
            this.props.fireRequest('profileStatusList');
        }
        this.setState({
            errors: {},
            successMessage: ''
        })
    }


    handleSubmit(evt) {
        evt.preventDefault();
        this.setState({
            showDisabledButton: true
        });
        if (this.state.formData && this.state.formData.id) {

            $.ajax({
                type: "POST",
                url: '/api/v1/client/profile/edit',
                data: this.state.formData,
                success: function (response) {
                    if (response.success == true) {
                        this.setState({
                            successMessage: "Client profile edited successfully!",
                            showDisabledButton: false
                        });
                        this.props.getPaginatedList('clientProfileList', this.props.query);
                        setTimeout(function () {
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        this.setState({
                            errors: response.data,
                            showDisabledButton: false
                        })
                    }
                }.bind(this)
            });
        } else {
            $.ajax({
                type: "POST",
                url: '/api/v1/client/profile/create',
                data: this.state.formData,
                success: function (response) {
                    if (response.success == true) {
                        this.setState({
                            successMessage: "Client added successfully!",
                            showDisabledButton: false
                        });
                        this.props.getPaginatedList('clientProfileList', this.props.query);
                        setTimeout(function () {
                            $('.close-button').click();
                        }, 2000)
                    } else if (response.success == false) {
                        this.setState({
                            errors: response.data,
                            showDisabledButton: false
                        })
                    }
                }.bind(this)
            });
        }

        return false;
    }

    handleChange(evt) {
        var targetElement = $(evt.target).attr('id');
        if (this.state.errors[targetElement]) {
            this.state.errors[targetElement] = null;
            this.setState({
                errors: this.state.errors
            })
        }
        // if($(evt.target).attr('id')=='referral_source'){
        //     if($(evt.target).val()=='Existing Client'){
        //     this.setState({
        //         showClientName:'show'
        //     });
        //     }else{
        //         this.state.formData['referred_client_name']='';
        //         this.setState({
        //             showClientName:'hide'
        //         });
        //     }
        // }

        this.state.formData[targetElement] = $(evt.target).val();
        this.setState({
            formData: this.state.formData
        });
    }

    handleRadioChange(evt) {
        var targetElement = $(evt.target).attr('name');
        this.state.formData[targetElement] = evt.target.value;
        this.setState({
            formData: this.state.formData
        });
    }
    setRevealClass(name) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: 'revealFormOpen',
            value: name
        })
    }

    
    

    handleClose() {
        $('.close-button').click();
    }

    render() {
        var formHeading = null;
        var btnText = null;
        if (this.props.userId) {
            formHeading = "Edit Client";
            btnText = 'Update';
        } else {
            formHeading = "Add Client";
            btnText = 'Add';
        }

        var showMessage = 'w3-hide';
        if (this.state.successMessage != '') {
            showMessage = 'w3-show';
        }

        var optionList = [];
        if (this.props.referrallList) {
            var referList = this.props.referrallList.data;
            for (var idx = 0; idx <= referList.length; idx++) {
                optionList.push(<option key={'refer_' + idx}>{referList[idx]}</option>);
            }
        }
        var statusList = [];
        if (this.props.profileStatusList) {
            var profileStatusList = this.props.profileStatusList.data;
            for (var idx = 0; idx <= profileStatusList.length; idx++) {
                statusList.push(<option key={'status_' + idx}>{profileStatusList[idx]}</option>);
            }
        }

        var gender = this.state.formData.gender;


        var uniqueIdEditField = null;
        if (this.state.formData.id == undefined) {
            uniqueIdEditField = <div className="s6 m6 w3-col">
                <FormTextField
                    id="unique_id"
                    name="unique_id"
                    label="Unique ID"
                    onChange={this.handleChange.bind(this)}
                    value={(this.state.formData.unique_id) ? this.state.formData.unique_id : ''}
                    type="text"
                    inputClass={"w3-input w3-border w3-round"}
                    topClass={"w3-padding-small"} />
                <label className="red" style={{ marginLeft: "10px" }}>{this.state.errors.unique_id}</label>
            </div>
        } else {
            uniqueIdEditField = <div className="s6 m6 w3-col client-unique-id">
                <label>Unique ID
                                <input
                        id="unique_id"
                        name="unique_id"
                        value={this.state.formData.unique_id}
                        disabled="true"
                        type="text"
                        className="w3-input w3-border w3-round" />
                </label>
            </div>
        }

        // var showClientNameField = 'hide';
        // showClientNameField = this.state.showClientName;
        var showButton = this.state.showDisabledButton;
        return (
            <Fragment>
                <NutrifyReveal openCls={this.props.revealFormOpen} handleClose={this.setRevealClass} customWidth={'600px'}>
                    <div className="w3-margin w3-center reveal-heading">
                        {formHeading}
                    </div>
                    <div className="w3-row">
                        <div className={"w3-margin w3-padding success-message " + showMessage}>
                            {this.state.successMessage}
                        </div>
                        <form name="client_add_form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="w3-row w3-row-padding w3-margin-bottom">

                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.first_name) ? this.state.formData.first_name : ''}
                                        type="text"
                                        inputClass={"w3-input w3-border w3-round"} />
                                    <label className="red">{this.state.errors.first_name}</label>
                                </div>

                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.last_name) ? this.state.formData.last_name : ''}
                                        type="text"
                                        inputClass={"w3-input w3-border w3-round"} />
                                    <label className="red">{this.state.errors.last_name}</label>
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div >
                                    {uniqueIdEditField}
                                </div>
                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="contact_email"
                                        name="contact_email"
                                        label="Email"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.contact_email) ? this.state.formData.contact_email : ''}
                                        type="email"
                                        inputClass={"w3-input w3-border w3-round"} />
                                    <label className="red">{this.state.errors.contact_email}</label>
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">

                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="phone"
                                        name="phone"
                                        label="Phone"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.phone) ? this.state.formData.phone : ''}
                                        type="text"
                                        inputClass={"w3-input w3-border w3-round"} />
                                    <label className="red">{this.state.errors.phone}</label>
                                </div>

                                <div id="gender" className="s6 m6 w3-col input-gender" onChange={this.handleRadioChange.bind(this)}>
                                    <div className="w3-margin-bottom">
                                        <label >Gender</label><br />
                                        <input name="gender" type="radio" value="Male" checked={gender === 'Male'} className="w3-radio radio-small" /> Male
                                            <input name="gender" type="radio" value="Female" checked={gender === 'Female'} className="w3-margin-left w3-radio radio-small" /> Female
                                        </div>
                                    <label className="red">{this.state.errors.gender}</label>
                                </div>

                            </div>

                            <div className="w3-row w3-row-padding w3-margin-bottom">

                                <div className="s6 m6 w3-col select-padding">
                                    <FormSelectField
                                        id="referral_source"
                                        name="referral_source"
                                        label="Referral Source"
                                        value={(this.state.formData.referral_source) ? this.state.formData.referral_source : ''}
                                        errors={this.state.errors.referral_source}
                                        onChange={this.handleChange.bind(this)}
                                        selectClass={"w3-select w3-border w3-round"}>
                                        {optionList}
                                    </FormSelectField>
                                </div>


                                <div id="client_name" className="s6 m6 w3-col" >
                                    <FormTextField
                                        id="referred_client_name"
                                        name="referred_client_name"
                                        label="Referral Source Details"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.referred_client_name) ? this.state.formData.referred_client_name : ''}
                                        errors={this.state.errors.referred_client_name}
                                        type="text"
                                        inputClass={"w3-input w3-border w3-round"} />
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">

                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="address"
                                        name="address"
                                        label="Address"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.address) ? this.state.formData.address : ''}
                                        errors={this.state.errors.address}
                                        type="text"
                                        inputClass={"w3-input w3-border w3-round"} />
                                </div>

                                {/* <div className="s6 m6 w3-col">
                                <input type="hidden" value={this.state.formDataModal.file_upload_path || ''} className="path-nd-json" name="file_upload_path" id="file_upload_path" />
                                    <input type="hidden" value={this.state.formDataModal.json_data || ''} className="path-nd-json" name="json_data" id="json_data" />
                                    <input type="file" ref="file" id='file' name="file" onChange={this.handleChange.bind(this)} />

                                </div> */}

                                <div className="s6 m6 w3-col">
                                    <FormTextField
                                        id="joined_date"
                                        name="joined_date"
                                        label="Joined Date"
                                        onChange={this.handleChange.bind(this)}
                                        value={(this.state.formData.joined_date) ? this.state.formData.joined_date : ''}
                                        errors={this.state.errors.joined_date}
                                        type="date"
                                        inputClass={"w3-input w3-border w3-round"} />
                                </div>
                            </div>
                            <div className="w3-row w3-row-padding w3-margin-bottom">
                                <div className="s6 m6 w3-col select-padding">
                                    <FormSelectField
                                        id="status"
                                        name="status"
                                        label="Status"
                                        value={(this.state.formData.status) ? this.state.formData.status : ''}
                                        errors={this.state.errors.status}
                                        onChange={this.handleChange.bind(this)}
                                        selectClass={"w3-select w3-border w3-round"}>
                                        {statusList}
                                    </FormSelectField>
                                </div>
                            </div>
                            <div className="w3-right w3-padding-small w3-margin-bottom">
                                <button onClick={this.setRevealClass.bind(this, '')} type="button" className="w3-button w3-teal w3-hover-teal btn-space w3-padding-small">Cancel</button>
                                <button type="submit" className="w3-button nutrify-btn w3-padding-small" disabled={showButton}>{btnText}</button>
                            </div>
                        </form>
                    </div>
                    <button className="close-button w3-btn no-shadow w3-display-topright" onClick={this.setRevealClass.bind(this, '')} aria-label="Close modal" type="button">
                        <span aria-hidden="true" className="w3-xlarge">&times;</span>
                    </button>
                </NutrifyReveal>


            </Fragment>



        )
    }
}

function mapStateToProps(state) {
    return {
        clientProfile: state.clientProfile,
        referrallList: state.referrallList,
        profileStatusList: state.profileStatusList,
        revealFormOpen: state.revealFormOpen,

    };
}
export default connect(mapStateToProps, {
    getClientProfile,
    getPaginatedList,
    fireRequest
})(ClientProfileManager);
