import React, { Component } from 'react';

import { Router, Route, IndexRoute, hashHistory, Redirect } from 'react-router';
// import { HashRouter as Router, Route } from 'react-router-dom';
import Application,{EmptyApplication,NoHeaderApplication} from "../components/Application.js";

import Login from "../components/Auth/Login.js";
import ForgotPassword from "../components/Auth/ForgotPassword.js";
import ResetPassword from "../components/Auth/ResetPassword.js";
import Dashboard from "../components/Dashboard/Dashboard.js";
import ClientProfileList from "../components/Client/ProfileList.js";
import DieticiansProfile from "../components/Dieticians/DieticiansProfile.js";
import Packages from "../components/Packages/Packages.js";
import Settings from "../components/Settings/Settings.js";
import ClientDetails from "../components/Client/ClientDetails.js";
import DietPlanWorkSpace from "../components/Workspace/DietPlanWorkSpace.js";
import SurveyPanel from "../components/SurveyQuestions/SurveyPanel.js";
import DietitianDetails from "../components/Dieticians/DietitianDetails.js";
import NutritionPlanLists from "../components/Workspace/NutritionPlanLists.js";
import ManageData from '../components/Settings/ManageData.js';
import $ from 'jquery'; 
import gNutrifyStore from '../js/clientStore';

function validateSession(input,replace,callback) {
    var state = gNutrifyStore.getState();

    if (state.currentUser){
        callback();
        return;
    }

    $.ajax({
        url: '/api/v1/auth/session-user'
    }).done(function (resp) {
        gNutrifyStore.dispatch({
            type: 'SET_DATA',
            key: "currentUser",
            value: resp.data.user});

        if (resp.data.user == null) {
            if (input.routes[0].name == 'onlyonauth') {
                replace('/login')
            }
        } else if (input.routes[0].name == 'no-auth') {
            replace('/client-profile');
        }
        callback();
    });
}

let routes = (
    <Router history={hashHistory} >
        <Route path="/" name="onlyonauth"  component={Application} onEnter={validateSession}>
            <IndexRoute component={ClientProfileList} onEnter={validateSession} />
            <Route path="/client-profile(/:filter)(/:page)" component={ClientProfileList} onEnter={validateSession}></Route>
            <Route name="/nutritionists" path="/nutritionists(/:filter)(/:page)" component={DieticiansProfile}  onEnter={validateSession}></Route>
            <Route path="/packages(/:filter)(/:page)" component={Packages}  onEnter={validateSession}></Route>
            <Route path="/settings" component={Settings}  onEnter={validateSession}></Route>
            <Route path="/manage-data" component={ManageData}  onEnter={validateSession}></Route>
            <Route path="/client-details/:id" component={ClientDetails}  onEnter={validateSession}></Route>
            <Route path="/dietitian-details/:id" component={DietitianDetails}  onEnter={validateSession}></Route>
            <Route path="/nutrition-plans(/:filter)(/:page)" component={NutritionPlanLists} onEnter={validateSession}></Route>
        </Route>
        
        <Route path="/workspace/" name="onlyonauth" component={NoHeaderApplication} > 
            <Route path=":packageId/:planId(/:plnId)" component={DietPlanWorkSpace}  onEnter={validateSession}></Route>  
            <Redirect path=":packageId" to=":packageId/list" />
        </Route>
        
        <Route path="/" component={EmptyApplication} name="no-auth" > 
            <Route name='login' path="/login" component={Login}  onEnter={validateSession} />  
            <Route name='forgot-password' path="/forgot-password" component={ForgotPassword}   onEnter={validateSession} /> 
            <Route name='reset-password' path="/reset-password/:token" component={ResetPassword}   onEnter={validateSession}/> 
        </Route>

        <Route path="/" component={EmptyApplication}>
            <Route path="/survey/:token(/:tab)" component={SurveyPanel}  ></Route> 
        </Route>
        <Redirect path="*" to="/" />
    </Router>
);

export default routes;
